import { getUploadInfo, upload } from '@/api';
import useUploadEvents, { ResourceInfo } from '@/hooks/useUploadEvents';
import { CvCResultFileInfo, resultFileListModel } from '@/stores/cvc';
import { resultUploadingStatesModel } from '@/stores/resource';
import { useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

const useResultUpload = () => {
  const [uploadingStatus, setUploadingStatus] = useRecoilState(
    resultUploadingStatesModel
  );
  const setResultList = useSetRecoilState(resultFileListModel);
  const completeUpload = useCallback(
    (data: ResourceInfo, resource_id: string) => {
      setResultList((prev) => {
        return prev.map((item) => {
          if (item.id === resource_id) {
            return { ...item, ...data };
          }
          return item;
        });
      });
    },
    [setResultList]
  );
  const addFiles = useCallback(
    async (sessionId: string, files: File[]) => {
      let resources = [] as CvCResultFileInfo[];
      const resourceResponses = await Promise.all(
        files.map((file) => {
          return getUploadInfo(sessionId, file.name, file.type);
        })
      );
      for (let i = 0; i < resourceResponses.length; i++) {
        const resourceData = resourceResponses[i].data.data;
        const newFiles = {
          name: files[i].name,
          id: resourceData.resource_id,
          resource_id: resourceData.resource_id,
          isChecked: true,
          upload_url: resourceData.upload_url,
        } as CvCResultFileInfo;
        resources.push(newFiles);
      }
      setUploadingStatus((prev) => {
        const status = { ...prev };
        resources.forEach((file) => {
          status[file.id] = 'READY';
        });
        return status;
      });
      setResultList(
        (list = []) => resources.concat(list) as CvCResultFileInfo[]
      );
      for (let i = 0; i < resources.length; i++) {
        await upload(resources[i].upload_url as string, files[i]);
      }
    },
    [setResultList, setUploadingStatus]
  );

  return useUploadEvents(
    uploadingStatus,
    setUploadingStatus,
    completeUpload,
    addFiles
  );
};
export default useResultUpload;
