import { selectedVoiceFilePanelModel } from '@/stores//panels';
import classNames from 'classnames';
import React, { PropsWithChildren, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { CardUI } from '../../components/CardUI';
import { CVCVoiceFilePanelTypes } from '../../consts/cvc';

interface VoiceFileInnerPanelProps {
  panelId: CVCVoiceFilePanelTypes;
}
const VoiceFileInnerPanel: React.FC<
  PropsWithChildren<VoiceFileInnerPanelProps>
> = ({ panelId, children }) => {
  const selectedVoiceFilePanelId = useRecoilValue(selectedVoiceFilePanelModel);
  const ref = React.useRef<HTMLElement>(null);
  const selectedPanelId = useRecoilValue(selectedVoiceFilePanelModel);
  useEffect(() => {
    selectedPanelId &&
      selectedPanelId === panelId &&
      ref?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
  }, [selectedPanelId, ref, panelId]);

  return (
    <section className="voice-cards-wrap" ref={ref}>
      <CardUI
        className={classNames(
          `${panelId.toLocaleLowerCase()}-audio-files`,
          selectedVoiceFilePanelId === panelId && 'selected'
        )}
      >
        {children}
      </CardUI>
    </section>
  );
};
export default VoiceFileInnerPanel;
