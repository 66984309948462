import { useIntervalLog } from '@/hooks/useLog';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { permissionModel } from '../../stores/user';
import Header from '../Header/Header';

const Container = () => {
  const { user } = useSUPAuth();
  const setPermissions = useSetRecoilState(permissionModel);
  const { start, end } = useIntervalLog();

  useEffect(() => {
    if (!user?.permissions) return;
    // Set permissions to recoil
    setPermissions(user.permissions);
    // permission 이 있는 사용자는 page에 머문 시간을 5분간격으로 로깅
    // @see https://supertone-inc.slack.com/archives/C04FBAMV202/p1715141158391479
    const timeTracker = start('TIME_SPENT');
    return () => {
      timeTracker.stop();
    };
  }, [user?.permissions, setPermissions, start]);

  useEffect(() => {
    // 윈도우 창을 끌때도 duration 을 위한 로그를 남김
    const endDuration = () => {
      end('TIME_SPENT');
    };
    window.addEventListener('beforeunload', endDuration);
    return () => {
      window.removeEventListener('beforeunload', endDuration);
    };
  }, [end]);

  return (
    <div className="sup-container">
      <Header />
      <Outlet />
    </div>
  );
};

export default Container;
