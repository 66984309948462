import { CardUI, CardUIContent, CardUIHeader } from '@/components/CardUI';
import Title from '@/components/Title/Title';
import { MSS_SOURCE_PANEL } from '@/consts/mss';
import { SectionPanelResizeHandle } from '@/layout/SectionPanel';
import HotkeyManagerContextProvider from '@/providers/HotkeyManagerContextProvider';
import { mssDescriptionSelector } from '@/stores/mss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { useRecoilValue } from 'recoil';

import {
  DEFAULT_NARROW_PANEL_SIZE,
  DEFAULT_WIDE_PANEL_SIZE,
  MIN_NARROW_PANEL_SIZE,
  MIN_WIDE_PANEL_SIZE,
} from './config';
import MSSResultList from './MSSResultList';
import MSSSourceList from './MSSSourceList';
import SeparateControl from './SeparateControl/SeparateControl';
import StyledMssAndSE from './styled/StyledMssAndSE';
import StyledMssAndSEGuide from './styled/StyledMssAndSEGuide';

const MssPage = () => {
  const { t } = useTranslation();
  const { main, sub } = useRecoilValue(mssDescriptionSelector);
  const [sourcePanelSize, setSourcePanelSize] = useState<number>(0);
  const [resultPanelSize, setResultPanelSize] = useState<number>(0);

  return (
    <HotkeyManagerContextProvider>
      <StyledMssAndSE>
        <section className="mss-grid">
          <PanelGroup direction="horizontal">
            <Panel
              minSize={MIN_NARROW_PANEL_SIZE}
              defaultSize={DEFAULT_NARROW_PANEL_SIZE}
              order={MSS_SOURCE_PANEL.SOURCE}
              onResize={(size: number) => setSourcePanelSize(size)}
            >
              <MSSSourceList resizeDep={sourcePanelSize} />
            </Panel>
            <SectionPanelResizeHandle />
            <Panel
              minSize={MIN_NARROW_PANEL_SIZE}
              defaultSize={DEFAULT_NARROW_PANEL_SIZE}
              order={MSS_SOURCE_PANEL.CONTROL}
            >
              <CardUI className="mss-cards">
                <CardUIHeader>
                  <Title size="lg">{t('SEPARATE CONTROL')}</Title>
                </CardUIHeader>
                <CardUIContent className="mss-separate">
                  <SeparateControl />
                </CardUIContent>
              </CardUI>
            </Panel>
            <SectionPanelResizeHandle />
            <Panel
              minSize={MIN_WIDE_PANEL_SIZE}
              defaultSize={DEFAULT_WIDE_PANEL_SIZE}
              order={MSS_SOURCE_PANEL.RESULT}
              onResize={(size: number) => setResultPanelSize(size)}
            >
              <CardUI className="mss-cards">
                <CardUIHeader>
                  <Title size="lg">{t('RESULTS')}</Title>
                </CardUIHeader>
                <CardUIContent>
                  <MSSResultList resizeDep={resultPanelSize} />
                </CardUIContent>
              </CardUI>
            </Panel>
          </PanelGroup>
        </section>
      </StyledMssAndSE>
      <StyledMssAndSEGuide>
        <section className="description">
          <p className="main-desc">{main}</p>
          {sub && <p>{sub}</p>}
        </section>
      </StyledMssAndSEGuide>
    </HotkeyManagerContextProvider>
  );
};

export default MssPage;
