import { ReactComponent as BottomPanelIcon } from '@/assets/icons/BottomPanelIcon.svg';
import { ReactComponent as LeftPanelIcon } from '@/assets/icons/LeftPanelIcon.svg';
import { ReactComponent as RightPanelIcon } from '@/assets/icons/RightPanelIcon.svg';
import { ReactComponent as TopPanelIcon } from '@/assets/icons/TopPanelIcon.svg';
import ButtonGroup from '@/components/Button/ButtonGroup';
import IconButton from '@/components/Button/IconButton';
import { panelsModel } from '@/stores//panels';
import { descriptionSelector } from '@/stores/cvc';
import classNames from 'classnames';
import { useContext, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { CVC_PANEL_ORDER_MAP } from '../../consts/cvc';
import { HotkeyManagerContext } from '../../providers/HotkeyManagerContextProvider';
import usePanelManager from './hooks/usePanelManager';
import StyledCvcGuide from './styled/StyledCvcGuide';

const CvcGuide = () => {
  const {
    toggleSelectModelPanel,
    toggleAudioEditorPanel,
    toggleVoiceFilePanel,
    toggleResultsPanel,
  } = usePanelManager();
  const panels = useRecoilValue(panelsModel);
  const screenToggleList = useMemo(() => {
    return [
      {
        onClick: toggleSelectModelPanel,
        icon: LeftPanelIcon,
        panel: CVC_PANEL_ORDER_MAP.CONTROL,
      },
      {
        onClick: toggleAudioEditorPanel,
        icon: TopPanelIcon,
        panel: CVC_PANEL_ORDER_MAP.AUDIO_EDITOR,
      },
      {
        onClick: toggleVoiceFilePanel,
        icon: BottomPanelIcon,
        panel: CVC_PANEL_ORDER_MAP.VOICE_FILES,
      },
      {
        onClick: toggleResultsPanel,
        icon: RightPanelIcon,
        panel: CVC_PANEL_ORDER_MAP.RESULTS,
      },
    ];
  }, [
    toggleSelectModelPanel,
    toggleAudioEditorPanel,
    toggleVoiceFilePanel,
    toggleResultsPanel,
  ]);

  const { main, sub } = useRecoilValue(descriptionSelector);
  const { register, unRegister } = useContext(HotkeyManagerContext);

  useEffect(() => {
    register('alt+1', toggleSelectModelPanel);
    register('alt+2', toggleAudioEditorPanel);
    register('alt+3', toggleVoiceFilePanel);
    register('alt+4', toggleResultsPanel);
    register('f1', toggleSelectModelPanel);
    register('f2', toggleAudioEditorPanel);
    register('f3', toggleVoiceFilePanel);
    register('f4', toggleResultsPanel);
    return () => {
      unRegister('alt+1');
      unRegister('alt+2');
      unRegister('alt+3');
      unRegister('alt+4');
      unRegister('f1');
      unRegister('f2');
      unRegister('f3');
      unRegister('f4');
    };
  }, [
    register,
    unRegister,
    toggleSelectModelPanel,
    toggleAudioEditorPanel,
    toggleVoiceFilePanel,
    toggleResultsPanel,
  ]);

  return (
    <StyledCvcGuide>
      <section className="description">
        <p className="main-desc">{main}</p>
        {sub && <p>{sub}</p>}
      </section>
      <section>
        <ButtonGroup className="panel-control">
          {screenToggleList.map(({ onClick, icon: Icon, panel }) => {
            return (
              <IconButton
                key={`key_toggle_${panel}`}
                onClick={onClick}
                color="transparent"
                radius="none"
                size="md"
                variant="contained"
                className={classNames({ closed: !panels[panel] })}
                isFillCurrentColor={false}
              >
                <Icon />
              </IconButton>
            );
          })}
        </ButtonGroup>
      </section>
    </StyledCvcGuide>
  );
};

export default CvcGuide;
