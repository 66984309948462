import { DEFAULT_RATIO } from '@/consts/cvc';
import {
  CheckboxParameter,
  DEFAULT_MODEL,
  MODEL_PREFIX,
  ModelType,
  STORAGE_KEY,
  SliderParameter,
  modelParamMap,
} from '@/pages/cvc/ModelControl/config';
import { atom, selector } from 'recoil';

import { permissionSelector } from './user';

export const enabledModelSelector = selector<ModelType[]>({
  key: 'enabledModelSelector',
  get: ({ get }) => {
    const permissions = get(permissionSelector);
    const models = permissions
      .filter((permission) => permission.startsWith(MODEL_PREFIX))
      .map((model) => model.replace(MODEL_PREFIX, '')) as ModelType[];
    // nansy-v${number}-${name}
    // 같은 버전끼리 묶음
    const nansyModels = models.filter((model) => model.startsWith('nansy-v'));

    const versionModelMap = nansyModels.reduce((acc, model) => {
      const [, version] = model.split('-');
      if (!acc[version]) acc[version] = [];
      acc[version].push(model as ModelType);
      return acc;
    }, {} as { [key: string]: ModelType[] });
    // 묶은 버전 key로 정렬후 모델 정렬
    const sortedModels = Object.keys(versionModelMap)
      .sort((v1, v2) => {
        return (
          parseFloat(v2.replace('v', '')) - parseFloat(v1.replace('v', ''))
        );
      })
      .reduce((acc, version) => {
        return [...acc, ...versionModelMap[version].sort()];
      }, [] as ModelType[]);
    return [
      ...sortedModels,
      ...models.filter((model) => !model.startsWith('nansy-v')),
    ];
  },
});

export const cvcTypeModel = atom<ModelType>({
  key: 'cvc/model',
  default: localStorage.getItem(STORAGE_KEY) as ModelType,
});

export const selectedCvcTypeSelector = selector<ModelType>({
  key: 'cvc/selectedModel',
  get: ({ get }) => {
    const cvcType = get(cvcTypeModel);
    return cvcType ?? DEFAULT_MODEL;
  },
  set: ({ set }, newValue) => {
    localStorage.setItem(STORAGE_KEY, newValue as string);
    set(cvcTypeModel, newValue as ModelType);
  },
});

export const isInterpolationSelector = selector<boolean>({
  key: 'cvc/isInterpolation',
  get: ({ get }) => {
    const cvcType = get(selectedCvcTypeSelector);
    return cvcType.endsWith('interpolation');
  },
});

export const interpolationRatioModel = atom<number>({
  key: 'cvc/interpolationRatio',
  default: DEFAULT_RATIO,
});

export const parameterSelector = selector<
  (CheckboxParameter | SliderParameter)[]
>({
  key: 'cvc/parameter',
  get: ({ get }) => {
    const cvcType = get(selectedCvcTypeSelector);
    // return exist model param
    if (modelParamMap[cvcType]) return modelParamMap[cvcType];
    // v2.5 or v2.7 use v2.5 params
    if (cvcType.startsWith('nansy-v2.5') || cvcType.startsWith('nansy-v2.7'))
      return modelParamMap['nansy-v2.5'];
    // v2 use v2 params
    return modelParamMap['nansy-v2'];
  },
});

export const parametersValueModel = atom<{ [key: string]: number | boolean }>({
  key: 'cvc/parameterValue',
  default: {},
});

export const mssParameterValueModel = atom<{ [key: string]: number }>({
  key: 'cvc/mssParameterValue',
  default: {},
});

export const parametersValueSelector = selector<{
  [key: string]: number | boolean;
}>({
  key: 'cvc/parameterValueSelector',
  get: ({ get }) => {
    const parameters = get(parameterSelector);
    const values = get(parametersValueModel);
    return parameters?.reduce((acc, parameter) => {
      acc[parameter.name] = values[parameter.name] ?? parameter.defaultValue;
      return acc;
    }, {} as { [key: string]: number | boolean });
  },
});

export const isSourceOnlySelector = selector<boolean>({
  key: 'cvc/isSourceOnly',
  get: ({ get }) => {
    const cvcType = get(selectedCvcTypeSelector);
    return cvcType === 'speaker-control';
  },
});
