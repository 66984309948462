import { CVCVoiceFilePanelTypes } from '@/consts/cvc';
import { isSourceOnlySelector } from '@/stores/models';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

const useCvcModelState = () => {
  const isSourceOnly = useRecoilValue(isSourceOnlySelector);
  const voiceFilePanelIds: CVCVoiceFilePanelTypes[] = useMemo(
    () =>
      isSourceOnly ? ['Source', 'Generate'] : ['Source', 'Target', 'Generate'],
    [isSourceOnly]
  );
  return { voiceFilePanelIds, isSourceOnly };
};

export default useCvcModelState;
