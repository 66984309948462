import {
  postCvcGenerate,
  postCvcInterpolationGenerate,
  postCvcSpeakerControl,
} from '@/api';
import { WebSocketContext } from '@/providers/WebSocketProvider';
import {
  CvCResultFileInfo,
  CvcTargetFileInfo,
  ExtendFileInfo,
  mergedTargetFileMapModel,
  mergedTargetIdListModel,
  resultFileListModel,
  resultParametersMapModel,
} from '@/stores/cvc';
import {
  interpolationRatioModel,
  isInterpolationSelector,
  isSourceOnlySelector,
  parametersValueSelector,
  selectedCvcTypeSelector,
} from '@/stores/models';
import { cvcProcessingStatesModel } from '@/stores/resource';
import { useCallback, useContext } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

const useGenerate = () => {
  const modelType = useRecoilValue(selectedCvcTypeSelector);
  const { sessionId } = useContext(WebSocketContext);
  const targetMergeMap = useRecoilValue(mergedTargetFileMapModel);
  const mergedTargetIds = useRecoilValue(mergedTargetIdListModel);
  const parameters = useRecoilValue(parametersValueSelector);
  const setCvcProcessingStatus = useSetRecoilState(cvcProcessingStatesModel);
  const setResultFileList = useSetRecoilState(resultFileListModel);
  const isInterpolation = useRecoilValue(isInterpolationSelector);
  const ratio = useRecoilValue(interpolationRatioModel);
  const isSourceOnly = useRecoilValue(isSourceOnlySelector);
  const setResultParametersMap = useSetRecoilState(resultParametersMapModel);

  const updateJobIds = useCallback(
    (jobIds: string[]) => {
      setCvcProcessingStatus((prev) => {
        const newStatus = { ...prev };
        jobIds.forEach((job_id: string) => {
          newStatus[job_id] = 'READY';
        });
        return newStatus;
      });
      setResultFileList((prev) => {
        return jobIds
          .map(
            (job_id) =>
              ({
                id: job_id,
                name: job_id,
                isChecked: false,
              } as CvCResultFileInfo)
          )
          .concat(prev);
      });
      setResultParametersMap((pre) => {
        const newMap = { ...pre };
        jobIds?.forEach((job_id) => {
          newMap[job_id] = {
            params: { ...parameters },
            name: modelType,
          };
        });
        return newMap;
      });
      return jobIds;
    },
    [
      setCvcProcessingStatus,
      setResultFileList,
      setResultParametersMap,
      modelType,
      parameters,
    ]
  );
  const generateAll = useCallback(
    async (sources: ExtendFileInfo[], targets: CvcTargetFileInfo[]) => {
      const jobIds = await postCvcGenerate(
        modelType,
        sessionId as string,
        sources.map((item) => item.id),
        targets
          .filter((item) => !item.isMerged)
          .map((item) =>
            targetMergeMap[item.id] ? targetMergeMap[item.id].list : item.id
          ),
        parameters
      );
      return updateJobIds(jobIds);
    },
    [modelType, sessionId, targetMergeMap, parameters, updateJobIds]
  );
  const generateList = useCallback(
    async (
      sources: ExtendFileInfo[],
      targets: CvcTargetFileInfo[],
      interpolationMap: {
        GroupA: string[];
        GroupB: string[];
      }
    ) => {
      if (isInterpolation) {
        // group에 체크가 안된 아이템 제거, group에서 merged 된 target 찾아서 string matrix로 변환
        const groupA = interpolationMap.GroupA.filter(
          (id) => !!targetMergeMap[id] || !mergedTargetIds.includes(id)
        ).map((item) =>
          targetMergeMap[item] ? targetMergeMap[item].list : item
        );
        const groupB = interpolationMap.GroupB.filter(
          (id) => !!targetMergeMap[id] || !mergedTargetIds.includes(id)
        ).map((item) =>
          targetMergeMap[item] ? targetMergeMap[item].list : item
        );
        if (groupA.length === 0 || groupB.length === 0) {
          return [];
        }
        const jobIds = await postCvcInterpolationGenerate(
          modelType.replace('-interpolation', ''),
          sessionId as string,
          sources.map((item) => item.id),
          {
            GroupA: groupA,
            GroupB: groupB,
          },
          { ...parameters, ratio: ratio / 10 }
        );
        return updateJobIds(jobIds);
      } else if (isSourceOnly) {
        const jobIds = await postCvcSpeakerControl(
          modelType,
          sessionId as string,
          sources.map((item) => item.id),
          parameters
        );
        return updateJobIds(jobIds);
      } else {
        return generateAll(sources, targets);
      }
    },
    [
      isSourceOnly,
      mergedTargetIds,
      ratio,
      generateAll,
      modelType,
      sessionId,
      targetMergeMap,
      parameters,
      isInterpolation,
      updateJobIds,
    ]
  );
  return { generateAll, generateList, updateJobIds };
};
export default useGenerate;
