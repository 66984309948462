import { useMemo } from 'react';

import Select from '../../../components/Select/Select';
import { MODEL_DESCRIPTIONS, MODEL_LABEL, ModelType } from './config';

export type ModelOption = {
  value: string;
  label: string;
};
interface ModelSelectProps {
  models: ModelType[];
  value: string;
  onChange: (option: ModelType) => void;
}

const ModelSelect = ({ models, value, onChange }: ModelSelectProps) => {
  // model option for showing description
  const modelOptions = useMemo(
    () =>
      models.map((model) => ({
        value: model,
        label:
          MODEL_LABEL[model] ||
          model
            .split('-')
            .reduce(
              (s, c) => [s, c.charAt(0).toUpperCase() + c.slice(1)].join(' '),
              ''
            ),
        description: MODEL_DESCRIPTIONS[model],
      })),
    [models]
  );
  return (
    <section className="model-selector">
      <Select
        value={value}
        onChange={(option) =>
          option?.value && onChange(option.value as ModelType)
        }
        options={modelOptions}
        optionsPlacement="bottom-center"
        optionsMinWidth="19.8rem"
        width="100%"
        showOptionDescription
      />
    </section>
  );
};

export default ModelSelect;
