import { formatXAxisTime } from '@/util/formatter';
import { axisTop } from 'd3-axis';
import { ScaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { useCallback, useEffect, useRef } from 'react';

import { X_AXIS_HEIGHT } from '../const';
import { Size } from '../types';

interface AxisProps {
  size: Size;
  xScale: ScaleLinear<number, number>;
}

const Axis = ({ size, xScale }: AxisProps) => {
  const xAxisRef = useRef<SVGGElement>(null);

  const drawXAxis = useCallback(() => {
    if (!size.height || !xAxisRef.current) return;
    const xAxis = xAxisRef.current;

    // MM:SS:MS 00:00:000 형태로 표시
    const xAxisGenerator = axisTop(xScale).tickFormat((d) => {
      return formatXAxisTime(d as number);
    });

    select(xAxis)
      .call(xAxisGenerator)
      .attr('transform', `translate(0, ${size.height})`)
      .select('.domain')
      .remove();

    // select line
    select(xAxis)
      .selectAll('line')
      .attr('transform', `translate(0, ${X_AXIS_HEIGHT})`)
      .attr('y2', -size.height);
    // select text
    select(xAxis)
      .selectAll('text')
      .attr('y', 0)
      .attr('dy', 0)
      .attr('alignment-baseline', 'middle')
      // translate it to top of the axis
      .attr('transform', `translate(0, ${-size.height + X_AXIS_HEIGHT / 2})`);
  }, [xScale, size.height]);

  useEffect(() => {
    drawXAxis();
  }, [xScale, drawXAxis]);

  return (
    <g className="editor-axis">
      {/* X Axis - time */}
      <g className="editor-xaxis">
        <rect width={size.width} height={X_AXIS_HEIGHT} />
        <g ref={xAxisRef}></g>
      </g>
    </g>
  );
};

export default Axis;
