import {
  selectedCVCPanelModel,
  selectedVoiceFilePanelModel,
} from '@/stores//panels';
import {
  interpolationSelector,
  selectedSourceFileListSelector,
  selectedTargetFileListSelector,
} from '@/stores/cvc';
import { isSourceOnlySelector } from '@/stores/models';
import classNames from 'classnames';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';

import { ReactComponent as LightingIcon } from '../../components/assets/icons/LightingIcon.svg';
import Button from '../../components/Button/Button';
import Title from '../../components/Title/Title';
import { HotkeyManagerContext } from '../../providers/HotkeyManagerContextProvider';
import { Primary } from '../../styles/Colors';
import useCvcModelState from './hooks/useCvcModelState';
import useGenerate from './hooks/useGenerate';

interface VoiceFilesPanelHeaderProps {
  isPanelActive: boolean;
}

const VoiceFilesPanelHeader = ({
  isPanelActive,
}: VoiceFilesPanelHeaderProps) => {
  const { t } = useTranslation();
  // Register hotkey
  const { register, unRegister } = useContext(HotkeyManagerContext);
  const [selectedPanelId, setSelectedPanelId] = useRecoilState(
    selectedVoiceFilePanelModel
  );
  const { voiceFilePanelIds } = useCvcModelState();
  const selectedCVCPanelId = useRecoilValue(selectedCVCPanelModel);
  const selectResultPanel = useRecoilCallback(
    ({ set }) =>
      () =>
        set(selectedCVCPanelModel, 'Results')
  );

  const handlePanelSelectHotkey = useCallback(
    (step = 1) => {
      const idx = selectedPanelId
        ? voiceFilePanelIds.indexOf(selectedPanelId)
        : 0;
      const nextIdx = idx + step;
      if (nextIdx < 0 || nextIdx >= voiceFilePanelIds.length) return;
      setSelectedPanelId(voiceFilePanelIds[nextIdx]);
    },
    [selectedPanelId, voiceFilePanelIds, setSelectedPanelId]
  );

  useEffect(() => {
    if (!isPanelActive) return;
    register('right', () => handlePanelSelectHotkey(1));
    register('left', () => handlePanelSelectHotkey(-1));
    return () => {
      unRegister('right');
      unRegister('left');
    };
  }, [register, unRegister, handlePanelSelectHotkey, isPanelActive]);

  const isSourceOnly = useRecoilValue(isSourceOnlySelector);

  const selectedSourceFileList = useRecoilValue(selectedSourceFileListSelector);
  const selectedTargetFileList = useRecoilValue(selectedTargetFileListSelector);
  const { generateList } = useGenerate();
  const interpolationMap = useRecoilValue(interpolationSelector);
  const onClickGenerate = useCallback(async () => {
    generateList(
      selectedSourceFileList,
      selectedTargetFileList.filter((v) => !v.isGroup),
      interpolationMap
    );
  }, [
    generateList,
    selectedSourceFileList,
    selectedTargetFileList,
    interpolationMap,
  ]);

  return (
    <>
      <Title size="lg">{t('VOICE FILES')}</Title>
      <ol className="voice-file-nav">
        {voiceFilePanelIds.map((panelId) => (
          <li
            onClick={() => setSelectedPanelId(panelId)}
            key={`voice_files_${panelId}`}
            className={classNames(selectedPanelId === panelId && 'on')}
          >
            <span>{t(panelId)}</span>
          </li>
        ))}
        <li
          onClick={selectResultPanel}
          className={classNames(selectedCVCPanelId === 'Results' && 'on')}
        >
          <span>{t('Results')}</span>
        </li>
      </ol>
      <Button
        startIcon={<LightingIcon />}
        color={Primary[400]}
        className="button-quick-generate"
        size="lg"
        disabled={
          isSourceOnly
            ? !selectedSourceFileList.length
            : !selectedSourceFileList.length || !selectedTargetFileList.length
        }
        onClick={onClickGenerate}
      >
        {t('Quick Generate')}
      </Button>
    </>
  );
};
export default VoiceFilesPanelHeader;
