import GroupA from '@/assets/images/GroupA.svg';
import GroupB from '@/assets/images/GroupB.svg';
import MinusIcon from '@/components/assets/icons/MinusIcon.svg';
import { customScrollbar } from '@/styles/mixins';
import styled from '@emotion/styled';

import {
  Black,
  Grey,
  Primary,
  PureBlack,
  Secondary,
  White,
} from '../../../styles/Colors';
import { FontSize, FontWeight } from '../../../styles/Typography';

export const StyledCvcContent = styled.article`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  width: 100%;
  .sup-control-panel {
    border-right: 1px solid ${Grey[500]};
    > .sup-card > .sup-card-header {
      min-height: 3rem !important;
    }
    .sup-models {
      display: flex;
      flex-direction: column;
      flex: auto;
      overflow: hidden;
      position: relative;
      padding: 0;
      width: 100%;
      font-size: ${FontSize['Md']};
      .model-selector {
        min-height: 3rem;
        padding: 0 0.5rem;
        > button {
          min-height: 3rem;
        }
      }
    }
    .main-panel-content {
      margin: 0 !important;
      width: 100% !important;
    }
    .model-parameters {
      margin-top: 2rem;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 0.5rem;
      ${customScrollbar};
      .model-parameter {
        margin-bottom: 1rem;
      }
      .sup-card {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .sup-results-panel {
    border-left: 1px solid ${Grey[500]};
    position: relative;
  }
  .cvc-section-panel {
    display: flex;
    flex-direction: column;
  }
  .highlight {
    position: relative;
    &::before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border: 2px solid ${Grey[50]};
      transition: all 0.3s ease-in-out;
      z-index: 1;
    }
  }
  .cvc-section-panel .main-panel-content {
    flex: auto;
    flex-direction: column;
    overflow: auto;
    margin: 0.625rem 0.625rem 0;
    width: calc(100% - 1.25rem);
    z-index: 1;
    margin-bottom: 0.25rem;
  }
  .cvc-section-panel .card {
    flex: auto;
  }
  .cvc-section-panel .card.grey {
    background-color: ${Grey[600]};
  }
  .cvc-section-panel .card.transparent {
    border: 1px solid currentColor;
  }
  .voice-files-panel > .sup-card {
    position: relative;
    .sup-card-header {
      position: relative;
      z-index: 1;
    }
    &::before,
    &::after {
      content: ' ';
      width: 2.0625rem;
      height: calc(100% - 6.8rem);
      position: absolute;
      top: 4.3rem;
      flex-shrink: 0;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) -30%,
        rgba(0, 0, 0, 0.5) 100%
      );
      filter: blur(10px);
      z-index: 2;
      pointer-events: none;
    }
    &::before {
      left: 1rem;
    }
    &::after {
      right: 1rem;
    }
  }
  .voice-files-panel .voice-file-nav {
    color: ${Grey['400']};
    display: flex;
    gap: 0;
    height: 1.875rem;
    li {
      border-top: 1px solid ${Grey['400']};
      border-bottom: 1px solid ${Grey['400']};
      display: flex;
      align-items: center;
      width: 6.25rem;
      margin-right: 0.625rem;
      text-align: center;
      font-family: 'Inter';
      font-weight: bold;
      position: relative;
      cursor: pointer;
      span {
        flex: auto;
        font-size: ${FontSize['Md']};
        line-height: ${FontSize['Md']};
      }
      &:hover {
        background-color: ${Grey['600']};
        ::after {
          background-color: ${Grey['600']};
        }
        ::before {
          background-color: ${Grey['600']};
        }
      }
      &.on {
        background-color: ${Grey['50']};
        border-top: 1px solid ${Grey['200']};
        border-bottom: 1px solid ${Grey['200']};
        border-right: 0;
        color: ${Black};
        &:last-child {
          border-right: 1px solid ${Grey['200']};
        }
        &:first-of-type {
          border-left: 1px solid ${Grey['200']};
        }
        ::before {
          border-right: 1px solid ${Grey['200']};
          border-top: 1px solid ${Grey['200']};
          background-color: ${Grey['600']};
        }
        ::after {
          background-color: ${Grey['50']};
          border-right: 1px solid ${Grey['200']};
          border-top: 1px solid ${Grey['200']};
        }
      }
      ::before,
      ::after {
        position: absolute;
        right: -0.4rem;
        top: 0.4rem;
        content: '';
        display: block;
        width: 0.75rem;
        height: 0.75rem;
        transform: rotate(45deg) skew(30deg, 30deg);
        border-right: 2px solid ${Grey['400']};
        border-top: 2px solid ${Grey['400']};
      }
      ::before {
        left: -0.4rem;
      }
      &:first-of-type {
        border-left: 1px solid ${Grey['400']};
        ::before {
          display: none;
          border-left: 1px solid ${Grey['400']};
        }
      }
      &:last-child {
        border-right: 1px solid ${Grey['400']};
        ::after {
          display: none;
        }
      }
    }
  }
  .voice-files-panel .button-quick-generate {
    white-space: nowrap;
    padding: 0.25rem 0.5rem;
    position: relative;
    z-index: 1;
  }
  .audio-editor-panel {
    position: relative;
  }
  .voice-files-panel .voice-cards-wrap {
    display: flex;
  }
  .voice-cards-wrap > .sup-card {
    box-sizing: border-box;
  }
  .sup-files {
    display: flex;
    flex: auto;
    overflow: hidden;
    position: relative;
    margin: 0.75rem 0.75rem 1.75rem 0.75rem;
    padding: 0;
    width: calc(100% - 1.5rem);
    font-size: ${FontSize['Md']};
    background-color: ${PureBlack};
    .file-content {
      height: 100%;
      display: flex;
      flex: auto;
      flex-direction: column;
      &.hide-length {
        .play-time {
          display: none !important;
        }
      }
    }
    .interpolation {
      &.selected {
        &::before {
          content: '';
          background-image: url(${GroupA});
          background-repeat: no-repeat;
          background-position: center;
          width: calc(50% - 1px);
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          background-color: ${Grey[300]};
          z-index: 1;
          border: 2px dotted ${Primary[400]};
          border-right: 1px solid ${Grey[50]};
        }
        &::after {
          content: '';
          background-image: url(${GroupB});
          background-repeat: no-repeat;
          background-position: center;
          width: calc(50% - 1px);
          position: absolute;
          right: 0;
          top: 0;
          height: calc(100% - 4px);
          line-height: 100%;
          background-color: ${Grey[200]};
          border: 2px dotted ${Primary[400]};
          border-left: 1px solid ${Grey[50]};
          text-align: center;
          font-family: Inter;
          font-size: 7.5rem;
          font-weight: ${FontWeight['Bold']};
          color: ${Grey[300]};
        }
      }
      ~ .ratio {
        display: flex;
        position: relative;
        bottom: 0;
        width: 100%;
        height: 2.5rem;
        border-top: 1px solid ${Primary[400]};
        align-items: center;
        justify-content: center;
        &-slider {
          width: 14.69rem;
        }
        .ratio-stepper {
          display: flex;
          align-items: center;
        }
        .ratio-minus,
        .ratio-plus {
          background-color: transparent;
          border: transparent;
          color: ${Grey[50]};
        }
        .ratio-minus:disabled,
        .ratio-plus:disabled {
          color: ${Grey[500]};
        }
        .ratio-input {
          width: 3.5rem;
          height: 1.125rem;
          text-align: center;
          background-color: transparent;
          color: ${Grey[50]};
          border: 1px solid ${Grey[500]};
        }
      }
    }
    &.wrap-ratio {
      flex-direction: column;
    }
  }
  .header-buttons {
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
  }
  .sup-results-panel .sup-card-content {
    margin: 0;
    width: 100%;
  }
  .generate-grid {
    background-color: transparent;
    gap: 1.25rem;
    .file-content {
      background-color: ${PureBlack};
      flex: 1;
      border: 1px solid ${Grey[200]};
      height: calc(100% - 2px);
      .sup-file-list-inner {
        overflow-x: hidden;
      }
    }
    .generated-list-header {
      background-color: ${Grey[200]};
      display: flex;
      justify-content: space-between;
      padding: 0.625rem 1rem;
      align-items: center;
      .selected-file-info {
        color: ${Grey[600]};
        font-size: ${FontSize['Lg']};
        font-weight: ${FontWeight['SemiBold']};
        span {
          color: ${White};
        }
      }
    }
  }
  .generate-buttons {
    display: inline-flex;
    gap: 1.25rem;
    button {
      padding: 0.25rem 0.5rem;
    }
    .button-view-matrix {
      border: 1px solid ${Grey[500]};
      background-color: ${Grey[500]};
    }
    .button-gen-files {
      border: 1px solid ${Primary[400]};
      background-color: ${Primary[400]};
      color: ${White};
    }
  }
  .matrix-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid ${Grey[200]};
    background: ${Grey[600]};
    overflow: auto;
    section:first-of-type {
      ::before {
        content: '';
        background-color: transparent;
      }
      strong:nth-of-type(even) {
        background-color: ${Black};
      }
      strong:nth-of-type(odd) {
        background-color: ${PureBlack};
      }
      .matrix-group-header {
        background-color: ${Grey[450]};
      }
      .matrix-group-header:first-of-type {
        border-right: 1px solid ${Black};
      }
    }
    &.interpolation {
      section:first-of-type {
        ::before {
          grid-row: span 2;
        }
      }
    }
    > section {
      display: grid;
      strong {
        color: ${Grey[100]};
        border-bottom: 1px solid ${Grey[500]};
        background-color: ${PureBlack};
        line-height: 100%;
        min-height: 2.5rem;
        .matrix-header {
          display: flex;
          flex: 1;
          padding: 0.6rem 1rem;
          .matrix-header-checkbox.has-checked > input ~ label {
            background: url(${MinusIcon}) no-repeat;
            background-color: ${Grey[200]};
            border-color: ${Grey[200]};
          }
        }
        .matrix-filename {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          line-height: 150%;
          margin-left: 1rem;
        }
      }
      &:nth-of-type(odd) strong {
        background-color: ${Black};
      }
      > span {
        border: 1px solid ${Grey[700]};
        .matrix-check-input {
          display: none;
        }
        .matrix-check-label {
          display: block;
          width: 100%;
          height: 2.5rem;
          box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.32) inset;
          background: ${Grey[500]};
        }
        input:checked ~ .matrix-check-label {
          background-color: ${Primary[400]};
        }
        &.even {
          input:checked ~ .matrix-check-label {
            background-color: ${Primary[450]};
          }
        }
      }
    }
    .empty {
      display: flex;
      flex: auto;
      justify-content: center;
      align-items: center;
    }
  }
  .confirm-modal {
    .modal-body {
      min-width: 24rem;
    }
    .modal-footer {
      button:last-of-type {
        background-color: ${Secondary[200]};
        border-color: ${Secondary[200]};
      }
    }
  }
`;
export default StyledCvcContent;
