import { currentEditFileModel } from '@/stores/cvc';
import classNames from 'classnames';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import StyledFileNameDisplay from './StyledFileNameDisplay';

export interface FileNameDisplayProps {
  maxWidth?: CSSProperties['maxWidth'];
  fileName?: string;
}
const FileNameDisplay = ({ fileName, maxWidth }: FileNameDisplayProps) => {
  const { t } = useTranslation();

  // file selected from list
  const file = useRecoilValue(currentEditFileModel);

  return (
    <StyledFileNameDisplay
      maxWidth={maxWidth}
      className={classNames('sup-audio-filename', { active: fileName })}
    >
      {fileName ? (
        <>
          {/* 파일명만 표기, 길어질 경우 text ellipsis */}
          <span>{fileName.split('.').slice(0, -1).join('.')}</span>
          {/* 확장자명 표기 */}
          <span>.{fileName.split('.').slice(-1)}</span>
        </>
      ) : (
        <span>{file ? file.name : t('No Imported files to Edit')}</span>
      )}
    </StyledFileNameDisplay>
  );
};

export default FileNameDisplay;
