import { ReactComponent as ZoomInIcon } from '../assets/icons/ZoomInIcon.svg';
import { ReactComponent as ZoomOutIcon } from '../assets/icons/ZoomOutIcon.svg';
import { ReactComponent as ZoomResetIcon } from '../assets/icons/ZoomResetIcon.svg';
import ButtonGroup from '../Button/ButtonGroup';
import IconButton from '../Button/IconButton';
import {
  DEFAULT_Y_SCALE_INFO_MAX,
  DEFAULT_Y_SCALE_INFO_MIN,
  DEFAULT_Y_SCALE_STEP,
} from './const';

interface VerticalZoomProps {
  range: [number, number];
  updateRange?: (range: [number, number]) => void;
  updateScale?: (ratio: number) => void;
  step?: number;
}

const VerticalZoom = ({
  range,
  updateRange,
  updateScale,
  step = DEFAULT_Y_SCALE_STEP,
}: VerticalZoomProps) => {
  return (
    <ButtonGroup radius="none" className="editor-zoom editor-zoom-vertical">
      <IconButton
        onClick={() => updateScale?.(1 - step)}
        disabled={
          range[0] === DEFAULT_Y_SCALE_INFO_MIN[0] &&
          range[1] === DEFAULT_Y_SCALE_INFO_MIN[1]
        }
      >
        <ZoomInIcon />
      </IconButton>
      <IconButton
        onClick={() => updateRange?.(DEFAULT_Y_SCALE_INFO_MAX)}
        disabled={
          range[0] === DEFAULT_Y_SCALE_INFO_MAX[0] &&
          range[1] === DEFAULT_Y_SCALE_INFO_MAX[1]
        }
      >
        <ZoomResetIcon />
      </IconButton>
      <IconButton
        onClick={() => updateScale?.(1 + step)}
        disabled={
          range[0] === DEFAULT_Y_SCALE_INFO_MAX[0] &&
          range[1] === DEFAULT_Y_SCALE_INFO_MAX[1]
        }
      >
        <ZoomOutIcon />
      </IconButton>
    </ButtonGroup>
  );
};

export default VerticalZoom;
