import useAudioBufferStore from '@/hooks/useAudioBufferStore';
import useUndoManager from '@/hooks/useUndoManager/useUndoManager';
import { Grey } from '@/styles/Colors';
import { useEffect, useMemo, useState } from 'react';

import { ReactComponent as CutIcon } from '../assets/icons/CutIcon.svg';
import { ReactComponent as RedoIcon } from '../assets/icons/RedoIcon.svg';
import { ReactComponent as SaveIcon } from '../assets/icons/SaveIcon.svg';
import { ReactComponent as TrimIcon } from '../assets/icons/TrimIcon.svg';
import { ReactComponent as UndoIcon } from '../assets/icons/UndoIcon.svg';
import ButtonGroup from '../Button/ButtonGroup';
import IconButton from '../Button/IconButton';
import { Tool, ToolbarAction } from './types';

interface ToolbarProps {
  isEditable?: boolean;
  isRangeSelected?: boolean;
  cutAudio?: () => void;
  trimAudio?: () => void;
  onSave?: () => void;
}

const ToolbarIcon = (action: ToolbarAction) => {
  switch (action) {
    case 'undo':
      return <UndoIcon />;
    case 'redo':
      return <RedoIcon />;
    case 'cut':
      return <CutIcon />;
    case 'trim':
      return <TrimIcon />;
    case 'save':
      return <SaveIcon />;
    default:
      return null;
  }
};

const Toolbar = ({
  isEditable = false,
  isRangeSelected = false,
  cutAudio,
  trimAudio,
  onSave,
}: ToolbarProps) => {
  const { undo, redo, isUndoable, isRedoable, currentSnapshot } =
    useUndoManager();
  const [isBufferChanged, setIsBufferChanged] = useState(false);
  const { findAudioBufferIndex } = useAudioBufferStore();

  const tools: Tool[][] = useMemo(
    () => [
      [
        {
          action: 'undo',
          disabled: !isUndoable,
          onClick: () => undo(),
        },
        { action: 'redo', disabled: !isRedoable, onClick: () => redo() },
      ],
      ...(cutAudio && trimAudio && onSave
        ? [
            [
              {
                action: 'cut',
                disabled: !isRangeSelected,
                onClick: () => cutAudio(),
              } as Tool,
              {
                action: 'trim',
                disabled: !isRangeSelected,
                onClick: () => trimAudio(),
              } as Tool,
              {
                action: 'save',
                // trim 또는 cut을 했을 때만 저장 가능
                disabled: !isBufferChanged,
                onClick: () => onSave(),
              } as Tool,
            ],
          ]
        : []),
    ],
    [
      cutAudio,
      isRangeSelected,
      isRedoable,
      isUndoable,
      redo,
      trimAudio,
      undo,
      onSave,
      isBufferChanged,
    ]
  );

  useEffect(() => {
    if (!currentSnapshot) {
      setIsBufferChanged(false);
      return;
    }
    // trim이나 cut으로 audioBuffer 변경이 있을 때 설정
    const bufferIndex = findAudioBufferIndex(currentSnapshot.audioBufferId);
    setIsBufferChanged(bufferIndex > 0);
  }, [currentSnapshot, findAudioBufferIndex]);

  return (
    <ButtonGroup className="sup-audio-toolbar" radius="none">
      {tools.map((tool, index) => (
        <ButtonGroup key={index}>
          {tool.map((toolItem, index) => (
            <IconButton
              key={index}
              color={Grey[800]}
              variant="contained"
              disabled={!isEditable || toolItem.disabled}
              onKeyDown={(e) => {
                if (e.code === 'Space') {
                  e.preventDefault();
                }
              }}
              onClick={toolItem.onClick}
            >
              {ToolbarIcon(toolItem.action)}
            </IconButton>
          ))}
        </ButtonGroup>
      ))}
    </ButtonGroup>
  );
};

export default Toolbar;
