import './index.css';
import './api/index';

import { SUPAuthProvider, SupertoneApps } from '@supertone-inc/auth-sdk-react';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import App from './App';
import AuthInterceptor from './providers/AuthInterceptor';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <SUPAuthProvider
      supertoneApp={SupertoneApps.Toolkit}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <RecoilRoot>
        <BrowserRouter>
          <AuthInterceptor>
            <App />
          </AuthInterceptor>
        </BrowserRouter>
      </RecoilRoot>
    </SUPAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string);
