import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import axios from 'axios';
import { useEffect } from 'react';

const useAxiosInterceptor = () => {
  const { getAccessTokenSilently } = useSUPAuth();

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      async (config) => {
        const token = await getAccessTokenSilently();

        // Add Authorization header to all requests except external requests
        if (token && !config.url?.startsWith('http')) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, [getAccessTokenSilently]);
};

export default useAxiosInterceptor;
