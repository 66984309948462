import { useTranslation } from 'react-i18next';

import { PastelBlue, Primary, Secondary, White } from '../../styles/Colors';
import Button from '../Button/Button';
import ButtonGroup from '../Button/ButtonGroup';
import Modal from '../Modal/Modal';
import ModalBody from '../Modal/ModalBody';
import ModalFooter from '../Modal/ModalFooter';
import ModalHeader from '../Modal/ModalHeader';

interface SaveModalProps {
  isOpen: boolean;
  onClose: () => void;
  onReset: () => void;
  onSave: () => void;
  onOverwrite: () => void;
}

const SaveModal = ({
  isOpen,
  onClose,
  onReset,
  onSave,
  onOverwrite,
}: SaveModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      backdropColor={PastelBlue}
      isPortal={false}
    >
      <ModalHeader>{t('Do you want to leave this file?')}</ModalHeader>
      <ModalBody>
        {t(
          'If you leave this file without saving, unsaved changes will be lost.'
        )}
      </ModalBody>
      <ModalFooter style={{ justifyContent: 'space-between' }}>
        <Button color={Secondary[200]} onClick={onOverwrite}>
          {t('Overwrite')}
        </Button>
        <ButtonGroup>
          <Button variant="outlined" color={White} onClick={onReset}>
            {t("Don't Save")}
          </Button>
          <Button color={Primary[400]} onClick={onSave}>
            {t('Create New')}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default SaveModal;
