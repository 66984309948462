import { ScaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { useCallback, useEffect, useRef } from 'react';

import { ReactComponent as MarkerIcon } from '../../assets/icons/MarkerIcon.svg';
import { MARKER_SIZE, VIEWPORT_PADDING, X_AXIS_HEIGHT } from '../const';
import { Size } from '../types';

interface IndicatorProps {
  xScale: ScaleLinear<number, number>;
  currentTime?: number;
  size: Size;
}

const Indicator = ({ xScale, currentTime, size }: IndicatorProps) => {
  const indicatorRef = useRef<SVGLineElement>(null);

  const updateIndicatorPosition = useCallback(
    (time: number) => {
      if (typeof time !== 'number') return;
      const position = xScale(time);
      const indicator = indicatorRef.current;
      select(indicator).attr(
        'transform',
        `translate(${position}, ${-VIEWPORT_PADDING.top})`
      );
    },
    [xScale]
  );

  useEffect(() => {
    if (typeof currentTime !== 'number') return;
    updateIndicatorPosition(currentTime);
  }, [currentTime, updateIndicatorPosition]);

  return (
    <g className="editor-indicator" ref={indicatorRef}>
      <MarkerIcon x={-MARKER_SIZE.width / 2} />
      <line
        x1={0}
        y1={0}
        x2={0}
        y2={size.height + X_AXIS_HEIGHT + VIEWPORT_PADDING.top}
      />
    </g>
  );
};

export default Indicator;
