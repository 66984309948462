import { getUploadInfo, upload } from '@/api';
import { CardUI, CardUIContent, CardUIHeader } from '@/components/CardUI';
import { Input } from '@/components/FileInput';
import DropZone from '@/components/FileInput/DropZone';
import List from '@/components/List/List';
import { ListItem } from '@/components/List/types';
import Title from '@/components/Title/Title';
import { AUDIO_FILE_ACCEPT, FILE_LIST_HEADERS } from '@/consts';
import useItemHeight from '@/hooks/useItemHeight';
import useToggleItem from '@/hooks/useToggleItem';
import useUploadEvents, { ResourceInfo } from '@/hooks/useUploadEvents';
import useVoiceFileList from '@/hooks/useVoiceFileList';
import MssSourceFileListItem from '@/ListItem/MssSourceFileListItem';
import { ExtendFileInfo } from '@/stores/cvc';
import {
  MssExtendFileInfo,
  mssSourceFileListModel,
  mssSourceFileListSelector,
  mssSourceFileToggleModel,
} from '@/stores/mss';
import { mssUploadingStatesModel } from '@/stores/resource';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import MssSourceListControl from './MssSourceListControl';

interface MSSSourceListProps {
  resizeDep: number;
}
const MSSSourceList: React.FC<MSSSourceListProps> = () => {
  const setMSSSourceList = useSetRecoilState(mssSourceFileListModel);
  const [uploadingStatus, setUploadingStatus] = useRecoilState(
    mssUploadingStatesModel
  );
  const completeUpload = useCallback(
    (data: ResourceInfo, resource_id: string) => {
      setMSSSourceList((prev) => {
        return prev.map((item) => {
          if (item.id === resource_id) {
            return { ...item, ...data };
          }
          return item;
        });
      });
    },
    [setMSSSourceList]
  );
  const addFiles = useCallback(
    async (sessionId: string, files: File[], group?: string) => {
      let resources = [] as MssExtendFileInfo[];
      const resourceResponses = await Promise.all(
        files.map((file) => {
          return getUploadInfo(sessionId, file.name, file.type);
        })
      );
      for (let i = 0; i < resourceResponses.length; i++) {
        const resourceData = resourceResponses[i].data.data;
        const newFiles = {
          name: files[i].name,
          file: files[i],
          id: resourceData.resource_id,
          isChecked: true,
          upload_url: resourceData.upload_url,
        } as ExtendFileInfo;
        resources.push(newFiles);
      }
      setUploadingStatus((prev) => {
        const status = { ...prev };
        resources.forEach((file) => {
          status[file.id] = 'READY';
        });
        return status;
      });
      setMSSSourceList(
        (list = []) => resources.concat(list) as ExtendFileInfo[]
      );
      for (let i = 0; i < resources.length; i++) {
        await upload(resources[i].upload_url as string, files[i]);
      }
    },
    [setMSSSourceList, setUploadingStatus]
  );
  const { uploadFile } = useUploadEvents(
    uploadingStatus,
    setUploadingStatus,
    completeUpload,
    addFiles
  );
  const sourceList = useRecoilValue(mssSourceFileListSelector);
  const { checkAll, updateFile, changeName, setCheckItem } = useVoiceFileList(
    sourceList,
    setMSSSourceList,
    true
  );
  const setToggleList = useSetRecoilState(mssSourceFileToggleModel);
  const { expandAll, expandedAll, toggleItem } = useToggleItem(
    sourceList,
    setToggleList
  );
  const { getItemHeight } = useItemHeight(sourceList);
  const { t } = useTranslation();

  return (
    <CardUI className="mss-cards">
      <CardUIHeader>
        <Title size="lg">{t('SOURCE AUDIO')}</Title>
        <Input
          className="attach-button-primary"
          caption={t('Add Files')}
          accept={AUDIO_FILE_ACCEPT}
          addFiles={uploadFile}
        />
      </CardUIHeader>
      <CardUIContent>
        <section className="sup-files">
          <DropZone
            addFiles={uploadFile}
            accept={AUDIO_FILE_ACCEPT}
            className="file-content"
          >
            <List
              control={<MssSourceListControl />}
              enableAllCheck={true}
              headers={FILE_LIST_HEADERS}
              list={sourceList as ListItem[]}
              checkAll={checkAll}
              expandAll={expandAll}
              expandedAll={expandedAll}
              ListItem={MssSourceFileListItem}
              setToggleItem={toggleItem}
              setCheckItem={setCheckItem}
              getItemSize={getItemHeight}
              emptyMessage={t('No imported audio files yet.') as string}
              changeName={changeName}
              type="Source"
              updateFile={updateFile}
            />
          </DropZone>
        </section>
      </CardUIContent>
    </CardUI>
  );
};

export default MSSSourceList;
