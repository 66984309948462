import { getUploadInfo, upload } from '@/api';
import {
  ExtendFileInfo,
  currentEditFileModel,
  interpolationMapModel,
  isEditingModel,
  prepareEditFileModel,
} from '@/stores/cvc';
import { ResourceStatus } from '@/stores/resource';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SetterOrUpdater,
  useRecoilCallback,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil';

import useConfirmModal from '../pages/cvc/hooks/useConfirmModal';

const useVoiceFileList = <E extends ExtendFileInfo>(
  files: E[],
  setFiles: SetterOrUpdater<E[]>,
  defaultCheck?: boolean
) => {
  const isAudioEditing = useRecoilValue(isEditingModel);
  const { openConfirmModal } = useConfirmModal();
  const { t } = useTranslation();
  const currentEditFile = useRecoilValue(currentEditFileModel);
  const resetCurrentEditFile = useResetRecoilState(currentEditFileModel);
  const resetPrepareEditFile = useResetRecoilState(prepareEditFileModel);

  const setInterpolationMap = useRecoilCallback(({ set }) => {
    return (ids: string[], group?: string) => {
      set(interpolationMapModel, (prev) => {
        const newMap = { ...prev };
        if (group) {
          newMap.GroupB = [...newMap.GroupB, ...ids];
        } else {
          newMap.GroupB = newMap.GroupB.filter(
            (id) => !ids.find((item) => item === id)
          );
        }
        return newMap;
      });
    };
  }, []);
  const checkedAll = useMemo(() => {
    return files.length > 0 && files.every((item) => item.isChecked);
  }, [files]);
  const checkAll = useCallback(() => {
    setFiles((list) => {
      return list.map((item) => ({ ...item, isChecked: !checkedAll }));
    });
  }, [checkedAll, setFiles]);
  const deleteFiles = useCallback(
    (deleteMap: ResourceStatus, confirmRequired = false) => {
      // deleteMap의 key에 currentEditFile.id가 있는지 확인
      const isEditingFileIncluded =
        !!currentEditFile &&
        Object.keys(deleteMap).includes(currentEditFile.id);
      const filteredList = (files: E[]) =>
        files.filter(
          (item) =>
            !item.isChecked ||
            !['COMPLETE', 'FAILED'].includes(deleteMap[item.id])
        );

      if (!confirmRequired || !isAudioEditing) {
        setFiles((list) => filteredList(list));
        if (isEditingFileIncluded) {
          resetPrepareEditFile();
          resetCurrentEditFile();
        }
        return;
      }
      openConfirmModal({
        title: t('Unsaved Chnages'),
        message: t('You have unsaved changes, Do you want to delete?'),
        onConfirm: () => {
          setFiles((list) => filteredList(list));
          if (isEditingFileIncluded) {
            resetPrepareEditFile();
            resetCurrentEditFile();
          }
        },
        confirmText: t('Delete Anyway') as string,
      });
    },
    [
      setFiles,
      isAudioEditing,
      openConfirmModal,
      t,
      resetPrepareEditFile,
      resetCurrentEditFile,
      currentEditFile,
    ]
  );
  const addFiles = useCallback(
    async (sessionId: string, files: File[], group?: string) => {
      let resources = [] as E[];
      const resourceResponses = await Promise.all(
        files.map((file) => {
          return getUploadInfo(sessionId, file.name, file.type);
        })
      );
      for (let i = 0; i < resourceResponses.length; i++) {
        const resourceData = resourceResponses[i].data.data;
        const newFiles = {
          name: files[i].name,
          id: resourceData.resource_id,
          isChecked: !!defaultCheck,
          upload_url: resourceData.upload_url,
        } as E;
        resources.push(newFiles);
      }
      setFiles((list = []) => resources.concat(list) as E[]);
      setInterpolationMap(
        resources.map((item) => item.id),
        group
      );
      for (let i = 0; i < resources.length; i++) {
        await upload(resources[i].upload_url as string, files[i]);
      }
    },
    [defaultCheck, setFiles, setInterpolationMap]
  );
  const setCheckItem = useCallback(
    (id: string) => {
      setFiles((list) => {
        return list.map((item) => {
          if (item.id === id) {
            return { ...item, isChecked: !item.isChecked };
          }
          return item;
        });
      });
    },
    [setFiles]
  );
  const changeName = useCallback(
    (id: string, name: string) => {
      setFiles((list) => {
        return list.map((item) => {
          if (item.id === id) {
            return { ...item, name };
          } else return item;
        });
      });
    },
    [setFiles]
  );

  const updateFile = useCallback(
    (id: string, fileInfo: ExtendFileInfo) => {
      setFiles((list) => {
        return list.map((item) => {
          if (item.id === id) {
            return { ...item, ...fileInfo };
          } else return item;
        });
      });
    },
    [setFiles]
  );

  return {
    addFiles,
    checkAll,
    deleteFiles,
    setCheckItem,
    changeName,
    files,
    updateFile,
  };
};
export default useVoiceFileList;
