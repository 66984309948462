import List from '@/components/List/List';
import Title from '@/components/Title/Title';
import TargetSimpleListItem from '@/ListItem/TargetSimpleListItem';
import { CvcTargetFileInfo, ExtendFileInfo } from '@/stores/cvc';
import { Black } from '@/styles/Colors';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useItemHeight from '../../../hooks/useItemHeight';
import SimpleListItem from '../../../ListItem/SimpleListItem';
import useMatrix from '../hooks/useMatrix';

interface GenerateListProps {
  resizeDep: number;
  list: CvcTargetFileInfo[];
  title: string;
  dimension: 'row' | 'col';
}
const GenerateList = ({
  title,
  list,
  resizeDep,
  dimension,
}: GenerateListProps) => {
  const { toggleCells } = useMatrix();

  const checkItem = useCallback(
    (id: string) => {
      const items = list.filter((item) => item.id === id);
      toggleCells(id, dimension, !items[0].isChecked);
    },
    [toggleCells, dimension, list]
  );
  const selectedCount = useMemo(
    () => list.filter((item) => item.isChecked).length,
    [list]
  );

  const { getItemHeight: getSourceItemHeight } = useItemHeight(
    list as ExtendFileInfo[],
    true
  );
  const { t } = useTranslation();
  return (
    <div className="file-content">
      <div className="generated-list-header">
        <Title size="md" color={Black}>
          {title}
        </Title>
        <div className="selected-file-info">
          <span>{selectedCount}</span>{' '}
          {t('Files Selected', { n: selectedCount > 1 ? 's' : '' })}
        </div>
      </div>
      <List
        list={list}
        ListItem={dimension === 'row' ? SimpleListItem : TargetSimpleListItem}
        setCheckItem={checkItem}
        resizeDep={resizeDep}
        emptyMessage={t('No imported audio files yet.') as string}
        getItemSize={getSourceItemHeight}
      />
    </div>
  );
};
export default GenerateList;
