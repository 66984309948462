import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import mixpanel from 'mixpanel-browser';
import { useCallback } from 'react';

export const LOGS = {
  TIME_SPENT: 'TIME_SPENT',
};
type LOG_EVENTS = keyof typeof LOGS;

// Get group info from user info
const PERMISSION_REGEXP = /company\/([^/]+)/;
export const getGroupInfo = (permission: string[] = []) => {
  const user_group = permission
    ?.filter((permission: string) => permission.match(PERMISSION_REGEXP))
    .map((permission: string) => permission.match(PERMISSION_REGEXP)?.[1]);
  return user_group;
};
const INTERVAL_LOG_DURATION = 5 * 60 * 1000;
export const useIntervalLog = (interval: number = INTERVAL_LOG_DURATION) => {
  const { user } = useSUPAuth();
  // [workaround] 내부로직을 사용해서 넘기는 경우를 대비해서 훅으로 분리
  const startTime = Date.now();
  const track = useCallback(
    (eventName: LOG_EVENTS, props?: any) => {
      const user_group = getGroupInfo(user?.permissions);
      if (
        process.env.REACT_APP_ENV !== 'prd' ||
        user?.email?.endsWith('@supertone.ai')
      )
        return;
      const params = {
        user: user?.email,
        user_group,
        spent_time: Date.now() - startTime,
      };
      mixpanel.track(eventName, { ...params, ...(props || {}) });
    },
    [user, startTime]
  );
  const start = useCallback(
    (eventName: LOG_EVENTS) => {
      const timer = setInterval(() => {
        track(eventName);
      }, interval);
      return { stop: () => clearInterval(timer) };
    },
    [track, interval]
  );
  const end = useCallback(
    (eventName: LOG_EVENTS) => {
      track(eventName);
    },
    [track]
  );
  return {
    start,
    end,
  };
};
