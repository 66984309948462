import {
  Black,
  Grey,
  Primary,
  PureBlack,
  Secondary,
  White,
} from '@/styles/Colors';
import { customScrollbar } from '@/styles/mixins';
import { FontSize, FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

const StyledList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: auto;
  flex-direction: column;
  &.selected::after {
    content: '';
    display: block;
    position: absolute;
    background-color: ${Grey['50']};
    /* TODO: 확인 필요 */
    outline: 1px solid ${Primary[400]};
    width: 100%;
    height: 100%;
  }
  .sup-file-info {
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    .status {
      margin-left: 1rem;
      display: flex;
      color: ${Grey[50]};
      font-weight: ${FontWeight['SemiBold']};
      align-items: center;
    }
    .buttons {
      display: flex;
      visibility: hidden;
      align-items: center;
      .sup-icon-button {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
    &.checked {
      background-color: ${Grey[500]};
      .buttons {
        visibility: visible;
      }
    }
  }
  .sup-file-header {
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: 1px solid ${White};
    background-color: ${Black};
    color: ${Grey[400]};
    & > span {
      display: flex;
      align-items: center;
    }
  }
  .sup-file-list {
    display: flex;
    flex: auto;
    width: 100%;
    .sup-file-list-inner {
      position: absolute;
      width: 100%;
      &.has-header {
        top: 5rem;
      }
    }
    .sup-file-list-item {
      color: ${White};
      background-color: ${PureBlack};
      border-bottom: 1px solid ${Grey[500]};
      &.simple-group {
        background-color: ${Grey[450]} !important;
      }
      &.simple-merge {
        color: ${Secondary[50]};
        .file-name {
          color: ${Secondary[50]} !important;
          display: inline-flex !important;
          align-items: center;
          gap: 0.625rem;
        }
      }
      &.merge-group {
        border-bottom: 1px solid ${Secondary[50]};
      }
      .inner {
        display: flex;
        justify-content: space-between;
        height: 2.5rem;
        margin-right: 1rem;
        & > span {
          display: flex;
          align-items: center;
        }
        .checked {
          width: 3.25rem;
          text-align: center;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        .merge-name {
          display: flex;
          align-items: center;
          gap: 1rem;
          color: ${Secondary[50]};
          .file-name,
          .editable {
            color: ${Secondary[50]};
          }
        }
        .file-name {
          flex: 1;
          color: ${Grey[100]};
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          word-break: break-all;
          line-height: 150%;
          margin-right: 1.25rem;
        }
        .editable {
          font-size: ${FontSize['Md']};
          width: 23.125rem;
          border: 0;
          outline: 0;
          height: 1.25rem;
          color: ${Grey[100]};
          background-color: transparent;
          word-break: keep-all;
          line-height: 150%;
          margin-right: 1.25rem;
          white-space: nowrap;
        }
        .file-player {
          height: 2.5rem;
        }
        .play-time {
          margin-right: 1rem;
          white-space: nowrap;
          .edit-button {
            display: none;
          }
          .current-time {
            color: ${Primary[200]};
            &:after {
              content: ' / ';
              color: ${Grey[200]};
            }
          }
        }
        &:hover .editable-item {
          .time-zone {
            display: none;
          }
          .edit-button {
            padding: 0 0.75rem;
            display: inline-flex;
            border-radius: 12.5rem;
            border: 1px solid ${White};
          }
        }
      }
      &.even {
        background-color: ${Black};
        &:hover {
          background-color: ${Grey[700]};
        }
      }
      &:hover {
        background-color: ${Grey[700]};
      }
      &.target-group {
        background-color: ${Grey[450]};
        &:hover {
          background-color: ${Grey[450]};
        }
      }
      &.edit {
        background-color: ${Primary[700]};
        &:hover {
          background-color: ${Primary[700]};
        }
      }
    }
    .sup-file-v-scroll {
      ${customScrollbar}
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .sup-file-empty {
    display: flex;
    flex: auto;
    justify-content: center;
    align-items: center;
    font-size: ${FontSize['Xl']};
    color: ${Grey[500]};
  }
`;
export default StyledList;
