import { ReactComponent as MergeIcon } from '@/assets/icons/MergeIcon.svg';
import Checkbox from '@/components/Checkbox/Checkbox';
import { ListItemData } from '@/components/List/types';
import useCvcModelState from '@/pages/cvc/hooks/useCvcModelState';
import { Grey, White } from '@/styles/Colors';
import classNames from 'classnames';
import { CSSProperties, useMemo } from 'react';

const SimpleListItem: React.FC<{
  index: number;
  style: CSSProperties;
  data: ListItemData;
}> = ({ index, style, data }) => {
  const { list, setCheckItem } = data;
  const item = list[index];

  const groupLabel = useMemo(() => {
    return item.name || `Group ${(item.mergeIndex as number) + 1}`;
  }, [item]);
  const { isSourceOnly } = useCvcModelState();

  // [workaround] nth-child even 사용시 vsScroll 동작과 충돌로 스크롤시 색이 일시적으로 섞이는 현상이 생김
  return (
    <li
      style={style}
      className={classNames(
        'sup-file-list-item',
        index % 2 && 'even',
        item.isMergeGroup && 'simple-merge',
        item.isGroup && 'simple-group'
      )}
    >
      <section className="inner">
        <span>
          <span className="checked">
            <Checkbox
              disabled={isSourceOnly}
              color={item.isGroup || item.isChecked ? White : Grey[400]}
              checked={item.isChecked}
              onChange={() => setCheckItem(item.id)}
            />
          </span>
          <span className="file-name">
            {item.isMergeGroup ? (
              <>
                <MergeIcon />
                <span>{groupLabel}</span>
              </>
            ) : (
              item.name
            )}
          </span>
        </span>
      </section>
    </li>
  );
};
export default SimpleListItem;
