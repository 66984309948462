import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

let store: Array<{ id: string; buffer: AudioBuffer }> = [];

const useAudioBufferStore = () => {
  // Get buffer by id
  const getAudioBufferById = useCallback((id: string) => {
    const item = store.find((item) => item.id === id);
    return item ? item.buffer : undefined;
  }, []);

  // Find buffer index
  const findAudioBufferIndex = useCallback((id: string) => {
    const index = store.findIndex((item) => item.id === id);
    return index;
  }, []);

  // Find buffer id
  const findAudioBufferId = useCallback((audioBuffer: AudioBuffer) => {
    const item = store.find((item) => item.buffer === audioBuffer);
    return item ? item.id : undefined;
  }, []);

  // Add buffer
  const addAudioBufferItem = useCallback((buffer: AudioBuffer) => {
    const id = uuid();
    const item = { id, buffer };
    store.push(item);
    return id;
  }, []);

  // Remove buffer
  const removeAudioBufferItem = useCallback((id: string) => {
    const index = store.findIndex((item) => item.id === id);
    if (index !== -1) {
      store.splice(index, 1);
    }
  }, []);

  // Clear store
  const clearAudioBufferStore = useCallback(() => {
    store = [];
  }, []);

  return {
    findAudioBufferId,
    findAudioBufferIndex,
    getAudioBufferById,
    addAudioBufferItem,
    removeAudioBufferItem,
    clearAudioBufferStore,
  };
};

export default useAudioBufferStore;
