import { ReactComponent as ZoomInIcon } from '../assets/icons/ZoomInIcon.svg';
import { ReactComponent as ZoomOutIcon } from '../assets/icons/ZoomOutIcon.svg';
import { ReactComponent as ZoomResetIcon } from '../assets/icons/ZoomResetIcon.svg';
import ButtonGroup from '../Button/ButtonGroup';
import IconButton from '../Button/IconButton';
import { MIN_WAVE_SAMPLES } from './const';

interface HorizontalZoomProps {
  // Audio buffer
  audioBuffer: AudioBuffer;
  // Channel data
  channelData: Float32Array;
  // Zoom range
  range?: [number, number];
  // Update zoom range
  updateRange?: (range: [number, number]) => void;
  // Update zoom scale
  updateScale?: (ratio: number) => void;
  // Zoom step(px)
  step?: number;
}

const HorizontalZoom = ({
  audioBuffer,
  channelData,
  range,
  updateRange,
  updateScale,
  step = 0.1,
}: HorizontalZoomProps) => {
  return (
    <ButtonGroup radius="none" className="editor-zoom">
      <IconButton
        onClick={() => updateScale?.(1 + step)}
        disabled={range && range[1] - range[0] >= audioBuffer.duration}
      >
        <ZoomOutIcon />
      </IconButton>
      <IconButton
        onClick={() => updateRange?.([0, audioBuffer.duration])}
        disabled={range && range[1] - range[0] >= audioBuffer.duration}
      >
        <ZoomResetIcon />
      </IconButton>
      <IconButton
        onClick={() => updateScale?.(1 - step)}
        disabled={channelData.length <= MIN_WAVE_SAMPLES}
      >
        <ZoomInIcon />
      </IconButton>
    </ButtonGroup>
  );
};

export default HorizontalZoom;
