import { ReactComponent as BottomArrowIcon } from '@/components/assets/icons/BottomArrowIcon.svg';
import { ReactComponent as RightArrowIcon } from '@/components/assets/icons/RightArrowIcon2.svg';
import IconButton from '@/components/Button/IconButton';
import Checkbox from '@/components/Checkbox/Checkbox';
import { ListItemData } from '@/components/List/types';
import { MSS_CONTROL_TYPE } from '@/consts/mss';
import StyledFileListItem from '@/ListItem/StyledFileListItem';
import { currentEditFileModel } from '@/stores/cvc';
import { MssExtendFileInfo, mssSourceFileListModel } from '@/stores/mss';
import { mssUploadingStatesModel } from '@/stores/resource';
import { Black, Primary } from '@/styles/Colors';
import classNames from 'classnames';
import { CSSProperties, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import DisplayTime from './DisplayTime';
import EditableName from './EditableName';
import FileLoading from './FileLoading';
import FilePlayer from './FilePlayer';

const MssSourceFileListItem: React.FC<{
  index: number;
  style: CSSProperties;
  data: ListItemData;
}> = ({ index, style, data }) => {
  const { list, setToggleItem, setCheckItem, type, changeName, updateFile } =
    data;
  const item = list[index] as MssExtendFileInfo;
  const { t } = useTranslation();
  const currentEditFile = useRecoilValue(currentEditFileModel);
  const [currentPlayTime, setCurrentPlayTime] = useState<number>();
  const setMssSourceFileList = useSetRecoilState(mssSourceFileListModel);
  const setMssUploadingStatus = useSetRecoilState(mssUploadingStatesModel);
  const onCancelFetching = useCallback(() => {
    setMssSourceFileList((prev) => prev.filter((v) => v.id !== item.id));
    setMssUploadingStatus((prev) => {
      const status = { ...prev };
      delete status[item.id];
      return status;
    });
  }, [item, setMssSourceFileList, setMssUploadingStatus]);

  // [workaround] nth-child even 사용시 vsScroll 동작과 충돌로 스크롤시 색이 일시적으로 섞이는 현상이 생김
  return (
    <StyledFileListItem
      style={style}
      className={classNames(
        'sup-file-list-item',
        'mss-result-list-item',
        item.isChecked && 'selected',
        item.id === currentEditFile?.id && 'edit'
      )}
      key={item.id}
    >
      <section className="inner">
        <span>
          <span className="checked">
            <Checkbox
              className="mss-result-check "
              color={item.isChecked ? Primary[400] : Black}
              checked={item.isChecked}
              onChange={() => setCheckItem(item.id)}
            />
          </span>
          {item.type && (
            <span className={classNames('mss-type', item.type)}>
              {MSS_CONTROL_TYPE[item.type]}
            </span>
          )}
          <EditableName item={item} changeName={changeName} />
        </span>
        <span>
          <DisplayTime
            type={type}
            id={item.id}
            currentTime={currentPlayTime}
            duration={item.duration}
            isExpand={item.isExpanded}
            editable={false}
          />
          <IconButton
            color="transparent"
            onClick={() => setToggleItem(item.id)}
          >
            {item.isExpanded ? <BottomArrowIcon /> : <RightArrowIcon />}
          </IconButton>
        </span>
      </section>
      {item.isExpanded && (
        <FilePlayer
          item={item}
          type={type}
          updateCurrentTime={setCurrentPlayTime}
          updateFile={updateFile}
        />
      )}
      {item.isFetching && (
        <FileLoading text={t('Uploading')} onCancel={onCancelFetching} />
      )}
    </StyledFileListItem>
  );
};
export default MssSourceFileListItem;
