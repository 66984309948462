import { getUploadInfo, upload } from '@/api';
import List from '@/components/List/List';
import { ListItem } from '@/components/List/types';
import { AUDIO_FILE_ACCEPT, FILE_LIST_HEADERS } from '@/consts';
import useUploadEvents, { ResourceInfo } from '@/hooks/useUploadEvents';
import useVoiceFileList from '@/hooks/useVoiceFileList';
import {
  CvcTargetFileInfo,
  ExtendFileInfo,
  interpolationMapModel,
  mergedTargetFileMapModel,
  targetFileListModel,
  targetFileListSelector,
  targetFileListToggleModel,
} from '@/stores/cvc';
import { targetUploadingStatesModel } from '@/stores/resource';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { CardUIContent } from '../../../components/CardUI';
import DropZone, { DIRECTION } from '../../../components/FileInput/DropZone';
import useToggleItem from '../../../hooks/useToggleItem';
import TargetItemList from '../../../ListItem/TargetItemList';
import { isInterpolationSelector } from '../../../stores/models';
import {
  DRAG_FROM_TARGET,
  DRAG_ITEM_KEY,
  EXPANDED_ITEM_SIZE,
  NARROW_ITEM_SIZE,
} from '../config';
import Ratio from './Ratio';
import TargetListControl from './TargetListControl';
import TargetPanelHeader from './TargetPanelHeader';

const TargetPanel: React.FC<{ resizeDep: number }> = ({ resizeDep }) => {
  const setTargetList = useSetRecoilState(targetFileListModel);
  const targetList = useRecoilValue(targetFileListSelector);

  // Common File list Control
  const { setCheckItem, changeName, updateFile } = useVoiceFileList(
    targetList,
    setTargetList
  );

  // toggle File List Item
  const [toggleList, setToggleList] = useRecoilState(targetFileListToggleModel);
  const { toggleItem } = useToggleItem(targetList, setToggleList);
  const { t } = useTranslation();
  const isInterpolation = useRecoilValue(isInterpolationSelector);
  const mergedTargetFileMap = useRecoilValue(mergedTargetFileMapModel);
  const checkItem = useCallback(
    (id: string) => {
      // merge group 이면, 해당 그룹의 아이템들 찾아서 같이 isChecked 변경
      const target = targetList.find((item) => item.id === id);
      // merge group을 선택 했을 경우, 해당 그룹의 모든 아이템을 선택
      if (target?.isMergeGroup) {
        mergedTargetFileMap[id].list.forEach((listId) => {
          setCheckItem(listId);
        });
      } else {
        setCheckItem(id);
      }
    },
    [setCheckItem, targetList, mergedTargetFileMap]
  );

  // 전체 확장 상태 체크
  const isExpandedAll = useMemo(() => {
    // merge Group expanded state check
    const flatMergeGroupItems = Object.values(mergedTargetFileMap)
      .filter((f) => f)
      .reduce(
        (acc, { isExpanded, list }) => (isExpanded ? [...acc, ...list] : acc),
        [] as string[]
      );
    const visibleMergedItem = targetList.filter((target) =>
      flatMergeGroupItems.includes(target.id)
    );
    const isAllMergeGroupExpanded = Object.values(visibleMergedItem).every(
      (item) => item.isExpanded || toggleList[item.id]
    );
    // All item expanded state check except merge group
    const isAllItemExpanded = Object.values(
      targetList.filter((item) => !item.isMerged)
    ).every(({ id }) => toggleList[id]);
    return isAllMergeGroupExpanded && isAllItemExpanded;
  }, [mergedTargetFileMap, targetList, toggleList]);

  // 전체 확장 토글
  const expandAll = useRecoilCallback(
    ({ set }) =>
      async () => {
        // set group expanded state
        set(mergedTargetFileMapModel, (prev) => {
          const newMap = { ...prev };
          Object.keys(newMap).forEach((key) => {
            newMap[key] = {
              ...newMap[key],
              isExpanded: !isExpandedAll,
            };
          });
          return newMap;
        });

        // set target expanded state
        set(targetFileListToggleModel, (prev) => {
          const newMap = { ...prev };
          targetList
            .filter(({ isMergeGroup }) => !isMergeGroup)
            .forEach((file) => {
              newMap[file.id] = !isExpandedAll;
            });
          // set merge group items expanded state(only for expanded state check logic)
          Object.values(mergedTargetFileMap).forEach(({ list }) => {
            list.forEach((listId) => {
              newMap[listId] = !isExpandedAll;
            });
          });
          return newMap;
        });
      },
    [isExpandedAll, targetList]
  );

  // check all for merge group
  const checkedAll = useMemo(() => {
    return targetList
      .filter((item) => !item.isMerged)
      .every(({ isChecked }) => isChecked);
  }, [targetList]);
  const checkAll = useCallback(() => {
    setTargetList((prev) => {
      const newList = [] as ExtendFileInfo[];
      prev.forEach((file, index) => {
        newList[index] = { ...file, isChecked: !checkedAll };
      });
      return newList;
    });
  }, [checkedAll, setTargetList]);

  const [uploadingStatus, setUploadingStatus] = useRecoilState(
    targetUploadingStatesModel
  );

  const setInterpolationMap = useSetRecoilState(interpolationMapModel);
  const setInterpolations = useCallback(
    (ids: string[], group?: string) => {
      setInterpolationMap((prev) => {
        const newMap = { ...prev };
        if (group) {
          newMap.GroupB = [...newMap.GroupB, ...ids];
        } else {
          newMap.GroupB = newMap.GroupB.filter(
            (id) => !ids.find((item) => item === id)
          );
        }
        return newMap;
      });
    },
    [setInterpolationMap]
  );

  const addFiles = useCallback(
    async (sessionId: string, files: File[], group?: string) => {
      let resources = [] as CvcTargetFileInfo[];
      const resourceResponses = await Promise.all(
        files.map((file) => {
          return getUploadInfo(sessionId, file.name, file.type);
        })
      );
      for (let i = 0; i < resourceResponses.length; i++) {
        const resourceData = resourceResponses[i].data.data;
        const newFiles = {
          name: files[i].name,
          file: files[i],
          id: resourceData.resource_id,
          isChecked: true,
          upload_url: resourceData.upload_url,
        } as CvcTargetFileInfo;
        resources.push(newFiles);
      }
      setUploadingStatus((prev) => {
        const status = { ...prev };
        resources.forEach((file) => {
          status[file.id] = 'READY';
        });
        return status;
      });
      setTargetList(
        (list = []) => resources.concat(list) as CvcTargetFileInfo[]
      );
      setInterpolations(
        resources.map((item) => item.id),
        group
      );
      for (let i = 0; i < resources.length; i++) {
        await upload(resources[i].upload_url as string, files[i]);
      }
    },
    [setTargetList, setInterpolations, setUploadingStatus]
  );

  const completeUpload = useCallback(
    (data: ResourceInfo, resource_id: string) => {
      setTargetList((prev) => {
        return prev.map((item) => {
          if (item.id === resource_id) {
            return { ...item, ...data };
          }
          return item;
        });
      });
    },
    [setTargetList]
  );
  const { uploadFile } = useUploadEvents(
    uploadingStatus,
    setUploadingStatus,
    completeUpload,
    addFiles
  );

  const addTargetFiles = useCallback(
    (files: File[], direction?: DIRECTION) => {
      if (isInterpolation) {
        if (direction === 'upper_right' || direction === 'lower_right') {
          uploadFile(files, 'GroupB');
        } else {
          uploadFile(files);
        }
      } else {
        uploadFile(files);
      }
    },
    [isInterpolation, uploadFile]
  );

  // target 케이스의 경우 mergeGroup 등의 정보로 높이가 달라질수 있으므로 별도 체크
  const getTargetItemHeight = useCallback(
    (index: number) => {
      // [workaround] react-window getItemSize must return number
      const item = targetList[index];
      return !item.isExpanded || item?.isMergeGroup || item?.isGroup
        ? NARROW_ITEM_SIZE
        : EXPANDED_ITEM_SIZE;
    },
    [targetList]
  );

  const moveOtherGroup = useCallback(
    (groupName: string, id: string) => {
      setInterpolationMap((prev) => {
        let groupB = [...prev.GroupB];
        if (groupName === 'GroupB') {
          groupB = groupB.filter((item) => item !== id);
        } else {
          groupB.push(id);
        }
        return {
          GroupB: groupB,
        };
      });
    },
    [setInterpolationMap]
  );

  const onDrop = useCallback(
    (e: React.DragEvent<HTMLElement>) => {
      const target = e.dataTransfer?.getData(DRAG_ITEM_KEY);
      if (target) {
        const { id, groupName, from } = JSON.parse(target);
        if (from !== DRAG_FROM_TARGET) {
          return;
        }
        const groups = e.currentTarget.querySelectorAll('.target-group');
        // [workaround]
        // This logic is necessary for virtual scroll
        if (groups.length) {
          const group = groups[groups.length - 1] as HTMLElement;
          if (groupName === 'GroupA') {
            if (group.dataset.group !== groupName) {
              if (e.clientY > group.getBoundingClientRect().top) {
                moveOtherGroup(groupName, id);
              }
            }
          } else if (groupName === 'GroupB') {
            if (group.dataset.group === groupName) {
              if (e.clientY < group.getBoundingClientRect().top) {
                moveOtherGroup(groupName, id);
              }
            } else {
              moveOtherGroup(groupName, id);
            }
          }
        } else {
          const firstItem = e.currentTarget.querySelector(
            '.sup-file-list-item'
          );
          const group = (firstItem as HTMLElement).dataset.group;
          if (group !== groupName) {
            moveOtherGroup(groupName, id);
          }
        }
      }
    },
    [moveOtherGroup]
  );

  return (
    <>
      <TargetPanelHeader addFiles={uploadFile} />
      <CardUIContent
        className={classNames('sup-files', isInterpolation && 'wrap-ratio')}
      >
        <DropZone
          dargKey={DRAG_ITEM_KEY}
          addFiles={addTargetFiles}
          accept={AUDIO_FILE_ACCEPT}
          className={classNames(isInterpolation && 'interpolation')}
          onDrop={onDrop}
        >
          <List
            control={<TargetListControl />}
            headers={FILE_LIST_HEADERS}
            list={targetList as ListItem[]}
            checkedAll={checkedAll}
            checkAll={checkAll}
            expandAll={expandAll}
            expandedAll={isExpandedAll}
            ListItem={TargetItemList}
            setToggleItem={toggleItem}
            setCheckItem={checkItem}
            resizeDep={resizeDep}
            getItemSize={getTargetItemHeight}
            enableAllCheck={!isInterpolation}
            changeName={changeName}
            emptyMessage={t('No imported audio files yet.') as string}
            type="Target"
            updateFile={updateFile}
          />
        </DropZone>
        {isInterpolation && <Ratio />}
      </CardUIContent>
    </>
  );
};
export default TargetPanel;
