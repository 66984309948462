import { PropsWithChildren, createContext, useMemo, useState } from 'react';
export interface UndoManager<T> {
  push: (snapshot: T) => void;
  undo: () => void;
  redo: () => void;
  reset: () => void;
  isUndoable: boolean;
  isRedoable: boolean;
  currentSnapshot?: T;
}

export const UndoManagerContext = createContext<UndoManager<any> | null>(null);

export const UndoManagerProvider = <T,>({
  children,
}: PropsWithChildren<{}>) => {
  // Current pointer of the stack.
  const [currentPointer, setCurrentPointer] = useState(-1);
  // Stack of undoable snapshots.
  const [stack, setStack] = useState<T[]>([]);
  // Current snapshot.
  const currentSnapshot = useMemo(
    () => stack[currentPointer],
    [stack, currentPointer]
  );

  // Push a new snapshot to the stack, and execute it.
  const push = (snapshot: T) => {
    // If the current pointer is not at the end of the stack,
    // remove all the tasks after the current pointer.
    if (currentPointer < stack.length - 1) {
      stack.length = currentPointer + 1;
    }
    setStack([...stack, snapshot]);
    setCurrentPointer(currentPointer + 1);
  };

  // Undo the task at the current pointer.
  const undo = () => {
    if (currentPointer < 0) return;
    setCurrentPointer(currentPointer - 1);
  };

  // Redo the task at the current pointer.
  const redo = () => {
    if (currentPointer === stack.length - 1) return;
    setCurrentPointer(currentPointer + 1);
  };

  // Reset the stack.
  const reset = () => {
    setStack([]);
    setCurrentPointer(-1);
  };

  // A boolean value for whether undo is possible.
  const isUndoable = useMemo(() => currentPointer >= 0, [currentPointer]);

  // A boolean value for whether redo is possible.
  const isRedoable = useMemo(
    () => currentPointer < stack.length - 1,
    [currentPointer, stack.length]
  );

  return (
    <UndoManagerContext.Provider
      value={{
        push,
        undo,
        redo,
        reset,
        isUndoable,
        isRedoable,
        currentSnapshot,
      }}
    >
      {children}
    </UndoManagerContext.Provider>
  );
};
