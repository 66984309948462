import { Grey, PureBlack, White } from '@/styles/Colors';
import { FontSize, FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

const StyledMssAndSEGuide = styled.section`
  height: 3.375rem;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: row;
  background-color: ${Grey[600]};
  border-bottom: 1px solid ${Grey[500]};
  border-top: 1px solid ${PureBlack};
  .description {
    display: flex;
    justify-content: center;
    flex: 1;
    text-align: center;
    color: ${Grey[200]};
    .main-desc {
      color: ${White};
      font-size: ${FontSize['Md']};
      font-weight: ${FontWeight['SemiBold']};
    }
  }
`;
export default StyledMssAndSEGuide;
