import { ReactComponent as BottomArrowIcon } from '@/components/assets/icons/BottomArrowIcon.svg';
import { ReactComponent as RightArrowIcon } from '@/components/assets/icons/RightArrowIcon2.svg';
import IconButton from '@/components/Button/IconButton';
import Checkbox from '@/components/Checkbox/Checkbox';
import { ListItemData } from '@/components/List/types';
import StyledFileListItem from '@/ListItem/StyledFileListItem';
import { ExtendFileInfo, sourceFileListModel } from '@/stores/cvc';
import { currentEditFileModel } from '@/stores/cvc';
import { sourceUploadingStatesModel } from '@/stores/resource';
import { Grey, White } from '@/styles/Colors';
import classNames from 'classnames';
import { CSSProperties, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import useIsHighlightPanel from '../pages/cvc/hooks/useIsHighlightPanel';
import DisplayTime from './DisplayTime';
import EditableName from './EditableName';
import FileLoading from './FileLoading';
import FilePlayer from './FilePlayer';

const SourceFileListItem: React.FC<{
  index: number;
  style: CSSProperties;
  data: ListItemData;
}> = ({ index, style, data }) => {
  const { list, setToggleItem, setCheckItem, type, changeName, updateFile } =
    data;
  const item = list[index] as ExtendFileInfo;
  const { t } = useTranslation();
  const currentEditFile = useRecoilValue(currentEditFileModel);
  const [currentPlayTime, setCurrentPlayTime] = useState<number>();
  const isPanelActive = useIsHighlightPanel('VoiceFiles');
  const setSourceList = useSetRecoilState(sourceFileListModel);
  const setUploadingStatus = useSetRecoilState(sourceUploadingStatesModel);
  const onCancelFetching = useCallback(() => {
    setSourceList((prev) => prev.filter((v) => v.id !== item.id));
    setUploadingStatus((prev) => {
      const status = { ...prev };
      delete status[item.id];
      return status;
    });
  }, [item, setSourceList, setUploadingStatus]);

  // [workaround] nth-child even 사용시 vsScroll 동작과 충돌로 스크롤시 색이 일시적으로 섞이는 현상이 생김
  return (
    <StyledFileListItem
      style={style}
      className={classNames(
        'sup-file-list-item',
        index % 2 && 'even',
        item.id === currentEditFile?.id && 'edit'
      )}
      key={item.id}
    >
      <section className="inner">
        <span>
          <span className="checked">
            <Checkbox
              color={item.isChecked ? White : Grey[400]}
              checked={item.isChecked}
              onChange={() => setCheckItem(item.id)}
            />
          </span>
          <EditableName item={item} changeName={changeName} />
        </span>
        <span>
          <DisplayTime
            type={type}
            id={item.id}
            currentTime={currentPlayTime}
            duration={item.duration}
            isExpand={item.isExpanded}
          />
          <IconButton
            color="transparent"
            onClick={() => setToggleItem(item.id)}
            onKeyDown={(e) => {
              if (e.code === 'Space') {
                e.preventDefault();
              }
            }}
          >
            {item.isExpanded ? <BottomArrowIcon /> : <RightArrowIcon />}
          </IconButton>
        </span>
      </section>
      {item.isExpanded && (
        <FilePlayer
          item={item}
          type={type}
          updateCurrentTime={setCurrentPlayTime}
          updateFile={updateFile}
          isPanel={true}
          isPanelActive={isPanelActive}
        />
      )}
      {item.isFetching && (
        <FileLoading text={t('Uploading')} onCancel={onCancelFetching} />
      )}
    </StyledFileListItem>
  );
};
export default SourceFileListItem;
