import { LayerPlacement } from '../../hooks/useLayerPosition/types';
import { FontSizeRatio } from '../../styles/Typography';

export const DEFAULT_SELECT_PROPS = {
  selectedLabel: 'Selected',
  optionsPlacement: 'bottom-left' as LayerPlacement,
  /**
   * 디자인상 ModelSelect options의 offset은 8px
   * {@link https://www.figma.com/file/wJ3u9P1BxtHhVH1f2Y2KC4/Voice-Toolkit?type=design&node-id=2038-20811&t=W6BZnetLubke9hY1-0}
   */
  optionsOffsetY: Math.round(8 * FontSizeRatio),
  optionsMaxHeight: '50vh',
};
