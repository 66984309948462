import classNames from 'classnames';
import { LiHTMLAttributes, PropsWithChildren } from 'react';

interface DropdownItemProps extends LiHTMLAttributes<HTMLLIElement> {
  label?: string;
  badgeLabel?: string;
  isSelected?: boolean;
}

const DropdownItem = ({
  label,
  badgeLabel,
  isSelected,
  children,
  ...props
}: PropsWithChildren<DropdownItemProps>) => {
  if (!label && !badgeLabel && children) {
    return <li className="sup-dropdown-item">{children}</li>;
  }

  return (
    <li
      className={classNames('sup-dropdown-item', { selected: isSelected })}
      {...props}
    >
      <span className="sup-dropdown-item-label">{label}</span>
      <span className="sup-dropdown-item-badge-label">{badgeLabel}</span>
    </li>
  );
};

export default DropdownItem;
