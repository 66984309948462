import { useContext } from 'react';

import { UndoManagerContext } from './UndoManager';

const useUndoManager = () => {
  const manager = useContext(UndoManagerContext);

  // Throw error if there is no enclosing UndoManagerProvider.
  if (!manager)
    throw new Error('useUndoManager must be used within a UndoManagerProvider');

  return manager;
};

export default useUndoManager;
