import { CardUIHeader } from '@/components/CardUI';
import { Input } from '@/components/FileInput';
import Record from '@/components/FileInput/Record';
import Title from '@/components/Title/Title';
import { AUDIO_FILE_ACCEPT } from '@/consts';
import { useTranslation } from 'react-i18next';

import { RECORD_CAPTIONS } from '../../../consts/cvc';

interface TargetPanelHeaderProps {
  addFiles: (files: File[]) => void;
}
const TargetPanelHeader: React.FC<TargetPanelHeaderProps> = ({ addFiles }) => {
  const { t } = useTranslation();
  return (
    <CardUIHeader>
      <Title size="lg">{t('Target Audio')}</Title>
      <section className="header-buttons">
        <Input
          caption={t('Add Files')}
          accept={AUDIO_FILE_ACCEPT}
          addFiles={addFiles}
        />
        <Record captions={RECORD_CAPTIONS} addFiles={addFiles} />
      </section>
    </CardUIHeader>
  );
};
export default TargetPanelHeader;
