import { ReactComponent as ScissorsIcon } from '@/assets/icons/ScissorsIcon.svg';
import Button from '@/components/Button/Button';
import { EditableListType } from '@/components/List/types';
import { prepareEditFileModel } from '@/stores/cvc';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import { formatSToMMSS } from '../util/formatter';

interface DisplayTimeProps {
  id: string;
  type: EditableListType;
  currentTime?: number;
  duration?: number;
  isExpand?: boolean;
  editable?: boolean;
}
const DisplayTime: React.FC<DisplayTimeProps> = ({
  id,
  type,
  currentTime,
  duration,
  isExpand,
  editable = true,
}) => {
  const setPrepareEditFile = useSetRecoilState(prepareEditFileModel);
  const { t } = useTranslation();
  return (
    <span
      className={classNames('play-time', editable && 'editable-item')}
      onClick={() => {
        setPrepareEditFile({
          id,
          type,
        });
      }}
    >
      <span className="time-zone">
        {isExpand && typeof currentTime === 'number' && (
          <span className="current-time">{formatSToMMSS(currentTime)}</span>
        )}
        <span className="duration">
          {duration ? formatSToMMSS(duration) : '-'}
        </span>
      </span>
      <Button className="edit-button" startIcon={<ScissorsIcon />}>
        {t('Edit')}
      </Button>
    </span>
  );
};
export default DisplayTime;
