import classNames from 'classnames';
import { HTMLAttributes } from 'react';

import ButtonGroup from '../Button/ButtonGroup';
import { ControlButton } from './ControlButton';
import StyledAudioControlPanel from './StyledAudioControlPanel';
import { Control } from './types';

interface AudioControlPanelProps extends HTMLAttributes<HTMLDivElement> {
  controls: Control[] | Control[][];
  className?: string;
  isControllable?: boolean;
}

const AudioControlPanel = ({
  className,
  controls,
  isControllable = false,
}: AudioControlPanelProps) => {
  return (
    <StyledAudioControlPanel
      className={classNames('sup-audio-controller', className)}
    >
      {controls.map((control, index) =>
        Array.isArray(control) ? (
          <ButtonGroup key={index} className="control-group" radius="none">
            {control.map((controlItem, index) => {
              return (
                <ControlButton
                  key={index}
                  isControllable={isControllable}
                  {...controlItem}
                />
              );
            })}
          </ButtonGroup>
        ) : (
          <ControlButton
            key={index}
            isControllable={isControllable}
            {...control}
          />
        )
      )}
    </StyledAudioControlPanel>
  );
};

export default AudioControlPanel;
