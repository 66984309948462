import { helpDescriptionModel } from '@/stores/cvc';
import { parameterSelector, parametersValueModel } from '@/stores/models';
import { useCallback } from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import { CheckboxParameter, ParameterType, SliderParameter } from './config';
import { SliderControl } from './SliderControl';
import SwitchControl from './SwitchControl';

const Parameters = () => {
  const parameters = useRecoilValue(parameterSelector);
  const setDescription = useSetRecoilState(helpDescriptionModel);
  const resetDescription = useResetRecoilState(helpDescriptionModel);
  const [parametersValue, setParametersValue] =
    useRecoilState(parametersValueModel);
  const onChangeParameters = useCallback(
    (value: { [key: string]: number | boolean }) => {
      setParametersValue((pre) => {
        return {
          ...pre,
          ...value,
        };
      });
    },
    [setParametersValue]
  );

  return (
    <section className="model-parameters">
      {parameters?.map((parameter, index) => {
        return (
          <section
            key={parameter.name}
            className="model-parameter"
            onMouseEnter={() => setDescription({ main: parameter.tooltip })}
            onMouseLeave={() => resetDescription()}
          >
            {parameter.type === ParameterType.Checkbox ? (
              <SwitchControl
                value={
                  (parametersValue[parameter.name] ??
                    parameter.defaultValue) as boolean
                }
                key={parameter.name}
                parameter={parameter as CheckboxParameter}
                onChange={onChangeParameters}
              />
            ) : (
              <SliderControl
                key={`${parameter.name}_${index}`}
                value={
                  (parametersValue[parameter.name] ??
                    parameter.defaultValue) as number
                }
                parameter={parameter as SliderParameter}
                onChange={onChangeParameters}
              />
            )}
          </section>
        );
      })}
    </section>
  );
};

export default Parameters;
