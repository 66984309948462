import { ReactNode } from 'react';

interface MessagePageProps {
  message: ReactNode;
}

const MessagePage = ({ message }: MessagePageProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div>{message}</div>
    </div>
  );
};

export default MessagePage;
