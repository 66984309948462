import styled from '@emotion/styled';

import { Black, Grey, Secondary } from '../../styles/Colors';

const StyledAudioPlayer = styled.div`
  padding: 0;
  display: flex;
  position: relative;
  .hidden {
    visibility: hidden;
  }
  .audio-player-button {
    width: 3rem;
    height: 3rem;
    svg {
      width: 3rem;
      height: 3rem;
    }
    svg * {
      fill: revert-layer;
    }
    &:hover {
      .icon-background {
        fill: ${Grey[200]};
      }
    }
  }
  .audio-player-wrapper {
    position: relative;
    box-sizing: border-box;
    border: 1px solid ${Grey[400]};
    border-left: none;
    width: 100%;
    height: 3rem;
    background-color: ${Black};
  }
  .audio-player-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .audio-player-wave {
    width: 100%;
    height: 100%;
  }
  .audio-player-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background-color: ${Secondary[200]};
  }
`;

export default StyledAudioPlayer;
