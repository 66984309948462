import CheckIcon from '@/assets/icons/MssCheckIcon.svg';
import { Grey, Primary, PureBlack, Secondary, White } from '@/styles/Colors';
import styled from '@emotion/styled';

import { FontSize, FontWeight } from '../../../styles/Typography';

const StyledMssAndSE = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  .mss-grid {
    flex: 1;
    .mss-cards {
      margin-top: 1px;
      padding: 0.5rem;
      padding-bottom: 0;
      border: 1px solid ${Grey[500]};
      height: 100%;
      background-color: ${Grey[600]};
      .sup-card-header {
        padding: 0.5rem;
        min-height: 3rem;
        .attach-button-primary {
          background-color: ${Primary[400]};
          border: 1px solid ${Primary[400]};
        }
      }
      .sup-card-content {
        padding: 0;
        flex: auto;
        flex-direction: column;
        margin-bottom: 0;
        width: 100%;
        .sup-files,
        .sup-file-empty,
        .sup-controls {
          display: flex;
          flex: auto;
          margin: 0.5rem 0.75rem 1.75rem 0.75rem;
        }
        .sup-files {
          flex: auto;
          flex-direction: column;
          background-color: ${PureBlack};
        }
        .sup-file-empty {
          background-color: ${PureBlack};
        }
        .sup-controls {
          padding: 0;
          width: calc(100% - 1.5rem);
          box-sizing: border-box;
          flex-direction: column;
          background-color: ${Grey[600]};
          justify-content: flex-start;
          gap: 0.38rem;
          .sup-control {
            box-sizing: border-box;
            padding: 0.75rem 0.85rem;
            border-radius: 0.25rem;
            position: relative;
            width: 100%;
            background-color: ${PureBlack};
            overflow: hidden;
            display: flex;
            flex-direction: column;
            .sup-card {
              padding: 0;
              background-color: ${PureBlack};
              .sup-card-header {
                padding: 0;
                min-height: unset;
              }
              .sup-card-content {
                padding: 0.75rem 0 0.375rem 0;
              }
              h4 {
                font-size: ${FontSize['Md']};
                font-weight: ${FontWeight['SemiBold']};
              }
            }
            label:has(.mss-control-switch) {
              margin-top: 0.88rem;
              align-self: flex-end;
            }
            &.disabled {
              background-color: ${Grey[800]};
              .sup-card {
                background-color: ${Grey[800]};
                h4 {
                  color: ${Grey[500]};
                  &::before {
                    background-color: ${Grey[500]};
                  }
                }
              }
              .sup-card-content {
                background-color: ${Grey[800]};
              }
            }
          }
          .mss-control-button {
            box-sizing: border-box;
            margin-top: 2.82rem;
            align-self: center;
            border: 1px solid ${Grey[50]};
            width: 17.875rem;
            height: 3.375rem;
            font-size: ${FontSize['Xl']};
            font-weight: ${FontWeight['SemiBold']};
            .sup-button-icon {
              margin-right: 1.25rem;
            }
          }
        }
      }
    }
    .sup-files {
      display: flex;
      flex: auto;
      overflow: hidden;
      position: relative;
      padding: 0;
      width: calc(100% - 1.5rem);
      font-size: ${FontSize['Md']};
      background-color: ${PureBlack};
    }
  }
  .sup-file-list-item {
    &.mss-group {
      background-color: ${Grey[500]};
      display: flex;
      justify-content: space-between;
      .mss-group-toggle {
        margin-right: 1.57rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
      &.selected {
        background-color: ${Primary[600]};
        .mss-result-check label {
          background-color: ${Primary[400]} !important;
          border-color: ${Primary[400]};
        }
      }
      .mss-result-check label {
        border: 2px solid ${White};
        border-radius: 2px;
      }
    }
    &.mss-result-list-item {
      &.selected {
        background-color: ${Primary[800]};
        &:hover {
          background-color: ${Primary[800]};
        }
        .mss-result-check label {
          color: ${White};
          background-color: ${Primary[400]} !important;
          border-color: ${Primary[400]};
        }
      }
      &:hover {
        background-color: ${PureBlack};
      }
      .checked {
        margin-left: 0.25rem;
      }
      .mss-result-check label {
        border: 2px solid ${Grey[400]};
        border-radius: 2px;
      }
      .mss-type {
        width: 4.9375rem;
        margin: 0 1rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &.vocals {
          color: #b69b3d;
        }
        &.accomp {
          color: ${Secondary[300]};
        }
        &.hifi_brunet {
          color: ${Primary[400]};
        }
      }
    }
    .mss-result-check > input:checked ~ label {
      background: url(${CheckIcon}) no-repeat !important;
    }
  }
  .dereverb-control {
    width: 15rem;
    margin-left: auto;
  }
`;

export default StyledMssAndSE;
