import { formatSToMMSSMS } from '@/util/formatter';

interface TimeDisplayProps {
  currentTime?: number;
  startTime?: number | null;
  endTime?: number | null;
}

const TimeDisplay = ({ currentTime, startTime, endTime }: TimeDisplayProps) => {
  return (
    <div className="sup-audio-time">
      <div className="sup-audio-time-current">
        <span>POSITION</span>
        <span>
          {typeof currentTime === 'number' ? formatSToMMSSMS(currentTime) : '-'}
        </span>
      </div>
      <div className="sup-audio-time-start">
        <span>START</span>
        <span>
          {typeof startTime === 'number' ? formatSToMMSSMS(startTime) : '-'}
        </span>
      </div>
      <div className="sup-audio-time-end">
        <span>END</span>
        <span>
          {typeof endTime === 'number' ? formatSToMMSSMS(endTime) : '-'}
        </span>
      </div>
      <div className="sup-audio-time-selection">
        <span>SELECTION</span>
        <span>
          {typeof startTime === 'number' && typeof endTime === 'number'
            ? formatSToMMSSMS(endTime - startTime)
            : '-'}
        </span>
      </div>
    </div>
  );
};

export default TimeDisplay;
