import { expandedPanelIndexModel, panelsModel } from '@/stores//panels';
import { useCallback } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

import { CVCPanelOrderTypes, CVC_PANEL_ORDER_MAP } from '../../../consts/cvc';

const usePanelManager = () => {
  const setPanelModel = useSetRecoilState(panelsModel);
  const togglePanel = useCallback(
    (number: CVCPanelOrderTypes) => {
      setPanelModel((prev) => ({
        ...prev,
        [number]: !prev[number],
      }));
    },
    [setPanelModel]
  );

  const [fullPanelIndex, setFullPanel] = useRecoilState(
    expandedPanelIndexModel
  );
  const exitFullPanel = useResetRecoilState(expandedPanelIndexModel);
  const toggleSelectModelPanel = useCallback(
    () => togglePanel(CVC_PANEL_ORDER_MAP.CONTROL),
    [togglePanel]
  );
  const toggleAudioEditorPanel = useCallback(
    () => togglePanel(CVC_PANEL_ORDER_MAP.AUDIO_EDITOR),
    [togglePanel]
  );
  const toggleVoiceFilePanel = useCallback(
    () => togglePanel(CVC_PANEL_ORDER_MAP.VOICE_FILES),
    [togglePanel]
  );
  const toggleResultsPanel = useCallback(
    () => togglePanel(CVC_PANEL_ORDER_MAP.RESULTS),
    [togglePanel]
  );
  return {
    toggleSelectModelPanel,
    toggleAudioEditorPanel,
    toggleVoiceFilePanel,
    toggleResultsPanel,
    setFullPanel,
    exitFullPanel,
    fullPanelIndex,
  };
};

export default usePanelManager;
