import { MssModelType } from '@/pages/mss/SeparateControl/config';

export const MSS_SOURCE_PANEL = {
  SOURCE: 0,
  CONTROL: 1,
  RESULT: 2,
};
export type MSSControlType = Exclude<MssModelType, 'custom'>;
export const MSS_CONTROL_TYPE: Record<MSSControlType, string> = {
  vocals: 'Vocal',
  accomp: 'Instrumental',
  hifi_brunet: 'De-Reverb',
};
export const MSS_DESCRIPTION =
  '음원에서 목소리와 목소리 아닌 것을 분리하고, 필요한 것을 선택하여 작업할 수 있습니다. 추가로 변화된 음원의 소음을 줄일 수 있습니다.';
