export const formatSToMMSSMS = (time: number) => {
  if (typeof time !== 'number') return '';
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  const milliseconds = Math.floor((time % 1) * 1000);

  const formattedM = minutes.toString().padStart(2, '0');
  const formattedS = seconds.toString().padStart(2, '0');
  const formattedMS = milliseconds.toString().padStart(3, '0');

  return `${formattedM}:${formattedS}:${formattedMS}`;
};

export const formatSToMMSS = (time: number) => {
  if (typeof time !== 'number') return '';
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);

  const formattedM = minutes.toString().padStart(2, '0');
  const formattedS = seconds.toString().padStart(2, '0');

  return `${formattedM}:${formattedS}`;
};

export const formatSToSSMSMS = (time: number) => {
  if (typeof time !== 'number') return '';
  const seconds = Math.floor(time % 60);
  const milliseconds = Math.floor((time % 1) * 1000);

  const formattedS = seconds.toString().padStart(2, '0');
  const formattedMS = milliseconds.toString().padStart(3, '0');

  return `${formattedS}:${formattedMS}`;
};

export const formatSToFullTime = (time: number) => {
  if (typeof time !== 'number') return '';
  const date = new Date(time);
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const hours = `0${date.getHours()}`.slice(-2);
  const minutes = `0${date.getMinutes()}`.slice(-2);
  const seconds = `0${date.getSeconds()}`.slice(-2);
  return `${year}-${month}-${day} ${hours}.${minutes}.${seconds}`;
};

// xAxis축의 시간을 표시하기 위해 사용
// MM:SS:MS 형식으로 표시
// TODO: 피드백 이후 형식 변경의 여지가 있음
export const formatXAxisTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  const milliseconds = Math.floor(((time % 1) * 1000) / 100);

  const formattedM = minutes.toString().padStart(2, '0');
  const formattedS = seconds.toString().padStart(2, '0');
  const formattedMS = milliseconds.toString().padStart(1, '0');

  return `${formattedM}:${formattedS}:${formattedMS}`;
};
