import { ReactComponent as MergeIcon } from '@/assets/icons/MergeIcon.svg';
import Checkbox from '@/components/Checkbox/Checkbox';
import { ListItemData } from '@/components/List/types';
import useMatrix from '@/pages/cvc/hooks/useMatrix';
import { generateTargetListSelector } from '@/stores/cvc';
import { Grey, White } from '@/styles/Colors';
import classNames from 'classnames';
import { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

const TargetSimpleListItem: React.FC<{
  index: number;
  style: CSSProperties;
  data: ListItemData;
}> = ({ index, style, data }) => {
  const { list, setCheckItem } = data;
  const targetItemList = useRecoilValue(generateTargetListSelector);
  const { hasCheckedItem, toggleGroupCells } = useMatrix();
  const item = useMemo(() => {
    const listItem = list[index];
    if (!listItem.isGroup) {
      return { ...list[index] };
    } else {
      const groupItems = targetItemList.filter(
        (item) => !item.isGroup && listItem.id === item.groupName
      );
      return {
        ...list[index],
        isChecked:
          !!groupItems.length && groupItems.every((item) => item.isChecked),
        isPartChecked: hasCheckedItem(listItem.id),
      };
    }
  }, [list, index, targetItemList, hasCheckedItem]);

  const { t } = useTranslation();
  const groupLabel = useMemo(() => {
    return item.name || `Group ${(item.mergeIndex as number) + 1}`;
  }, [item]);

  // [workaround] nth-child even 사용시 vsScroll 동작과 충돌로 스크롤시 색이 일시적으로 섞이는 현상이 생김
  return (
    <li
      style={style}
      className={classNames(
        'sup-file-list-item',
        index % 2 && 'even',
        item.isMergeGroup && 'simple-merge',
        item.isGroup && 'simple-group'
      )}
    >
      <section className="inner">
        <span>
          <span className="checked">
            <Checkbox
              color={item.isGroup || item.isChecked ? White : Grey[400]}
              checked={item.isChecked}
              onChange={() =>
                item.isGroup
                  ? toggleGroupCells(item.id, item.isChecked ? false : true)
                  : setCheckItem(item.id)
              }
            />
          </span>
          <span className="file-name">
            {item.isMergeGroup ? (
              <>
                <MergeIcon />
                <span>{groupLabel}</span>
              </>
            ) : item.isGroup ? (
              t('TARGET GROUP', { group: item.name })
            ) : (
              item.name
            )}
          </span>
        </span>
      </section>
    </li>
  );
};
export default TargetSimpleListItem;
