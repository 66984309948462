import { atom, selector } from 'recoil';

export const userModel = atom<any>({
  key: 'userModel',
  default: null,
});

export const permissionModel = atom<string[]>({
  key: 'permissionModel',
  default: [],
});

export const permissionSelector = selector<string[]>({
  key: 'permissionSelector',
  get: ({ get }) => {
    const permissions = get(permissionModel);
    return permissions.map((model) => new URL(model).pathname);
  },
});
