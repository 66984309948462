import React from 'react';

import useAxiosInterceptor from '../hooks/useAxiosInterceptor';

const AuthInterceptor = ({ children }: { children: React.ReactNode }) => {
  useAxiosInterceptor();
  return <>{children}</>;
};

export default AuthInterceptor;
