import List from '@/components/List/List';
import { ListItem } from '@/components/List/types';
import { FILE_LIST_HEADERS, RESULT_TOGGLE_DEFAULT_VALUE } from '@/consts';
import useVoiceFileList from '@/hooks/useVoiceFileList';
import { getPanelRatio, panelDefaultSizeModel } from '@/stores//panels';
import {
  resultFileListModel,
  resultFileListSelector,
  resultFileListToggleModel,
} from '@/stores/cvc';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { CVC_PANEL_ORDER_MAP } from '../../../consts/cvc';
import useItemHeight from '../../../hooks/useItemHeight';
import useToggleItem from '../../../hooks/useToggleItem';
import { SectionPanel } from '../../../layout/SectionPanel';
import ResultFileListItem from '../../../ListItem/ResultFileListItem';
import { RESULTS_LENGTH_FIX_SIZE, RESULTS_PANEL_MIN } from '../config';
import useIsHighlightPanel from '../hooks/useIsHighlightPanel';
import ResultListControl from './ResultListControl';

const minSize = getPanelRatio(RESULTS_PANEL_MIN);
const ResultsPanel = () => {
  const { t } = useTranslation();
  const panelDefaultWidth = useRecoilValue(panelDefaultSizeModel);
  const isHighlight = useIsHighlightPanel('Results');
  const resultList = useRecoilValue(resultFileListSelector);
  const setResultList = useSetRecoilState(resultFileListModel);
  // File list Control
  const { checkAll, setCheckItem, changeName, updateFile } = useVoiceFileList(
    resultList,
    setResultList
  );
  // toggle File List Item
  const setToggleList = useSetRecoilState(resultFileListToggleModel);
  const { toggleItem, expandAll, expandedAll } = useToggleItem(
    resultList,
    setToggleList,
    RESULT_TOGGLE_DEFAULT_VALUE
  );

  const [ratio, setRatio] = useState<number>(0);
  const [hideLength, setHideLength] = useState<boolean>(false);

  const { getItemHeight } = useItemHeight(resultList);
  const onResizePanel = useCallback(
    (ratio: number) => {
      setRatio(ratio);
      if ((ratio / 100) * document.body.clientWidth > RESULTS_LENGTH_FIX_SIZE) {
        setHideLength(false);
      } else {
        setHideLength(true);
      }
    },
    [setHideLength, setRatio]
  );

  return (
    <SectionPanel
      panelId="Results"
      title={t('RESULTS')}
      className={classNames('sup-results-panel', isHighlight && 'highlight')}
      order={CVC_PANEL_ORDER_MAP.RESULTS}
      theme="grey"
      defaultSize={panelDefaultWidth[CVC_PANEL_ORDER_MAP.RESULTS]}
      minSize={minSize}
      maxSize={panelDefaultWidth[CVC_PANEL_ORDER_MAP.RESULTS]}
      onResize={onResizePanel}
    >
      <section className="sup-files">
        <section
          className={classNames('file-content', hideLength && 'hide-length')}
        >
          <List
            control={<ResultListControl />}
            headers={
              hideLength ? FILE_LIST_HEADERS.slice(0, 1) : FILE_LIST_HEADERS
            }
            list={resultList as ListItem[]}
            checkAll={checkAll}
            expandAll={expandAll}
            expandedAll={expandedAll}
            ListItem={ResultFileListItem}
            setToggleItem={toggleItem}
            setCheckItem={setCheckItem}
            emptyMessage={t('No generated audio files yet.') as string}
            getItemSize={getItemHeight}
            changeName={changeName}
            type="Result"
            updateFile={updateFile}
            enableAllCheck={true}
            resizeDep={ratio}
          />
        </section>
      </section>
    </SectionPanel>
  );
};

export default ResultsPanel;
