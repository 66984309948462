import { ExtendFileInfo, currentPlayFileModel } from '@/stores/cvc';
import { useCallback, useContext, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import AudioPlayer from '../components/AudioPlayer/AudioPlayer';
import { EditableListType } from '../components/List/types';
import useAudioController from '../hooks/useAudioController';
import { HotkeyManagerContext } from '../providers/HotkeyManagerContextProvider';
import { fetchAudio, getAudioBuffer } from '../util/audio';

interface FilePlayerProps {
  item: ExtendFileInfo;
  type: EditableListType;
  updateCurrentTime?: (time: number) => void;
  updateFile?: (id: string, file: Partial<ExtendFileInfo>) => void;
  // Configure hotkey is working on panel or not
  isPanel?: boolean;
  // Panel is active or not
  isPanelActive?: boolean;
}

const FilePlayer = ({
  item,
  type,
  updateCurrentTime,
  updateFile,
  isPanel = false,
  isPanelActive = false,
}: FilePlayerProps) => {
  const {
    loadAudio,
    playAudio,
    pauseAudio,
    stopAudio,
    isPlaying,
    currentTime,
  } = useAudioController();
  const [currentPlayFile, setCurrentPlayFile] =
    useRecoilState(currentPlayFileModel);

  // Fetch audio buffer
  const fetchAudioBuffer = useCallback(async () => {
    if (!item.file && !item.transcodedUrl && !item.originalUrl) return;
    // 업로드한 file이 있으면 우선순위로 사용
    const target: File | string | undefined =
      item?.file || item?.transcodedUrl || item?.originalUrl;
    if (!target) return;
    const { arrayBuffer } = await fetchAudio(target);
    const audioBuffer = await getAudioBuffer(arrayBuffer);
    if (!audioBuffer) return;
    updateFile?.(item.id, {
      audioBuffer,
      duration: audioBuffer.duration,
    });
  }, [item.id, item.file, item.transcodedUrl, item.originalUrl, updateFile]);

  const handlePlay = useCallback(
    (time?: number) => {
      playAudio(time);
      setCurrentPlayFile({
        id: item.id,
        type,
      });
    },
    [playAudio, item.id, type, setCurrentPlayFile]
  );

  useEffect(() => {
    updateCurrentTime?.(currentTime);
  }, [currentTime, updateCurrentTime]);

  // Register hotkey
  const { register, unRegister } = useContext(HotkeyManagerContext);

  const handlePlayPause = useCallback(() => {
    if (isPlaying) {
      pauseAudio();
    } else {
      playAudio();
    }
  }, [isPlaying, pauseAudio, playAudio]);

  useEffect(() => {
    if (currentPlayFile?.id !== item.id) return;
    if (isPanel && !isPanelActive) return;
    register('space', handlePlayPause);
    return () => {
      unRegister('space');
    };
  }, [
    currentPlayFile,
    item.id,
    register,
    unRegister,
    handlePlayPause,
    isPanel,
    isPanelActive,
  ]);

  // 재생중일 때 다른 패널 혹은 다른 파일 선택시 재생 중지
  useEffect(() => {
    if (!isPlaying) return;
    if ((isPanel && !isPanelActive) || currentPlayFile?.id !== item.id) {
      stopAudio();
    }
  }, [currentPlayFile, item.id, stopAudio, isPlaying, isPanel, isPanelActive]);

  useEffect(() => {
    if (!item?.isExpanded || !item?.transcodedUrl || !item?.originalUrl) return;
    if (item?.audioBuffer) {
      loadAudio(item.audioBuffer);
    } else {
      // 만약에 audioBuffer가 없으면 load
      fetchAudioBuffer();
    }
  }, [
    item?.isExpanded,
    item?.transcodedUrl,
    item?.originalUrl,
    item?.audioBuffer,
    fetchAudioBuffer,
    loadAudio,
  ]);

  return (
    <section className="file-player">
      <AudioPlayer
        audioBuffer={item.audioBuffer}
        currentTime={currentTime}
        playAudio={handlePlay}
        isPlaying={isPlaying}
        pauseAudio={pauseAudio}
      />
    </section>
  );
};

export default FilePlayer;
