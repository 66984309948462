import {
  ButtonHTMLAttributes,
  CSSProperties,
  isValidElement,
  useCallback,
  useId,
  useState,
} from 'react';

import { ReactComponent as SelectDownArrowIcon } from '../../components/assets/icons/SelectDownArrowIcon.svg';
import { useLayerPosition } from '../../hooks/useLayerPosition';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import {
  Dropdown,
  DropdownHeader,
  DropdownItem,
  DropdownLayer,
  DropdownList,
} from '../Dropdown';
import { DEFAULT_SELECT_PROPS } from './const';
import { StyledSelect } from './StyledSelect';
import { SelectOption } from './types';
import { getSelectOptions } from './utils';

interface SelectProps<T extends string | number>
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'onChange'> {
  value: T;
  options: T[] | SelectOption<T>[];
  onChange?: (option: SelectOption<T>) => void;
  selectedLabel?: string;
  width?: CSSProperties['width'];
  showOptionDescription?: boolean;
  optionsPlacement?: Parameters<typeof Dropdown>[0]['placement'];
  optionsOffsetY?: Parameters<typeof Dropdown>[0]['offsetY'];
  optionsMaxHeight?: Parameters<typeof Dropdown>[0]['maxHeight'];
  optionsMinWidth?: Parameters<typeof Dropdown>[0]['minWidth'];
  optionsZIndex?: Parameters<typeof Dropdown>[0]['zIndex'];
}

const Select = <T extends string | number>({
  value,
  options,
  onChange,
  selectedLabel = DEFAULT_SELECT_PROPS.selectedLabel,
  width,
  showOptionDescription,
  optionsPlacement = DEFAULT_SELECT_PROPS.optionsPlacement,
  optionsOffsetY = DEFAULT_SELECT_PROPS.optionsOffsetY,
  optionsMaxHeight = DEFAULT_SELECT_PROPS.optionsMaxHeight,
  optionsMinWidth,
  optionsZIndex,
  ...props
}: SelectProps<T>) => {
  const selectId = useId();
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const { anchorRef, layerRef } = useLayerPosition<
    HTMLButtonElement,
    HTMLDivElement
  >({
    placement: optionsPlacement,
    offsetY: optionsOffsetY,
    isLayerVisible: isOptionsOpen,
  });
  const [hoveredOption, setHoveredOption] = useState<SelectOption<T> | null>(
    null
  );
  const selectOptions = getSelectOptions(options);
  const selectedOption = selectOptions.find((o) => o.value === value);

  const hideOptions = useCallback(() => setIsOptionsOpen(false), []);
  const toggleOptions = useCallback(
    () => setIsOptionsOpen((prev) => !prev),
    []
  );

  const handleClickItem = useCallback(
    (option: SelectOption<T>) => () => {
      onChange?.(option);
      hideOptions();
    },
    [onChange, hideOptions]
  );

  useOnClickOutside([anchorRef, layerRef], () => hideOptions?.());

  return (
    <>
      <StyledSelect
        ref={anchorRef}
        role="combobox"
        type="button"
        style={{ width }}
        aria-controls={selectId}
        aria-expanded={isOptionsOpen ? 'true' : 'false'}
        onClick={toggleOptions}
        {...props}
      >
        {selectedOption?.label}
        <SelectDownArrowIcon className="sup-select-down-arrow" />
      </StyledSelect>
      <DropdownLayer
        id={selectId}
        ref={layerRef}
        style={{
          maxHeight: optionsMaxHeight,
          width: width === '100%' ? anchorRef?.current?.clientWidth : width,
          minWidth: optionsMinWidth,
          zIndex: optionsZIndex,
        }}
        isOpen={isOptionsOpen}
      >
        {showOptionDescription && (
          <DropdownHeader>
            {hoveredOption?.description ?? selectedOption?.description}
          </DropdownHeader>
        )}
        <DropdownList>
          {selectOptions.map((option) => {
            const { value, label } = option;
            const isSelected = value === selectedOption?.value;
            return (
              <DropdownItem
                key={value}
                value={value}
                label={!isValidElement(label) ? `${label}` : undefined}
                badgeLabel={isSelected ? selectedLabel : undefined}
                onClick={handleClickItem(option)}
                onMouseOver={() => setHoveredOption(option)}
                onMouseLeave={() => setHoveredOption(null)}
                isSelected={isSelected}
              >
                {isValidElement(label) && label}
              </DropdownItem>
            );
          })}
        </DropdownList>
      </DropdownLayer>
    </>
  );
};

export default Select;
