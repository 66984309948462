import { postCvcMatrixGenerate } from '@/api';
import { ReactComponent as MatrixIcon } from '@/assets/icons/MatrixIcon.svg';
import { ReactComponent as MoveToIcon } from '@/assets/icons/MoveToIcon.svg';
import Button from '@/components/Button/Button';
import useProcessingEvents from '@/hooks/useProcessingEvents';
import { WebSocketContext } from '@/providers/WebSocketProvider';
import {
  CvCResultFileInfo,
  generateMatrixModelSelector,
  generateSourceListSelector,
  generateTargetListSelector,
  resultFileListModel,
  selectedGeneratedInterpolationMapSelector,
} from '@/stores/cvc';
import { cvcProcessingStatesModel } from '@/stores/resource';
import { Secondary } from '@/styles/Colors';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { CardUIContent, CardUIHeader } from '../../../components/CardUI';
import Title from '../../../components/Title/Title';
import {
  isInterpolationSelector,
  parametersValueSelector,
  selectedCvcTypeSelector,
} from '../../../stores/models';
import useCvcModelState from '../hooks/useCvcModelState';
import useGenerate from '../hooks/useGenerate';
import GenerateList from './GenerateList';
import GenerateMatrix from './GenerateMatrix';

type GeneratePanelMode = 'GRID' | 'LIST';
const GeneratePanel: React.FC<{ resizeDep: number }> = ({ resizeDep }) => {
  const { t } = useTranslation();
  const generateSourceList = useRecoilValue(generateSourceListSelector);
  const generateTargetList = useRecoilValue(generateTargetListSelector);
  const isInterpolation = useRecoilValue(isInterpolationSelector);
  const matrix = useRecoilValue(generateMatrixModelSelector);
  const [mode, setMode] = useState<GeneratePanelMode>('LIST');
  const { isSourceOnly } = useCvcModelState();
  const selectedGenerateSourceList = generateSourceList.filter(
    (target) => target.isChecked
  );
  const selectedGenerateTargetList = generateTargetList.filter(
    (target) => target.isChecked
  );

  const selectedGenerateInterpolationMap = useRecoilValue(
    selectedGeneratedInterpolationMapSelector
  );
  const generateFileCount = useMemo(() => {
    if (isSourceOnly) {
      return selectedGenerateSourceList.length;
    }
    if (isInterpolation) {
      if (mode === 'LIST') {
        const groupSet = new Set<string>();
        // interpolation 의 경우 check 된 item의 groupName이 1개 이상인지 체크
        for (let i = 0; i < generateTargetList.length; i++) {
          generateTargetList[i].isChecked &&
            generateTargetList[i].groupName &&
            groupSet.add(generateTargetList[i].groupName as string);
          if (groupSet.size > 1) {
            break;
          }
        }
        // group이 2개 미만일 경우 generate disable
        if (groupSet.size < 2) {
          groupSet.clear();
          return 0;
        } else {
          groupSet.clear();
          return (
            selectedGenerateSourceList.length *
            selectedGenerateInterpolationMap.GroupB.length *
            selectedGenerateInterpolationMap.GroupA.length
          );
        }
      } else {
        // matrix mode 일 경우 matrix에서 true 인 count를 찾아 반환
        return Object.values(matrix).filter((item) => item).length;
      }
    }
    // interpolation 이 아닌 경우list mode일 경우, 체크된 아이템의 합집합
    if (mode === 'LIST') {
      return (
        selectedGenerateSourceList.length * selectedGenerateTargetList.length
      );
    } else {
      // matrix mode 일 경우 matrix에서 true 인 count를 찾아 반환
      return Object.values(matrix).filter((item) => item).length;
    }
  }, [
    isSourceOnly,
    generateTargetList,
    selectedGenerateSourceList,
    selectedGenerateTargetList,
    selectedGenerateInterpolationMap,
    mode,
    matrix,
    isInterpolation,
  ]);
  const setResultFileList = useSetRecoilState(resultFileListModel);
  const [cvcProcessingStatus, setCvcProcessingStatus] = useRecoilState(
    cvcProcessingStatesModel
  );
  const { sessionId } = useContext(WebSocketContext);
  useProcessingEvents<CvCResultFileInfo>(
    setResultFileList,
    cvcProcessingStatus,
    setCvcProcessingStatus
  );

  const parameters = useRecoilValue(parametersValueSelector);
  const modelType = useRecoilValue(selectedCvcTypeSelector);
  const { generateList, updateJobIds } = useGenerate();
  const generate = useCallback(async () => {
    let jobIds: string[];
    if (mode === 'LIST') {
      jobIds = await generateList(
        selectedGenerateSourceList,
        selectedGenerateTargetList,
        selectedGenerateInterpolationMap
      );
    } else {
      jobIds = await postCvcMatrixGenerate(
        modelType,
        sessionId as string,
        matrix,
        parameters
      );
      updateJobIds(jobIds);
    }
  }, [
    matrix,
    modelType,
    selectedGenerateInterpolationMap,
    mode,
    sessionId,
    parameters,
    selectedGenerateSourceList,
    selectedGenerateTargetList,
    updateJobIds,
    generateList,
  ]);

  useEffect(() => {
    if (
      (modelType.endsWith('-interpolation') || isSourceOnly) &&
      mode !== 'LIST'
    ) {
      setMode('LIST');
    }
  }, [modelType, isSourceOnly, mode]);

  return (
    <>
      <CardUIHeader>
        <Title size="lg" color={Secondary[200]}>
          {t('Generate')}
        </Title>
        <div className="generate-buttons">
          {!isSourceOnly && !isInterpolation && (
            <Button
              startIcon={<MatrixIcon />}
              className="button-view-matrix"
              onClick={() => setMode(mode === 'GRID' ? 'LIST' : 'GRID')}
              size="lg"
            >
              {mode === 'GRID' ? t('List View') : t('Matrix View')}
            </Button>
          )}
          <Button
            startIcon={<MoveToIcon />}
            className="button-gen-files"
            onClick={generate}
            disabled={!generateFileCount}
            size="lg"
          >
            {t('Generate Files', {
              count: generateFileCount,
              n: generateFileCount > 1 ? 's' : '',
            })}
          </Button>
        </div>
      </CardUIHeader>
      <CardUIContent className="sup-files generate-grid">
        {mode === 'LIST' ? (
          <>
            <GenerateList
              dimension="row"
              title="Source to Change"
              list={generateSourceList}
              resizeDep={resizeDep}
            />
            {!isSourceOnly && (
              <GenerateList
                dimension="col"
                title="Target to Mimic"
                list={generateTargetList}
                resizeDep={resizeDep}
              />
            )}
          </>
        ) : (
          <GenerateMatrix
            generateSourceList={generateSourceList}
            generateTargetList={generateTargetList}
          />
        )}
      </CardUIContent>
    </>
  );
};
export default GeneratePanel;
