import useAudioBufferStore from '@/hooks/useAudioBufferStore';
import { LoopRange } from '@/hooks/useAudioController';
import setAudioEditSnapshot from '@/hooks/useUndoManager/setAudioEditSnapshot';
import useUndoManager from '@/hooks/useUndoManager/useUndoManager';
import { ScaleLinear } from 'd3-scale';
import { pointer, select } from 'd3-selection';
import { PointerEvent, useCallback, useEffect, useRef, useState } from 'react';

import { MIN_DRAG_WIDTH, SELECT_HANDLE_CLASS, X_AXIS_HEIGHT } from '../const';
import { DragAction, Size } from '../types';
import { normalizeWheel } from '../utils';
import LoopArea from './LoopArea';

interface GridProps {
  audioBuffer?: AudioBuffer;
  size: Size;
  xScale: ScaleLinear<number, number>;
  currentTime?: number;
  updateCurrentTime?: (time: number) => void;
  loopRange?: LoopRange | null;
  updateLoopRange?: (range: LoopRange | null, activeLoop?: boolean) => void;
  viewportRange?: [number, number];
  updateViewportPosition?: (dx: number) => void;
  updateViewportScale?: (ratio: number) => void;
  dragRange?: LoopRange | null;
  updateDragRange?: (range: LoopRange | null) => void;
  updateYScale?: (ratio: number) => void;
}
interface DragState {
  // Is dragging or not
  isDragging: boolean;
  // Drag action
  action: DragAction;
  // Drag 확대 및 축소 시 고정된 기준점
  fixedPosition?: number | null;
}

const Grid = ({
  audioBuffer,
  size,
  xScale,
  currentTime,
  updateCurrentTime,
  loopRange,
  updateLoopRange,
  viewportRange,
  updateViewportPosition,
  updateViewportScale,
  dragRange,
  updateDragRange,
  updateYScale,
}: GridProps) => {
  const wrapperRef = useRef<SVGRectElement>(null);
  const cursorRef = useRef<SVGLineElement>(null);
  const [showCursor, setShowCursor] = useState(false);
  const [dragState, setDragState] = useState<DragState>({
    isDragging: false,
    action: 'DRAW',
  });
  const [audioBufferId, setAudioBufferId] = useState<string>();
  const { push, currentSnapshot } = useUndoManager();
  const { findAudioBufferId } = useAudioBufferStore();

  const updateCursorPosition = (position: number) => {
    const cursor = cursorRef.current;
    select(cursor).attr(
      'transform',
      `translate(${position}, ${X_AXIS_HEIGHT})`
    );
  };

  // 이벤트 발생 위치에 따라 drag action을 설정
  const handlePointerDown = (e: PointerEvent<SVGGElement>) => {
    // 만약에 loop handle을 클릭했다면(className: editor-loop-handle-left, editor-loop-handle-right)
    // loop range를 업데이트 하지 않고, isDragging만 true로 변경
    const isExpandLeft =
      e.target instanceof SVGRectElement &&
      e.target.classList.contains(SELECT_HANDLE_CLASS.left);
    const isExpandRight =
      e.target instanceof SVGRectElement &&
      e.target.classList.contains(SELECT_HANDLE_CLASS.right);

    let action: DragAction;
    let fixedPosition;
    if (isExpandLeft) {
      action = 'EXPAND_LEFT';
      fixedPosition = dragRange?.endTime;
    } else if (isExpandRight) {
      action = 'EXPAND_RIGHT';
      fixedPosition = dragRange?.startTime;
    } else {
      action = 'DRAW';
    }

    setDragState({
      isDragging: true,
      action,
      fixedPosition,
    });

    if (action !== 'DRAW') return;
    const newTime = xScale.invert(pointer(e, wrapperRef.current)[0]);
    updateCurrentTime?.(newTime);
  };

  const handlePointerMove = (e: PointerEvent<SVGGElement>) => {
    const wrapper = wrapperRef.current;
    const [px] = pointer(e, wrapper);
    const [viewportMin, viewportMax] = viewportRange || [
      xScale(0),
      xScale(audioBuffer?.duration || 0),
    ];
    updateCursorPosition(px);
    setShowCursor(true);
    const { isDragging, action, fixedPosition } = dragState;
    if (!isDragging) return;

    const newTime = xScale.invert(px);
    let startTime, endTime;
    // Loop Range를 그릴 때
    if (action === 'DRAW') {
      startTime = Math.min(currentTime ?? viewportMin, newTime);
      endTime = Math.max(currentTime ?? viewportMin, newTime);
    }
    // 상단의 좌우 handle을 드래그할 때
    else {
      if (
        !dragRange ||
        typeof dragRange.startTime !== 'number' ||
        typeof dragRange.endTime !== 'number' ||
        typeof fixedPosition !== 'number'
      )
        return;

      if (action === 'EXPAND_LEFT') {
        startTime = newTime < fixedPosition ? newTime : fixedPosition;
        endTime = newTime < fixedPosition ? dragRange.endTime : newTime;
      } else if (action === 'EXPAND_RIGHT') {
        startTime = newTime < fixedPosition ? newTime : dragRange.startTime;
        endTime = newTime < fixedPosition ? fixedPosition : newTime;
      } else {
        startTime = dragRange.startTime;
        endTime = dragRange.endTime;
      }
    }
    if (startTime === endTime) return;

    // tmp: offset을 설정해서 끝점에 가까운 부분에서 0 혹은 duration으로 붙도록 함
    // TODO: 추후 마우스 이벤트 로직을 변경하면서 수정이 필요함
    const OFFSET_TIME = xScale.invert(4) - xScale.invert(0);
    startTime = Math.max(
      viewportMin,
      startTime < OFFSET_TIME ? viewportMin : startTime
    );
    endTime = Math.min(
      viewportMax,
      endTime > viewportMax - OFFSET_TIME ? viewportMax : endTime
    );
    // PointerMove 시에는 dragRange를 업데이트. (loopRange는 마우스를 떼었을 때 업데이트)
    updateDragRange?.({
      startTime,
      endTime,
    });
  };

  const updateState = () => {
    if (
      !dragRange ||
      (dragRange.startTime === loopRange?.startTime &&
        dragRange.endTime === loopRange?.endTime)
    ) {
      setDragState((prev) => ({ ...prev, isDragging: false }));
      return;
    }
    const { startTime, endTime } = dragRange;
    const dragWidth = xScale(endTime) - xScale(startTime);
    // Min width보다 작으면 loop range를 null로 변경
    if (dragWidth < MIN_DRAG_WIDTH) {
      updateDragRange?.(null);
      updateLoopRange?.(null);
    } else {
      dragState.isDragging && updateEditSnapshot();
      updateLoopRange?.(dragRange, true);
    }
    setDragState((prev) => ({ ...prev, isDragging: false }));
  };

  const handlePointerUp = () => {
    updateState();
  };

  const handlePointerLeave = (e: PointerEvent<SVGGElement>) => {
    updateState();
    setShowCursor(false);
  };

  const updateEditSnapshot = () => {
    if (!dragRange) return;
    const { startTime, endTime } = dragRange;
    if (
      startTime === currentSnapshot?.loopRange?.startTime &&
      endTime === currentSnapshot?.loopRange?.endTime
    )
      return;

    push(
      setAudioEditSnapshot({
        audioBufferId,
        loopRange: dragRange,
      })
    );
  };

  // 더블 클릭 시 loop range를 초기화
  const handleDoubleClick = () => {
    updateDragRange?.(null);
    updateLoopRange?.(null);
    setDragState((prev) => ({ ...prev, isDragging: false }));
    // TODO: Add do stack for undo
    push(
      setAudioEditSnapshot({
        audioBufferId,
        loopRange: null,
      })
    );
  };

  // Wheel Event
  const handleWheel = useCallback(
    (e: WheelEvent) => {
      // passive: false 처리를 해야 preventDefault가 동작함
      e.preventDefault();
      if (!audioBuffer || !viewportRange) return;
      const [dx, dy] = normalizeWheel(e);
      if (e.altKey || e.ctrlKey) {
        const WHEEL_SCALE_SPEEDUP = 1;
        // 가로줌에서는 두 케이스 모두 dy를 사용
        // 세로줌에서 trackpad의 경우는 dy가 0이므로 dx를 사용
        //         mouse wheel의 경우는 dy를 사용
        const d = dy ? dy : dx;
        // Wheel Speed scaling
        // dy이 0보다 작을수록 확대 속도가 빨라짐
        // dy 값이 0보다 클수록 축소 속도가 빨라짐
        const scale =
          d <= 0
            ? 1 - (WHEEL_SCALE_SPEEDUP * d) / 100
            : 1 / (1 + (WHEEL_SCALE_SPEEDUP * d) / 100);
        // Vertical Wheel
        if (e.shiftKey) {
          updateYScale?.(scale);
        }
        // Horizontal Wheel
        else {
          // Pinch의 경우 확대/축소를 반대로 적용이 필요
          updateViewportScale?.(e.ctrlKey ? 1 / scale : scale);
        }
      }
      // Drag left, right
      else {
        const [start, end] = viewportRange;
        const newCenter = xScale.invert(xScale((start + end) / 2) + dx);
        const newPosition = (newCenter / audioBuffer.duration) * 100;
        // 좌우 드래그
        updateViewportPosition?.(newPosition);
      }
    },
    [
      updateViewportPosition,
      updateViewportScale,
      viewportRange,
      audioBuffer,
      xScale,
      updateYScale,
    ]
  );

  useEffect(() => {
    // passive 옵션을 넘겨줘야해서 addEventListener로 처리
    // 이렇지 않으면 페이지 전체 스크롤이 적용되어서 waveform 부분만 확대, 축소를 구현할 수 없음
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    const rafHandleWheel = (e: WheelEvent) => {
      e.preventDefault();
      requestAnimationFrame(() => handleWheel(e));
    };

    wrapper.addEventListener('wheel', rafHandleWheel, { passive: false });

    return () => {
      wrapper.removeEventListener('wheel', rafHandleWheel);
    };
  }, [handleWheel]);

  useEffect(() => {
    if (!audioBuffer) return;
    setAudioBufferId(findAudioBufferId(audioBuffer));
  }, [audioBuffer, findAudioBufferId]);

  return (
    <g
      ref={wrapperRef}
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
      onPointerLeave={handlePointerLeave}
      onDoubleClick={handleDoubleClick}
    >
      {/* Grid Rect */}
      <rect
        width={size.width}
        height={size.height + X_AXIS_HEIGHT}
        fill="transparent"
      />
      {/* Cursor Position */}
      {showCursor && (
        <g className="editor-cursor">
          <line ref={cursorRef} x1={0} y1={0} x2={0} y2={size.height} />
        </g>
      )}
      {/* Loop Range */}
      {dragRange && (
        <LoopArea size={size} loopRange={dragRange} xScale={xScale} />
      )}
    </g>
  );
};

export default Grid;
