import styled from '@emotion/styled';

import { CommonSwitchStyles } from '../Switch/StyledSwitch';

export const StyledToggleButton = styled.div`
  ${CommonSwitchStyles.Container}

  label {
    ${CommonSwitchStyles.Label}

    cursor: pointer;

    input {
      appearance: none;
      margin: 0;
    }

    &:has(input:checked) {
      ${CommonSwitchStyles.SelectedLabel}
    }

    &:has(input:disabled) {
      cursor: not-allowed;
    }
  }
`;
