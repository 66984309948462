import Checkbox from '@/components/Checkbox/Checkbox';
import { ListItemData } from '@/components/List/types';
import {
  ExtendFileInfo,
  GroupType,
  interpolationSelector,
  targetFileListModel,
} from '@/stores/cvc';
import { White } from '@/styles/Colors';
import classNames from 'classnames';
import { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

const TargetGroupItem: React.FC<{
  index: number;
  style: CSSProperties;
  data: ListItemData;
}> = ({ index, style, data }) => {
  const { list } = data;
  const item = list[index] as ExtendFileInfo;
  // [workaround] nth-child even 사용시 vsScroll 동작과 충돌로 스크롤시 색이 일시적으로 섞이는 현상이 생김

  const checkAllGroup = useRecoilCallback(
    ({ set, snapshot }) =>
      async (groupId: GroupType) => {
        const interpolationMap = await snapshot.getPromise(
          interpolationSelector
        );
        const targetList = await snapshot.getPromise(targetFileListModel);
        // 현재 GroupB에 속하지 않은 경우만 보지만, 그룹이 늘어나면 수정필요
        const targetGroupList =
          groupId !== 'GroupA'
            ? targetList.filter((item) =>
                interpolationMap[groupId].includes(item.id)
              )
            : targetList.filter(
                (item) => !interpolationMap['GroupB'].includes(item.id)
              );
        const checkedAll = targetGroupList.every((item) => item.isChecked);
        set(targetFileListModel, (prev) => {
          const newList = [...prev];
          targetGroupList.forEach((item) => {
            const targetIndex = newList.findIndex(
              (target) => target.id === item.id
            );
            newList[targetIndex] = {
              ...item,
              isChecked: !checkedAll,
            };
          });
          return newList;
        });
      },
    [item.id]
  );

  const { t } = useTranslation();
  const groupLabel = useMemo(() => {
    return t('TARGET GROUP', { group: item.name });
  }, [item, t]);
  return (
    <li
      id={`group_${item.name}`}
      data-group={item.id}
      style={style}
      className={classNames(
        'sup-file-list-item',
        index % 2 && 'even',
        'target-group'
      )}
    >
      <section className="inner">
        <span>
          <span className="checked">
            <Checkbox
              color={White}
              checked={item.isChecked}
              onChange={() => checkAllGroup(item.id as GroupType)}
            />
          </span>
          <span className="group-name">{groupLabel}</span>
        </span>
      </section>
    </li>
  );
};
export default TargetGroupItem;
