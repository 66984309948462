import styled from '@emotion/styled';

import { Black, Grey } from '../styles/Colors';
import { FontSize, FontWeight } from '../styles/Typography';

const StyledFileListItem = styled.li`
  .file-loading {
    background-color: ${Grey[700]};
  }
  .file-loading,
  .loading-status {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .fetching {
      position: absolute;
      display: flex;
      justify-content: space-around;
      gap: 1.25rem;
      > span {
        height: 0.63rem;
        border-radius: 1.88rem;
        background-color: ${Grey[600]};
      }
      .narrow {
        width: 4.85rem;
      }
      .wide {
        position: relative;
        width: 15.5rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        .loading-text {
          text-align: left;
          color: ${Grey[200]};
        }
      }
    }
    .cancel-button {
      width: 5rem;
      position: absolute;
      right: 0;
    }
    .btn-cancel {
      padding: 0 0.75rem;
      border: 1px solid ${Grey[50]};
      border-radius: 12.5rem;
      background-color: transparent;
      background-color: ${Black};
      font-size: ${FontSize['Md']};
      font-weight: ${FontWeight['SemiBold']};
    }
  }
  .file-player {
    padding: 0 3rem 1rem;
  }
`;
export default StyledFileListItem;
