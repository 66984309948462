import styled from '@emotion/styled';

import { Black, Grey } from '../../styles/Colors';
import { ellipsis } from '../../styles/mixins';
import { FontSize, FontWeight } from '../../styles/Typography';

export const StyledSelect = styled.button`
  ${ellipsis()}

  height: 3rem;
  border: 0.37rem solid ${Black};
  border-radius: 2.5rem;
  box-sizing: border-box;
  padding: 0.06rem 2.69rem 0.13rem;

  font-size: ${FontSize['Lg']};
  font-weight: ${FontWeight['SemiBold']};
  line-height: 150%;

  background: ${Grey[400]};
  box-shadow: 0px 0px 16px 0px #000 inset;
  color: ${Grey[50]};
  text-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.24);

  cursor: pointer;
  position: relative;

  &:disabled {
    cursor: not-allowed;
  }

  svg.sup-select-down-arrow {
    position: absolute;
    right: 0.96rem;
    top: 50%;
    width: 1.65rem;
    height: 1.14rem;
    transform: translateY(-50%);
  }
`;
