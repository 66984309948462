import { EXPANDED_ITEM_SIZE, NARROW_ITEM_SIZE } from '@/pages/cvc/config';
import { ExtendFileInfo } from '@/stores/cvc';
import { useCallback } from 'react';

const useItemHeight = <E extends ExtendFileInfo>(
  list: E[],
  noExpand?: boolean
) => {
  const getItemHeight = useCallback(
    (index: number) => {
      // [workaround] react-window getItemSize must return number
      return noExpand || !list[index].isExpanded
        ? NARROW_ITEM_SIZE
        : EXPANDED_ITEM_SIZE;
    },
    [list, noExpand]
  );
  return { getItemHeight };
};
export default useItemHeight;
