import { ReactComponent as MergeIcon } from '@/assets/icons/MergeIcon.svg';
import { ReactComponent as BottomArrowIcon } from '@/components/assets/icons/BottomArrowIcon.svg';
import { ReactComponent as RightArrowIcon } from '@/components/assets/icons/RightArrowIcon2.svg';
import IconButton from '@/components/Button/IconButton';
import Checkbox from '@/components/Checkbox/Checkbox';
import { ListItemData } from '@/components/List/types';
import { DRAG_ITEM_KEY } from '@/pages/cvc/config';
import { CvcTargetFileInfo, mergedTargetFileMapModel } from '@/stores/cvc';
import { isInterpolationSelector } from '@/stores/models';
import { Grey, White } from '@/styles/Colors';
import classNames from 'classnames';
import { CSSProperties, useRef } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import EditableName from './EditableName';

const MergeGroupItem: React.FC<{
  index: number;
  style: CSSProperties;
  data: ListItemData;
}> = ({ index, style, data }) => {
  const { list, setCheckItem } = data;
  const item = list[index] as CvcTargetFileInfo;

  const ref = useRef<HTMLLIElement>(null);
  const isInterpolation = useRecoilValue(isInterpolationSelector);

  // [workaround] nth-child even 사용시 vsScroll 동작과 충돌로 스크롤시 색이 일시적으로 섞이는 현상이 생김
  const setToggleItem = useRecoilCallback(
    ({ set }) =>
      () => {
        set(mergedTargetFileMapModel, (prev) => {
          const newMap = {
            ...prev,
            [item.id]: {
              ...prev[item.id],
              isExpanded: !prev[item.id].isExpanded,
            },
          };
          return newMap;
        });
      },
    [item]
  );
  const setGroupName = useRecoilCallback(
    ({ set }) =>
      (id: string, name: string) => {
        set(mergedTargetFileMapModel, (prev) => {
          const newMap = {
            ...prev,
            [id]: {
              ...prev[id],
              name,
            },
          };
          return newMap;
        });
      }
  );

  return (
    <li
      style={style}
      className={classNames(
        'sup-file-list-item',
        index % 2 && 'even',
        'merge-group'
      )}
      ref={ref}
      draggable={isInterpolation}
      onDragStart={(e) => {
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.dropEffect = 'none';
        e.dataTransfer?.setData(
          DRAG_ITEM_KEY,
          JSON.stringify({
            id: item.id,
            groupName: item.groupName || 'GroupA',
          })
        );
        e.dataTransfer?.setDragImage(ref.current as Element, 0, 0);
      }}
    >
      <section className="inner">
        <span>
          <span className="checked">
            <Checkbox
              color={item.isChecked ? White : Grey[400]}
              checked={item.isChecked}
              onChange={() => setCheckItem(item.id)}
            />
          </span>
          <span className="merge-name">
            <MergeIcon />
            <EditableName changeName={setGroupName} item={item} />
          </span>
        </span>
        <span>
          <IconButton color="transparent" onClick={() => setToggleItem()}>
            {item.isExpanded ? <BottomArrowIcon /> : <RightArrowIcon />}
          </IconButton>
        </span>
      </section>
    </li>
  );
};
export default MergeGroupItem;
