import {
  Black,
  Grey,
  PastelBlue,
  Primary,
  Secondary,
  White,
} from '@/styles/Colors';
import { FontFamily, FontSize, FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

const StyledAudioEditor = styled.div`
  box-sizing: border-box;
  padding: 1rem 1.75rem 0 1.75rem;
  width: 100%;
  height: 100%;
  background-color: ${PastelBlue};
  box-shadow: inset 0px 0px 12px rgba(0, 0, 0, 0.48);
  position: relative;
  .sup-audio-header {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sup-audio-time {
    display: flex;
    div[class*='sup-audio-time-'] {
      border: 1px solid ${Grey[800]};
      display: flex;
      align-items: center;
      height: calc(${FontSize['Lg']} * 1.5);
      box-sizing: border-box;
      span {
        padding: 0 0.36rem;
        line-height: 150%;
        font-weight: ${FontWeight['SemiBold']};
        font-size: ${FontSize['Lg']};
        text-align: center;
        /* 숫자 너비를 일정하게 변경시키기 위한 css */
        font-feature-settings: tnum;
        font-variant-numeric: tabular-nums;
      }
      span:first-of-type {
        background-color: ${Grey[800]};
        color: ${PastelBlue};
      }
      span:last-of-type {
        min-width: 3.14rem;
        color: ${Grey[800]};
      }
      &:first-of-type {
        span:last-of-type {
          color: ${Grey[50]};
        }
      }
    }
  }
  .sup-audio-toolbar {
    padding: 0 0.19rem;
    background: ${Grey[800]};
    .sup-button-group:last-of-type {
      margin-left: 0.38rem;
    }
    button {
      margin: 0 0.31rem;
      width: calc(${FontSize['Lg']} * 1.5);
      height: calc(${FontSize['Lg']} * 1.5);
      color: ${PastelBlue};
      &:hover {
        color: ${Primary[150]};
      }
      &:disabled {
        color: ${Grey[500]};
      }
    }
  }
  .sup-audio-editor {
    min-height: 8.94rem;
    /* margin-top, header height */
    height: calc(100% - 1rem - 1.25rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    & div {
      box-sizing: border-box;
    }
    .editor-loading {
      section {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .blue {
          box-sizing: border-box;
          background-color: ${PastelBlue};
          border: 1px solid ${Grey[800]};
        }
        .grey {
          background-color: ${Grey[800]};
        }
      }
    }
    .editor-header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1.5rem;
      background: ${Grey[800]};
    }
    .editor-body {
      margin-bottom: 0;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid ${Grey[800]};
      .editor-xaxis {
        rect {
          fill: ${Grey[800]};
        }
        text {
          fill: ${PastelBlue};
          font-size: ${FontSize['Sm']};
          font-family: ${FontFamily['Inter']};
          user-select: none;
          line-height: 150%;
          text-anchor: middle;
        }
        line {
          stroke: ${Primary[150]};
          stroke-width: 1;
          opacity: 0.1;
        }
      }
      .editor-cursor {
        stroke: ${Secondary[50]};
        stroke-width: 1;
      }
      .editor-indicator {
        line {
          stroke: ${Secondary[200]};
          stroke-width: 1;
        }
      }
      .editor-wave {
        .wave-path {
          stroke-width: 1;
        }
        .wave-zero {
          stroke: ${Primary[150]};
          stroke-width: 1;
          opacity: 0.1;
        }
        .wave-clip-path.hide {
          display: none;
        }
      }
      .editor-loop {
        &-dimmed {
          fill: ${Primary[400]};
          fill-opacity: 0.3;
        }
        &-handle-start,
        &-handle-end {
          fill: ${White};
          cursor: ew-resize;
        }
        line {
          stroke: ${White};
        }
      }
    }
    .editor-footer {
      padding: 0.31rem 0 0.56rem 0;
      display: flex;
      width: 100%;
      min-height: 2rem;
      justify-content: flex-end;
      align-items: center;
      .editor-scroll {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 0.62rem;
      }
      .editor-duration {
        margin-right: 0.75rem;
        margin-left: 0.75rem;
        width: 3.125rem;
        color: ${Black};
        text-align: right;
        font-size: ${FontSize['Md']};
        font-weight: ${FontWeight['SemiBold']};
        align-self: center;
      }
      .editor-zoom {
        gap: 0.37rem;
        button {
          background: none;
          border-color: transparent;
          width: 1.125rem;
          height: 1.125rem;
        }
        svg * {
          /* reset currentColor */
          fill: revert-layer;
        }
      }
      .editor-zoom-vertical {
        position: absolute;
        bottom: 1.96rem;
        right: -1.44rem;
        flex-direction: column;
      }
    }
    .editor-empty {
      p {
        margin-top: 1.5rem;
        text-align: center;
        color: ${Grey[800]};
        font-size: ${FontSize['Xl']};
      }
    }
  }
`;

export default StyledAudioEditor;
