import styled from '@emotion/styled';

import { Grey } from '../../../styles/Colors';
import { FontSize } from '../../../styles/Typography';
import { FileNameDisplayProps } from './FileNameDisplay';

const StyledFileNameDisplay = styled.p<FileNameDisplayProps>`
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  min-width: 0;
  font-size: ${FontSize['Sm']};
  line-height: 150%;
  color: ${Grey[500]};
  border: 1px solid ${Grey[500]};
  user-select: none;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  /* TODO: 최대 사이즈 확인 필요 */
  max-width: ${({ maxWidth }) => maxWidth || '15.625rem'};
  display: inline-flex;
  span:first-of-type {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  span:last-of-type {
    flex-shrink: 0;
  }
  &.active {
    color: ${Grey[50]};
    border: 1px solid ${Grey[50]};
    user-select: unset;
  }
`;

export default StyledFileNameDisplay;
