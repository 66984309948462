// tmp component for needs approval
import styled from '@emotion/styled';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button/Button';
import { Primary } from '../../styles/Colors';

const StyledWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 1rem;
  p {
    font-size: 1.25rem;
  }
  button {
    align-self: center;
    font-size: 1.2rem;
    width: 8rem;
  }
`;

const NeedsApproval: FC = () => {
  const { logout } = useSUPAuth();
  const { t } = useTranslation();
  return (
    <StyledWrapper>
      <h1>관리자의 승인이 필요합니다.</h1>
      <p>
        하단의 LOG OUT 버튼 클릭 후, 재로그인 시에도 현재 화면이 계속 뜬다면
        #project-voice-toolkit 채널에 문의해주세요.
      </p>
      <Button
        className="logout"
        color={Primary[400]}
        size="lg"
        onClick={() =>
          logout({ logoutParams: { returnTo: window.location.origin } })
        }
      >
        {t('LOG OUT')}
      </Button>
    </StyledWrapper>
  );
};

export default NeedsApproval;
