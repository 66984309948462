import { atom } from 'recoil';

export type FETCH_STATUS =
  | 'READY'
  | 'PENDING'
  | 'PROCESSING'
  | 'COMPLETE'
  | 'FAILED';
export type ResourceStatus = Record<string, FETCH_STATUS>;
export const targetUploadingStatesModel = atom<ResourceStatus>({
  key: 'cvc/targetUploadingStatesModel',
  default: {},
});
export const mssUploadingStatesModel = atom<ResourceStatus>({
  key: 'cvc/smsUploadingStatesModel',
  default: {},
});
export const sourceUploadingStatesModel = atom<ResourceStatus>({
  key: 'cvc/sourceUploadingStatesModel',
  default: {},
});
export const mssProcessingStatesModel = atom<ResourceStatus>({
  key: 'cvc/mssProcessingStatesModel',
  default: {},
});
export const cvcProcessingStatesModel = atom<ResourceStatus>({
  key: 'cvc/cvcProcessingStatesModel',
  default: {},
});
export const resultUploadingStatesModel = atom<ResourceStatus>({
  key: 'cvc/resultUploadingStatesModel',
  default: {},
});
