import { useCallback, useEffect, useRef, useState } from 'react';
import { VariableSizeList } from 'react-window';

import { ListItem } from '../types';

const useVirtualScroll = (list: ListItem[], resizeDep?: number) => {
  const ref = useRef<HTMLElement>(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const resize = useCallback(() => {
    const { height, width } =
      ref.current?.parentElement?.getBoundingClientRect() || {
        width: 0,
        height: 0,
      };
    setHeight(height);
    setWidth(width);
  }, [setHeight, setWidth, ref]);

  const parentRect = ref.current?.parentElement?.getBoundingClientRect();
  useEffect(() => {
    resize();
  }, [resize, list, parentRect, resizeDep]);

  const listRef = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [list, listRef]);

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  return { height, width, ref, listRef };
};
export default useVirtualScroll;
