import { CardUI, CardUIHeader } from '@/components/CardUI';
import Switch from '@/components/Switch/Switch';
import Title from '@/components/Title/Title';
import { useCallback } from 'react';

import { CheckboxParameter } from './config';

interface SwitchControlProps {
  value: boolean;
  parameter: CheckboxParameter;
  onChange: (value: { [key: string]: number | boolean }) => void;
}
export const SwitchControl: React.FC<SwitchControlProps> = ({
  parameter,
  value,
  onChange,
}) => {
  const handleChange = useCallback(
    () => onChange({ [parameter.name]: !value }),
    [onChange, parameter, value]
  );

  return (
    <CardUI style={{ borderRadius: '0.25rem' }}>
      <CardUIHeader padding="0">
        <Title size="md" tag="h4">
          {parameter.label}
        </Title>
        <Switch checked={value} onChange={handleChange} />
      </CardUIHeader>
    </CardUI>
  );
};
export default SwitchControl;
