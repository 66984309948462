import List from '@/components/List/List';
import { ListItem } from '@/components/List/types';
import { FILE_LIST_HEADERS } from '@/consts';
import useToggleItem from '@/hooks/useToggleItem';
import useVoiceFileList from '@/hooks/useVoiceFileList';
import MSSResultListItem from '@/ListItem/MssResultListItem';
import {
  MssExtendFileInfo,
  mssResultFileGroupBySourceModel,
  mssResultFileListModel,
  mssResultFileListSelector,
  mssResultFileToggleModel,
} from '@/stores/mss';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { EXPANDED_ITEM_SIZE, NARROW_ITEM_SIZE } from '../cvc/config';
import MssResultListControl from './MssResultListControl';

interface MSSResultListProps {
  resizeDep: number;
}
const MSSResultList: React.FC<MSSResultListProps> = ({ resizeDep }) => {
  const setResultList = useSetRecoilState(mssResultFileListModel);
  const resultList = useRecoilValue(mssResultFileListSelector);
  const result = useRecoilValue(mssResultFileListModel);
  const { checkAll, updateFile, changeName, setCheckItem } =
    useVoiceFileList<MssExtendFileInfo>(resultList, setResultList);
  const [toggleList, setToggleList] = useRecoilState(mssResultFileToggleModel);
  const { toggleItem } = useToggleItem(resultList, setToggleList);
  const groupList = useRecoilValue(mssResultFileGroupBySourceModel);
  const expandedAllItem = useMemo(() => {
    return result.every((item) => toggleList[item.id]);
  }, [result, toggleList]);
  const expandedAllGroup = useMemo(() => {
    return groupList.every((item) => item.isExpanded);
  }, [groupList]);
  const expandedAll = useMemo(
    () => expandedAllItem && expandedAllGroup,
    [expandedAllItem, expandedAllGroup]
  );
  const expandAll = useRecoilCallback(
    ({ set }) =>
      () => {
        set(mssResultFileGroupBySourceModel, (pre) => {
          return pre.map((group) => {
            return {
              ...group,
              isExpanded: !expandedAll,
            };
          });
        });
        set(mssResultFileToggleModel, (pre) => {
          let newToggleMap = { ...pre };
          result.forEach((item) => {
            newToggleMap[item.id] = !expandedAll;
          });
          return newToggleMap;
        });
      },
    [expandedAll, resultList]
  );

  const getMSSResultItemHeight = useCallback(
    (index: number) => {
      // [workaround] react-window getItemSize must return number
      const item = resultList[index];
      return !item.isExpanded || item?.isGroup
        ? NARROW_ITEM_SIZE
        : EXPANDED_ITEM_SIZE;
    },
    [resultList]
  );
  const { t } = useTranslation();
  return (
    <section className="sup-files">
      <List
        control={<MssResultListControl />}
        enableAllCheck={true}
        headers={FILE_LIST_HEADERS}
        list={resultList as ListItem[]}
        checkAll={checkAll}
        expandAll={expandAll}
        expandedAll={expandedAll}
        ListItem={MSSResultListItem}
        setToggleItem={toggleItem}
        setCheckItem={setCheckItem}
        getItemSize={getMSSResultItemHeight}
        emptyMessage={t('No imported audio files yet.') as string}
        changeName={changeName}
        type="Source"
        updateFile={updateFile}
        resizeDep={resizeDep}
      />
    </section>
  );
};

export default MSSResultList;
