import { ExtendFileInfo, FileItemToggleModel } from '@/stores/cvc';
import { useCallback, useMemo } from 'react';
import { SetterOrUpdater } from 'recoil';

const useToggleItem = (
  list: ExtendFileInfo[],
  setToggleList: SetterOrUpdater<FileItemToggleModel>,
  defaultExpand?: boolean
) => {
  // toggle one item
  const toggleItem = useCallback(
    (id: string) => {
      setToggleList((prev) => {
        // 토글이벤트가 생긴 시점에 이미 generate된 상태이기에 기본 값을 설정함
        const current = prev[id] ?? defaultExpand;
        return { ...prev, [id]: !current };
      });
    },
    [setToggleList, defaultExpand]
  );
  // flag all items expanded or not
  const expandedAll = useMemo(() => {
    return list.every((item) => item.isExpanded);
  }, [list]);

  // toggle all item
  const expandAll = useCallback(() => {
    setToggleList((prev) => {
      const newToggles = { ...prev };
      const current = !expandedAll;
      return list.reduce((acc, item) => {
        acc[item.id] = current;
        return acc;
      }, newToggles);
    });
  }, [list, expandedAll, setToggleList]);
  return {
    toggleItem,
    expandAll,
    expandedAll,
  };
};
export default useToggleItem;
