import styled from '@emotion/styled';

import { Black, Grey, Primary } from '../../styles/Colors';
import { FontSize, FontWeight } from '../../styles/Typography';

export const StyledHeader = styled.header`
  background-color: ${Black};
  height: 3.375rem;
  border-bottom: 1px solid ${Grey[500]};
  ul {
    display: flex;
    justify-content: center;
    height: 3.375rem;

    li {
      line-height: normal;
      font-size: ${FontSize['2Xl']};
      align-items: center;
      display: inline-flex;
      margin-right: 3.125rem;
      font-weight: ${FontWeight['SemiBold']};

      a {
        text-decoration: none;
        color: ${Grey[200]};
      }
    }

    li:last-child {
      margin-right: 0;
    }

    li.selected {
      border-bottom: 1px solid ${Primary[300]};

      a {
        color: ${Primary[300]};
      }
    }
  }
  .user-info {
    position: absolute;
    right: 1.75rem;
    top: 0.75rem;
    display: flex;
    align-items: center;
    .initial {
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      background-color: ${Primary[400]};
      text-align: center;
      line-height: 150%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .btn-dropdown {
      margin-left: 0.5rem;
      svg {
        width: 0.75rem;
        height: 0.375rem;
      }
    }
  }
`;

export const StyledUserLayer = styled.div`
  padding: 1rem 0.75rem 0.5rem;
  color: ${Grey[50]};
  z-index: 100;
  .info {
    text-align: center;
    white-space: nowrap;
    display: block;
    margin-bottom: 1rem;
    line-height: 100%;
  }
  .logout {
    width: 100%;
    min-width: 9.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: bold;
  }
`;
