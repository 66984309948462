import CheckIcon from '@/components/assets/icons/CheckIcon.svg';
import MinusIcon from '@/components/assets/icons/MinusIcon.svg';
import { Grey, White } from '@/styles/Colors';
import styled from '@emotion/styled';

import { FontSize, FontWeight } from '../../styles/Typography';

const StyledCheckbox = styled.span`
  display: inline-flex;
  height: 1.25rem;
  align-items: center;
  gap: 0.5rem;
  .screen-out {
    visibility: hidden;
    position: absolute;
    width: 0;
    height: 0;
  }
  > input {
    visibility: hidden;
    position: absolute;
  }
  > input ~ label {
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 2px;
    border: 2px solid ${(props) => props.color || White};
  }
  > input:checked ~ label {
    background: url(${CheckIcon}) no-repeat;
    background-color: ${(props) => props.color || White};
  }
  > input:disabled {
    border-color: ${Grey[200]};
  }
  > input:disabled ~ label {
    background: url(${MinusIcon}) no-repeat;
    background-color: ${(props) => props.color || Grey[200]};
    cursor: default;
  }
  .checkbox-caption {
    font-size: ${FontSize['Md']};
    font-weight: ${FontWeight['SemiBold']};
    line-height: 150%;
  }
`;
export default StyledCheckbox;
