import styled from '@emotion/styled';

import { Grey, Primary } from '../../styles/Colors';

const StyledAudioControlPanel = styled.div`
  box-sizing: border-box;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${Grey[500]};
  border-radius: 0.25rem;
  div {
    margin: 0;
    &:last-of-type {
      margin-left: 0.87rem;
    }
  }
  /* 버튼 status, active 상태 확인 필요 */
  button {
    width: 2.625rem;
    height: 1.875rem;
    color: ${Grey[50]};
    &:last-of-type {
      margin-right: 0;
    }
    &[disabled] {
      color: ${Grey[500]};
      &:hover {
        background: none;
      }
    }
    &:hover {
      background: ${Grey[600]};
    }
    &:has(.sup-icon-play) {
      color: ${Primary[400]};
      &.active {
        background: ${Primary[700]};
      }
      &[disabled] {
        color: ${Grey[500]};
      }
    }
    &.active {
      background: ${Grey[300]};
      &[disabled] {
        background: none;
      }
    }
  }
`;

export default StyledAudioControlPanel;
