import { LoopRange } from '@/hooks/useAudioController';
import useUndoManager from '@/hooks/useUndoManager/useUndoManager';
import { formatSToMMSS } from '@/util/formatter';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { HotkeyManagerContext } from '../../providers/HotkeyManagerContextProvider';
import Loading from '../Loading/Loading';
import Scrollbar from '../Scrollbar/Scrollbar';
import {
  DEFAULT_Y_SCALE_INFO_MAX,
  DEFAULT_Y_SCALE_INFO_MIN,
  MIN_WAVE_SAMPLES,
  OFFSET_SIZE,
  VIEWPORT_PADDING,
  X_AXIS_HEIGHT,
} from './const';
import Axis from './Editor/Axis';
import Grid from './Editor/Grid';
import Indicator from './Editor/Indicator';
import Wave from './Editor/Wave';
import HorizontalZoom from './HorizontalZoom';
import { Size } from './types';
import VerticalZoom from './VerticalZoom';

interface EditorProps {
  containerSize?: Size;
  audioBuffer?: AudioBuffer;
  currentTime?: number;
  updateCurrentTime?: (time: number) => void;
  loopRange?: LoopRange | null;
  updateLoopRange?: (range: LoopRange | null) => void;
  convertToMono?: boolean;
  dragRange?: LoopRange | null;
  updateDragRange?: (range: LoopRange | null) => void;
  isHotkeyEnabled?: boolean;
  isLoading?: boolean;
}

const Editor = ({
  containerSize,
  audioBuffer,
  currentTime,
  updateCurrentTime,
  loopRange,
  updateLoopRange,
  dragRange,
  updateDragRange,
  isHotkeyEnabled = false,
  isLoading,
}: EditorProps) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<SVGSVGElement>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [viewportRange, setViewportRange] = useState<[number, number]>();
  const [channelDataList, setChannelDataList] =
    useState<[Float32Array, Float32Array?]>();
  const [scrollInfo, setScrollInfo] = useState({
    position: 0,
    contentSize: 0,
    viewportSize: 0,
  });
  const [yScaleInfo, setYScaleInfo] = useState<[number, number]>(
    DEFAULT_Y_SCALE_INFO_MAX
  );
  const { register, unRegister } = useContext(HotkeyManagerContext);

  const { isUndoable } = useUndoManager();

  const viewportXScale = useMemo(() => {
    const [start, end] = viewportRange || [0, audioBuffer?.duration || 0];
    return scaleLinear().domain([start, end]).range([0, size.width]);
  }, [audioBuffer, size.width, viewportRange]);

  // channelDataList가 변경되면 size.height의 변경이 필요함
  const newSize = useMemo(() => {
    return {
      width: size.width,
      height: !!channelDataList?.[1] ? size.height / 2 : size.height,
    };
  }, [channelDataList, size]);

  const updateWrapperSize = useCallback(
    (wrapperSize?: Size) => {
      const container = containerRef.current;
      const width = wrapperSize?.width || container?.clientWidth || 0;
      const height = wrapperSize?.height || container?.clientHeight || 0;
      if (width === size.width && height === size.height) return;

      const wrapper = wrapperRef.current;
      const { top, left, bottom, right } = VIEWPORT_PADDING;

      setSize({ width, height: height - X_AXIS_HEIGHT });

      select(wrapper)
        .attr('width', width + left + right)
        .attr('height', height + top + bottom)
        .attr(
          'viewBox',
          `${-left} ${-bottom} ${width + left + right} ${height - top - bottom}`
        )
        .attr('transform', `translate(${-right}, ${-top})`);
    },
    [size.width, size.height]
  );

  const updateChannelData = useCallback(() => {
    if (!audioBuffer || !viewportRange) return;
    // zoom range에 맞게 채널 데이터를 잘라서 가져옴
    const [start, end] = viewportRange;
    const startIdx = Math.floor(start * audioBuffer.sampleRate);
    const endIdx = Math.floor(end * audioBuffer.sampleRate);

    const channelData = audioBuffer.getChannelData(0).slice(startIdx, endIdx);
    const channelData2 =
      audioBuffer.numberOfChannels > 1
        ? audioBuffer.getChannelData(1).slice(startIdx, endIdx)
        : undefined;
    !viewportRange && setViewportRange([start, end]);
    setChannelDataList([channelData, channelData2]);
  }, [audioBuffer, viewportRange]);

  const updateViewportScale = (ratio: number) => {
    if (!audioBuffer || !viewportRange) return;
    const [start, end] = viewportRange;

    // If viewport range is equal to audio buffer duration and zoom out, do not zoom
    if (start === 0 && end === audioBuffer.duration && ratio > 1) return;

    // If ChannelData is less than 2, do not zoom
    if (
      channelDataList &&
      channelDataList[0].length <= MIN_WAVE_SAMPLES &&
      ratio < 1
    )
      return;

    // If current time is set, zoom to current time
    const mid = currentTime ? currentTime : (end - start) / 2 + start;
    const { duration } = audioBuffer;

    const startPos = viewportXScale(start);
    const endPos = viewportXScale(end);

    const newWidth = (endPos - startPos) * ratio;

    let newStart = viewportXScale.invert(viewportXScale(mid) - newWidth / 2);
    let newEnd = viewportXScale.invert(viewportXScale(mid) + newWidth / 2);
    if (newStart < 0) {
      newStart = 0;
      newEnd = Math.min(
        viewportXScale.invert(viewportXScale(0) + newWidth),
        duration
      );
    } else if (newEnd > duration) {
      newStart = Math.max(
        viewportXScale.invert(viewportXScale(duration) - newWidth),
        0
      );
      newEnd = duration;
    }
    if (start === newStart && end === newEnd) return;
    setViewportRange?.([newStart, newEnd]);
  };

  const updateViewportPosition = (p: number) => {
    if (!audioBuffer || !viewportRange) return;
    const [start, end] = viewportRange;

    // If viewport range is equal to audio buffer duration, do not move viewport
    if (start === 0 && end === audioBuffer.duration) return;

    const center = (p * audioBuffer.duration) / 100;
    const centerPx = viewportXScale(center);
    let newStart = viewportXScale.invert(centerPx - size.width / 2);
    let newEnd = viewportXScale.invert(centerPx + size.width / 2);

    // 시작점이 0보다 작은 경우 시작점을 0에 맞춰서 끝점을 조정
    if (newStart < 0) {
      newStart = 0;
      newEnd = viewportXScale.invert(viewportXScale(end - start));
    }
    // 끝점이 오디오 버퍼의 끝보다 큰 경우 끝점을 오디오 버퍼의 끝에 맞춰서 시작점을 조정
    else if (newEnd > audioBuffer.duration) {
      newStart = viewportXScale.invert(
        viewportXScale(newStart - newEnd + audioBuffer.duration)
      );
      newEnd = audioBuffer.duration;
    }
    if (start === newStart && end === newEnd) return;
    setViewportRange?.([newStart, newEnd]);
  };

  // Update wave vertical scale
  const updateYScale = useCallback(
    (ratio: number) => {
      const [min, max] = yScaleInfo;
      const newMin = parseFloat((min * ratio).toFixed(2));
      const newMax = parseFloat((max * ratio).toFixed(2));
      if (newMin >= newMax) return;
      if (
        newMin < DEFAULT_Y_SCALE_INFO_MAX[0] ||
        newMax > DEFAULT_Y_SCALE_INFO_MAX[1]
      ) {
        setYScaleInfo(DEFAULT_Y_SCALE_INFO_MAX);
        return;
      }
      if (
        newMin > DEFAULT_Y_SCALE_INFO_MIN[0] ||
        newMax < DEFAULT_Y_SCALE_INFO_MIN[1]
      ) {
        setYScaleInfo(DEFAULT_Y_SCALE_INFO_MIN);
        return;
      }
      setYScaleInfo([newMin, newMax]);
    },
    [yScaleInfo]
  );

  // Reset viewport range when audioBuffer is changed
  useEffect(() => {
    if (!audioBuffer) return;
    setViewportRange((prev) => {
      // Undoable이 없는 경우는 파일 변경을 의미하므로 [0, audioBuffer.duration]로 설정
      if (!prev || !isUndoable) return [0, audioBuffer.duration];
      const [start, end] = prev;
      // 만약 prev값이 audioBuffer의 duration보다 크면 [0, audioBuffer.duration]로 설정
      // trim 후 undo 시 audioBuffer의 duration이 trim 이전의 duration보다 작아지는 경우가 있음
      if (start < 0 || end > audioBuffer.duration) {
        return [0, audioBuffer.duration];
      }
      // loopRange가 viewport 내에 없으면 [0, audioBuffer.duration]로 설정
      if (
        loopRange &&
        (start > loopRange.startTime || end < loopRange.endTime)
      ) {
        return [0, audioBuffer.duration];
      }
      return [start, end];
    });
  }, [audioBuffer, isUndoable, loopRange]);

  // Update channel data when container size is changed
  useEffect(() => {
    if (!audioBuffer || !containerSize) return;
    const newW = Math.round(
      containerSize.width - OFFSET_SIZE.left - OFFSET_SIZE.right
    );
    const newH = Math.round(
      containerSize.height - OFFSET_SIZE.top - OFFSET_SIZE.bottom
    );
    updateWrapperSize({
      width: newW,
      height: newH,
    });
  }, [audioBuffer, containerSize, updateWrapperSize]);

  // Update channel data when viewport range is changed
  useEffect(() => {
    if (!audioBuffer) return;
    updateChannelData();
  }, [audioBuffer, updateChannelData]);

  useEffect(() => {
    if (!audioBuffer || !viewportRange) return;

    const viewportSize = size.width;

    // Only update scrollInfo when viewportSize is changed.
    if (viewportSize !== scrollInfo.viewportSize) {
      setScrollInfo((prev) => ({ ...prev, viewportSize }));
    }

    const contentSize = Math.round(
      viewportXScale(audioBuffer.duration) - viewportXScale(0)
    );

    // Only update scrollInfo when contentSize is changed.
    if (contentSize !== scrollInfo.contentSize) {
      setScrollInfo((prev) => ({ ...prev, contentSize }));
    }

    const rangeAvg = (viewportRange[0] + viewportRange[1]) / 2;
    const position = (rangeAvg / audioBuffer.duration) * 100;

    // Only update scrollInfo when position is changed.
    if (position !== scrollInfo.position) {
      setScrollInfo((prev) => ({ ...prev, position }));
    }
  }, [viewportRange, viewportXScale, audioBuffer, size.width, scrollInfo]);

  // Register, Unregister Hotkeys
  useEffect(() => {
    if (!isHotkeyEnabled || !audioBuffer) return;
    register('alt+shift+a', () => setYScaleInfo(DEFAULT_Y_SCALE_INFO_MAX));
    register('alt+a', () => setViewportRange([0, audioBuffer.duration]));

    return () => {
      unRegister('alt+shift+a');
      unRegister('alt+a');
    };
  }, [isHotkeyEnabled, audioBuffer, register, unRegister]);

  return (
    <div className="sup-audio-editor">
      <div className="editor-header"></div>
      <div className="editor-body" ref={containerRef}>
        {isLoading ? (
          <div className="editor-loading">
            <Loading />
          </div>
        ) : audioBuffer ? (
          // editor body
          <div
            className="editor-content"
            style={{
              width: size.width,
              height: size.height + X_AXIS_HEIGHT,
            }}
          >
            <svg ref={wrapperRef}>
              {/* Axis */}
              <Axis size={size} xScale={viewportXScale} />
              {/* Loop Dimmed Background */}
              {/* Grid 하위 LoopArea 하위에 넣을 경우 waveform을 덮는 이슈가 있음 */}
              {!!dragRange && (
                <g className="editor-loop-dimmed">
                  <rect
                    x={viewportXScale(dragRange?.startTime!)}
                    width={
                      viewportXScale(dragRange?.endTime!) -
                      viewportXScale(dragRange?.startTime!)
                    }
                    height={size.height + X_AXIS_HEIGHT}
                  />
                </g>
              )}
              {/* stereo까지 지원하는 가정 하에 Waveform 2개까지 draw */}
              {channelDataList &&
                channelDataList.map((channelData, idx) => {
                  // stereo일 경우 두번째 Waveform은 y축을 반으로 줄여서 그림
                  const newTranslateY =
                    idx === 1 ? newSize.height + X_AXIS_HEIGHT : X_AXIS_HEIGHT;
                  return (
                    channelData && (
                      <Wave
                        key={idx}
                        channelData={channelData}
                        size={newSize}
                        translate={{
                          x: 0,
                          y: newTranslateY,
                        }}
                        yScaleInfo={yScaleInfo}
                        showClipPath={!!dragRange}
                        clipPathId="loop-clip-path"
                      />
                    )
                  );
                })}
              {/* CurrentTime Indicator */}
              <Indicator
                size={size}
                xScale={viewportXScale}
                currentTime={currentTime}
              />
              <Grid
                audioBuffer={audioBuffer}
                size={size}
                xScale={viewportXScale}
                currentTime={currentTime}
                updateCurrentTime={updateCurrentTime}
                viewportRange={viewportRange}
                loopRange={loopRange}
                updateLoopRange={updateLoopRange}
                updateViewportPosition={updateViewportPosition}
                updateViewportScale={updateViewportScale}
                dragRange={dragRange}
                updateDragRange={updateDragRange}
                updateYScale={updateYScale}
              />
            </svg>
          </div>
        ) : (
          <div className="editor-empty">
            <p>{t('No Imported files to Edit')}</p>
          </div>
        )}
      </div>
      <div className="editor-footer">
        {!isLoading && audioBuffer && channelDataList && (
          <>
            {/* Custom Scroll Component */}
            <div className="editor-scroll">
              <Scrollbar
                viewportSize={scrollInfo.viewportSize}
                position={scrollInfo.position}
                contentSize={scrollInfo.contentSize}
                autoHide={false}
                orientation="horizontal"
                onChange={(p) => {
                  updateViewportPosition(p);
                }}
              />
            </div>
            {/* Audio Duration */}
            <p className="editor-duration">
              {formatSToMMSS(audioBuffer.duration)}
            </p>
            {/* Zoom buttons */}
            <HorizontalZoom
              audioBuffer={audioBuffer}
              channelData={channelDataList[0]}
              range={viewportRange}
              updateRange={setViewportRange}
              updateScale={updateViewportScale}
            />
            <VerticalZoom
              range={yScaleInfo}
              updateRange={setYScaleInfo}
              updateScale={updateYScale}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Editor;
