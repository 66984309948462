import Checkbox from '@/components/Checkbox/Checkbox';
import {
  CvcTargetFileInfo,
  FileInfo,
  generateInterpolationMapSelector,
} from '@/stores/cvc';
import { isInterpolationSelector } from '@/stores/models';
import { Grey } from '@/styles/Colors';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import useMatrix from '../hooks/useMatrix';

interface GenerateMatrixProps {
  generateSourceList: FileInfo[];
  generateTargetList: CvcTargetFileInfo[];
}
const ROW_HEADER_WIDTH = 22;
const ROW_BODY_WIDTH = 100 - ROW_HEADER_WIDTH;
const MIN_CELL_WIDTH = 15.5;

const hasChecked = (matrix: boolean[]) => matrix.some((item) => item);
const GenerateMatrix: React.FC<GenerateMatrixProps> = ({
  generateSourceList,
  generateTargetList,
}) => {
  const { t } = useTranslation();
  const {
    matrix,
    toggleCells,
    setMatrix,
    isAllChecked,
    toggleGroupCells,
    hasCheckedItem,
  } = useMatrix();

  const interpolationMap = useRecoilValue(generateInterpolationMapSelector);
  // matrix grid only renders when a source list and a target list are both not empty
  const isEmpty =
    generateSourceList.length === 0 || generateTargetList.length === 0;
  const isInterpolation = useRecoilValue(isInterpolationSelector);
  const targetItemList = useMemo(() => {
    return generateTargetList.filter((target) => !target.isGroup);
  }, [generateTargetList]);

  // get grid colum width by targetList length
  const gridTemplateColumns = useMemo(
    () =>
      [
        `${ROW_HEADER_WIDTH}%`,
        ...Array.from(
          { length: targetItemList.length },
          () =>
            `${Math.max(
              ROW_BODY_WIDTH / targetItemList.length,
              MIN_CELL_WIDTH
            )}%`
        ),
      ].join(' '),
    [targetItemList]
  );

  const groupList = useMemo(() => {
    return generateTargetList
      .filter((target) => {
        return (
          target.isGroup &&
          !!targetItemList.filter((item) => target.id === item.groupName).length
        );
      })
      .map((target) => {
        const groupItems = targetItemList.filter(
          (item) => target.id === item.groupName
        );
        return {
          ...target,
          length:
            target.id === 'GroupA'
              ? targetItemList.length - interpolationMap.GroupB.length
              : interpolationMap.GroupB.length,
          isChecked:
            !!groupItems.length && groupItems.every((item) => item.isChecked),
          isPartChecked: hasCheckedItem(target.id),
        };
      });
  }, [interpolationMap, generateTargetList, targetItemList, hasCheckedItem]);

  return (
    <section
      className={classNames('matrix-grid', isInterpolation && 'interpolation')}
    >
      {!isEmpty ? (
        <>
          <section style={{ gridTemplateColumns: gridTemplateColumns }}>
            {isInterpolation &&
              groupList.map((target, index) => {
                return (
                  <strong
                    className="matrix-group-header"
                    key={`target_list_${index}`}
                    title={target.name}
                    style={{
                      gridColumn: `span ${target.length}`,
                      backgroundColor: Grey[450],
                    }}
                  >
                    <span className="matrix-header">
                      <Checkbox
                        className={classNames(
                          'matrix-header-checkbox',
                          !target.isChecked &&
                            target.isPartChecked &&
                            'has-checked'
                        )}
                        checked={target.isChecked}
                        onChange={() =>
                          toggleGroupCells(
                            target.id,
                            target.isChecked ? false : true
                          )
                        }
                      />
                      <span className="matrix-filename">{target.name}</span>
                    </span>
                  </strong>
                );
              })}
            {targetItemList.map((target, index) => {
              const list = Object.keys(matrix)
                .filter((key) => key.endsWith(`:${targetItemList[index].id}`))
                .map((key) => matrix[key]);
              return (
                <strong key={`target_list_${index}`} title={target.name}>
                  <span className="matrix-header">
                    <Checkbox
                      className={classNames(
                        'matrix-header-checkbox',
                        !isAllChecked(list) && hasChecked(list) && 'has-checked'
                      )}
                      checked={isAllChecked(list)}
                      onChange={() =>
                        toggleCells(targetItemList[index].id, 'col')
                      }
                    />
                    <span className="matrix-filename">{target.name}</span>
                  </span>
                </strong>
              );
            })}
          </section>
          {generateSourceList.map((source, index) => {
            const list = Object.keys(matrix)
              .filter((key) =>
                key.startsWith(`${generateSourceList[index].id}:`)
              )
              .map((key) => matrix[key]);
            return (
              <section
                key={`source_list_${index}`}
                style={{ gridTemplateColumns: gridTemplateColumns }}
              >
                <strong title={source.name}>
                  <span className="matrix-header">
                    <Checkbox
                      className={classNames(
                        'matrix-header-checkbox',
                        !isAllChecked(list) && hasChecked(list) && 'has-checked'
                      )}
                      checked={isAllChecked(list)}
                      onChange={() =>
                        toggleCells(generateSourceList[index].id, 'row')
                      }
                    />
                    <span className="matrix-filename">{source.name}</span>
                  </span>
                </strong>
                {list.map((value, idx) => (
                  <span
                    key={`target_list_${index}_${idx}`}
                    className={classNames((index + idx) % 2) && 'even'}
                  >
                    <input
                      className="matrix-check-input"
                      id={`matrix-check-${index}-${idx}`}
                      type="checkbox"
                      checked={value}
                      onChange={() => {
                        const key = `${generateSourceList[index].id}:${targetItemList[idx].id}`;
                        setMatrix((pre) => {
                          const newMatrix = { ...pre };
                          newMatrix[key] = !newMatrix[key];
                          return newMatrix;
                        });
                      }}
                    />
                    <label
                      className="matrix-check-label"
                      htmlFor={`matrix-check-${index}-${idx}`}
                    ></label>
                  </span>
                ))}
              </section>
            );
          })}
        </>
      ) : (
        <section className="empty">
          <p>{t('No imported audio files yet.')}</p>
        </section>
      )}
    </section>
  );
};
export default GenerateMatrix;
