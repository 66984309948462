import { ReactComponent as MergedIcon } from '@/assets/icons/MergedIcon.svg';
import { ReactComponent as BottomArrowIcon } from '@/components/assets/icons/BottomArrowIcon.svg';
import { ReactComponent as RightArrowIcon } from '@/components/assets/icons/RightArrowIcon2.svg';
import IconButton from '@/components/Button/IconButton';
import Checkbox from '@/components/Checkbox/Checkbox';
import { ListItemData } from '@/components/List/types';
import StyledFileListItem from '@/ListItem/StyledFileListItem';
import { DRAG_FROM_TARGET, DRAG_ITEM_KEY } from '@/pages/cvc/config';
import { CvcTargetFileInfo, targetFileListModel } from '@/stores/cvc';
import { currentEditFileModel } from '@/stores/cvc';
import { isInterpolationSelector } from '@/stores/models';
import { targetUploadingStatesModel } from '@/stores/resource';
import { Grey, White } from '@/styles/Colors';
import classNames from 'classnames';
import React, { CSSProperties, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import useIsHighlightPanel from '../pages/cvc/hooks/useIsHighlightPanel';
import DisplayTime from './DisplayTime';
import EditableName from './EditableName';
import FileLoading from './FileLoading';
import FilePlayer from './FilePlayer';

const TargetFileListItem: React.FC<{
  index: number;
  style: CSSProperties;
  data: ListItemData;
  item?: CvcTargetFileInfo;
}> = ({ index, style, data, item = data.list[index] as CvcTargetFileInfo }) => {
  const { setCheckItem, setToggleItem, changeName, type, updateFile } = data;
  const { t } = useTranslation();
  const currentEditFile = useRecoilValue(currentEditFileModel);
  const [currentPlayTime, setCurrentPlayTime] = useState<number>();
  const isPanelActive = useIsHighlightPanel('VoiceFiles');

  const ref = useRef<HTMLLIElement>(null);
  const isInterpolation = useRecoilValue(isInterpolationSelector);
  const setTargetList = useSetRecoilState(targetFileListModel);
  const setUploadingStatus = useSetRecoilState(targetUploadingStatesModel);
  const onCancelFetching = useCallback(() => {
    setTargetList((prev) => prev.filter((v) => v.id !== item.id));
    setUploadingStatus((prev) => {
      const status = { ...prev };
      delete status[item.id];
      return status;
    });
  }, [item, setTargetList, setUploadingStatus]);
  const onDragStart = useCallback(
    (e: React.DragEvent) => {
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.dropEffect = 'none';
      e.dataTransfer?.setData(
        DRAG_ITEM_KEY,
        JSON.stringify({
          id: item.id,
          from: DRAG_FROM_TARGET,
          groupName: item.groupName || 'GroupA',
        })
      );
      e.dataTransfer?.setDragImage(
        ref.current as Element,
        e.clientX - e.currentTarget.getBoundingClientRect().left,
        0
      );
    },
    [item.id, item.groupName]
  );

  // [workaround] nth-child even 사용시 vsScroll 동작과 충돌로 스크롤시 색이 일시적으로 섞이는 현상이 생김
  return (
    <StyledFileListItem
      style={style}
      className={classNames(
        'sup-file-list-item',
        index % 2 && 'even',
        item.id === currentEditFile?.id && 'edit',
        item.isLastGroupFile && 'merge-group'
      )}
      ref={ref}
      onDragStart={onDragStart}
      draggable={!item.isMerged && !item.isFetching && isInterpolation}
      key={item.id}
      data-group={item.groupName}
    >
      <section className="inner">
        <span>
          <span className="checked">
            {item.isMerged ? (
              <MergedIcon />
            ) : (
              <Checkbox
                color={item.isChecked ? White : Grey[400]}
                checked={item.isChecked}
                onChange={() => setCheckItem(item.id)}
              />
            )}
          </span>
          {item.isGroup ? (
            <span className="file-name">{item.name}</span>
          ) : (
            <EditableName item={item} changeName={changeName} />
          )}
        </span>
        <span>
          <DisplayTime
            type={type}
            id={item.id}
            currentTime={currentPlayTime}
            duration={item.duration}
            isExpand={item.isExpanded}
          />
          <IconButton
            color="transparent"
            onClick={() => setToggleItem(item.id)}
            onKeyDown={(e) => {
              if (e.code === 'Space') {
                e.preventDefault();
              }
            }}
          >
            {item.isExpanded ? <BottomArrowIcon /> : <RightArrowIcon />}
          </IconButton>
        </span>
      </section>
      {item.isExpanded && (
        <FilePlayer
          item={item}
          type={type}
          updateCurrentTime={setCurrentPlayTime}
          updateFile={updateFile}
          isPanel={true}
          isPanelActive={isPanelActive}
        />
      )}
      {item.isFetching && (
        <FileLoading text={t('Uploading')} onCancel={onCancelFetching} />
      )}
    </StyledFileListItem>
  );
};
export default TargetFileListItem;
