import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';

export const SPLITTER = '+';

// Chromium에서는 Meta로 통일이 되었으나 Firefox에서는 Meta대신 OS를 사용
// https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_code_values
// Voice toolkit의 경우 Chromium 기반으로 개발되어 있어 Meta로 통일
// TODO: IE에서도 metaKey로 동작하는지 확인 필요
const FUNCTIONAL_KEYS = ['meta', 'shift', 'ctrl', 'alt'];

const getFunctionalKeys = (event: KeyboardEvent) =>
  FUNCTIONAL_KEYS.filter(
    (key) => event[`${key}Key` as keyof typeof event]
  ).sort();

const isEditorFocused = (target: EventTarget | null) => {
  if (!target) return false;
  let element = target as HTMLElement;
  return (
    element instanceof HTMLInputElement ||
    element instanceof HTMLTextAreaElement ||
    element.isContentEditable
  );
};

interface HotKeyManager {
  register: (shortcut: string, callback: () => void) => void;
  unRegister: (shortcut: string) => void;
}

export const HotkeyManagerContext = createContext<HotKeyManager>(
  {} as HotKeyManager
);
export const HotkeyManagerContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [events, setEvents] = useState<{ key: string; callback: () => void }[]>(
    []
  );

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      const eventKey = event.code
        .trim()
        .toLocaleLowerCase()
        .replace(/key|digit|numpad|arrow/, '');
      const functionalKeys = getFunctionalKeys(event);
      if (functionalKeys.length > 0) {
        const shortcut = [...functionalKeys, eventKey].join(SPLITTER);
        const event = events.find((event) => event.key === shortcut);
        event && event.callback();
      } else {
        if (isEditorFocused(event.target)) {
          return;
        }
        const evt = events.find((event) => event.key === eventKey);
        if (evt) {
          // TODO: space, arrow 키에 대한 예외 처리
          // space, arrow 키는 기본 동작이 있어서 예외 처리 필요
          // 더 좋은 방법이 있을지 고민 필요
          if (event.code === 'Space' || event.code.includes('Arrow')) {
            event.preventDefault();
          }
          evt.callback();
        }
      }
    };
    if (events.length) {
      window.addEventListener('keydown', handler);
    }
    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [events]);

  const register = useCallback((shortcut: string, callback: () => void) => {
    setEvents((preEvents) => {
      let newEvents = [...preEvents];
      const [eventKey, ...fncKeys] = shortcut
        .toLocaleLowerCase()
        .split(SPLITTER)
        .reverse();
      const sortedFncKeys = fncKeys.sort();
      const key = [...sortedFncKeys, eventKey].join(SPLITTER);
      // 동일 키에 대한 중복 등록 방지
      newEvents.filter((event) => event.key !== key);
      newEvents.push({
        key,
        callback,
      });
      return newEvents;
    });
  }, []);

  const unRegister = useCallback((shortcut: string) => {
    setEvents((preEvents) => {
      const newEvents = [...preEvents];
      return newEvents.filter(
        (event) => event.key !== shortcut.toLocaleLowerCase()
      );
    });
  }, []);

  return (
    <HotkeyManagerContext.Provider value={{ register, unRegister }}>
      {children}
    </HotkeyManagerContext.Provider>
  );
};

export default HotkeyManagerContextProvider;
