import {
  generateMatrixModelSelector,
  generateTargetListSelector,
} from '@/stores/cvc';
import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

type DIMENSION = 'row' | 'col';
const isAllChecked = (matrix: boolean[]) => matrix.every((item) => item);
const useMatrix = () => {
  const [matrix, setMatrix] = useRecoilState(generateMatrixModelSelector);

  const toggleCells = useCallback(
    (key: string, dimension: DIMENSION, isChecked?: boolean) => {
      const list = Object.keys(matrix).filter((item: string) =>
        dimension === 'row'
          ? item.startsWith(`${key}:`)
          : item.endsWith(`:${key}`)
      );
      const isAll =
        isChecked ?? !isAllChecked(list.map((item) => matrix[item]));
      setMatrix((pre) => {
        const newMatrix = { ...pre };
        list.forEach((item) => {
          newMatrix[item] = isAll;
        });
        return newMatrix;
      });
    },
    [setMatrix, matrix]
  );
  const generateTargetList = useRecoilValue(generateTargetListSelector);
  const toggleGroupCells = useCallback(
    (group: string, value: boolean) => {
      const groupItems = generateTargetList.filter(
        (target) => target.groupName === group
      );
      const list = Object.keys(matrix).filter((item: string) => {
        return groupItems.some((groupItem) => item.endsWith(groupItem.id));
      });
      setMatrix((pre) => {
        const newMatrix = { ...pre };
        list.forEach((item) => {
          newMatrix[item] = value;
        });
        return newMatrix;
      });
    },
    [generateTargetList, matrix, setMatrix]
  );
  const hasCheckedItem = useCallback(
    (group: string) => {
      const groupItems = generateTargetList.filter(
        (target) => target.groupName === group
      );
      return Object.keys(matrix)
        .filter((item: string) => {
          return groupItems.some((groupItem) => item.endsWith(groupItem.id));
        })
        .every((item) => matrix[item]);
    },
    [matrix, generateTargetList]
  );

  return {
    toggleCells,
    matrix,
    setMatrix,
    isAllChecked,
    toggleGroupCells,
    hasCheckedItem,
  };
};

export default useMatrix;
