import { ListItemData } from '@/components/List/types';
import { MssExtendFileInfo } from '@/stores/mss';
import { CSSProperties } from 'react';

import MssGroupItem from './MssGroupItem';
import MssFileListItem from './MssResultFileListItem';

const MSSResultListItem: React.FC<{
  index: number;
  style: CSSProperties;
  data: ListItemData;
}> = ({ index, style, data }) => {
  const item = data.list[index] as MssExtendFileInfo;
  if (item.isGroup) {
    return <MssGroupItem index={index} style={style} data={data} />;
  } else {
    return <MssFileListItem index={index} style={style} data={data} />;
  }
};
export default MSSResultListItem;
