import useAudioBufferStore from '@/hooks/useAudioBufferStore';
import { LoopRange } from '@/hooks/useAudioController';
import useUndoManager from '@/hooks/useUndoManager/useUndoManager';
import { exportAudioBufferToFile } from '@/util/audio';
import { useCallback, useContext, useEffect } from 'react';

import { HotkeyManagerContext } from '../../providers/HotkeyManagerContextProvider';
import Editor from './Editor';
import SaveModal from './SaveModal';
import StyledAudioEditor from './StyledAudioEditor';
import TimeDisplay from './TimeDisplay';
import Toolbar from './Toolbar';
import { Size } from './types';

interface AudioEditorProps {
  size?: Size;
  audioBuffer?: AudioBuffer;
  loopRange?: LoopRange | null;
  updateLoopRange?: (range: LoopRange | null) => void;
  currentTime?: number;
  updateCurrentTime?: (time: number) => void;
  playAudio?: (time?: number) => void;
  pauseAudio?: () => void;
  cutAudio?: () => void;
  trimAudio?: () => void;
  isPlaying?: boolean;
  convertToMono?: boolean;
  onSave?: (file: File, overwrite?: boolean) => void;
  onReset?: () => void;
  onCancel?: () => void;
  showModal?: boolean;
  updateShowModal?: (showModal: boolean) => void;
  isHotkeyEnabled?: boolean;
  dragRange?: LoopRange | null;
  updateDragRange?: (range: LoopRange | null) => void;
  fileName?: string;
  isLoading?: boolean;
}

const AudioEditor = ({
  size,
  audioBuffer,
  loopRange,
  updateLoopRange,
  currentTime,
  updateCurrentTime,
  playAudio,
  pauseAudio,
  cutAudio,
  trimAudio,
  onSave,
  onReset,
  onCancel,
  isPlaying,
  convertToMono = false,
  showModal,
  updateShowModal,
  isHotkeyEnabled,
  dragRange,
  updateDragRange,
  fileName,
  isLoading,
}: AudioEditorProps) => {
  const { clearAudioBufferStore } = useAudioBufferStore();
  const { reset: resetUndoManager, undo, redo } = useUndoManager();
  const { register, unRegister } = useContext(HotkeyManagerContext);

  const reset = useCallback(() => {
    updateCurrentTime?.(0);
    updateLoopRange?.(null);
    clearAudioBufferStore();
    resetUndoManager();
  }, [
    updateCurrentTime,
    updateLoopRange,
    clearAudioBufferStore,
    resetUndoManager,
  ]);

  // Toolbar의 Save 버튼을 클릭했을 때
  const handleSave = useCallback(() => {
    updateShowModal?.(true);
  }, [updateShowModal]);

  const handleOverwrite = useCallback(() => {
    updateShowModal?.(false);
    /*
      1. 기존 audioBuffer를 편집된 상태로 업데이트 (recoil)
      2. audioBufferStore는 리셋 
      3. 나머지 loopRange 등등 관련 상태들 리셋 
    */
    if (!audioBuffer || !fileName) return;
    const file = exportAudioBufferToFile(audioBuffer, fileName);
    onSave?.(file, true);
    reset();
    updateShowModal?.(false);
  }, [updateShowModal, reset, audioBuffer, fileName, onSave]);

  const handleCreateNew = useCallback(() => {
    /*
      1. audioBuffer 뽑아서 파일을 만든다 -> addFile 
      2. 파일을 서버에 업로드한다 -> uploadFile
      3. new file 추가 (recoil)
      4. audioBufferStore는 리셋 
      5. 나머지 loopRange 등등 관련 상태들 리셋 
      6. currentFile을 새로 만든 파일로 업데이트
      */
    if (!audioBuffer || !fileName) return;
    const file = exportAudioBufferToFile(audioBuffer, fileName);
    onSave?.(file);
    reset();
    updateShowModal?.(false);
  }, [updateShowModal, reset, audioBuffer, fileName, onSave]);

  // 모달을 닫았을 때
  const handleClose = useCallback(() => {
    onCancel?.();
    updateShowModal?.(false);
  }, [updateShowModal, onCancel]);

  // Don't save 버튼을 클릭했을 때
  const handleReset = useCallback(() => {
    reset();
    onReset?.();
    updateShowModal?.(false);
  }, [reset, updateShowModal, onReset]);

  useEffect(() => {
    if (!isHotkeyEnabled || !audioBuffer) return;
    register('meta+z', undo);
    register('meta+shift+z', redo);
    cutAudio && register('backspace', cutAudio);
    trimAudio && register('ctrl+t', trimAudio);
    register('ctrl+s', handleSave);

    return () => {
      unRegister('meta+z');
      unRegister('meta+shift+z');
      cutAudio && unRegister('backspace');
      trimAudio && unRegister('ctrl+t');
      unRegister('ctrl+s');
    };
  }, [
    audioBuffer,
    register,
    unRegister,
    isHotkeyEnabled,
    undo,
    redo,
    cutAudio,
    trimAudio,
    handleSave,
  ]);

  useEffect(() => {
    if (!showModal) return;
    isPlaying && pauseAudio?.();
  }, [showModal, isPlaying, pauseAudio]);

  return (
    <StyledAudioEditor className="sup-audio">
      <div className="sup-audio-header">
        <TimeDisplay
          currentTime={currentTime}
          startTime={dragRange?.startTime || loopRange?.startTime}
          endTime={dragRange?.endTime || loopRange?.endTime}
        />
        <Toolbar
          isEditable={!isLoading && !!audioBuffer}
          isRangeSelected={!!loopRange}
          cutAudio={cutAudio}
          trimAudio={trimAudio}
          onSave={handleSave}
        />
      </div>
      <Editor
        containerSize={size}
        audioBuffer={audioBuffer}
        currentTime={currentTime}
        updateCurrentTime={updateCurrentTime}
        loopRange={loopRange}
        updateLoopRange={updateLoopRange}
        convertToMono={convertToMono}
        dragRange={dragRange}
        updateDragRange={updateDragRange}
        isHotkeyEnabled={isHotkeyEnabled}
        isLoading={isLoading}
      />
      <SaveModal
        isOpen={!!showModal}
        onClose={handleClose}
        onReset={handleReset}
        onSave={handleCreateNew}
        onOverwrite={handleOverwrite}
      />
    </StyledAudioEditor>
  );
};

export default AudioEditor;
