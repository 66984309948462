import { ReactComponent as RecordIcon } from '@/components/assets/icons/RecordIcon.svg';
import { ReactComponent as StopIcon } from '@/components/assets/icons/RecordStopIcon.svg';
import Button from '@/components/Button/Button';
import { Grey, Secondary } from '@/styles/Colors';
import { FontSize, FontWeight } from '@/styles/Typography';
import { audioBufferToWav, getAudioBuffer } from '@/util/audio';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';

const recordStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: Secondary[300],
  borderColor: Secondary[300],
  borderRadius: '0.25rem',
  fontSize: FontSize['Lg'],
  lineHeight: '150%',
  padding: '0.25rem 0.5rem',
  cursor: 'pointer',
  color: Grey[50],
  fontWeight: FontWeight['SemiBold'],
  minWidth: '5rem',
  justifyContent: 'space-around',
};
const simpleRecordStyle = {
  ...recordStyle,
  padding: '0.175rem 0.25rem',
  minWidth: '1.5rem',
};

export interface Recorder {
  start: () => Promise<void>;
  stop: () => Promise<Blob>;
}
export const recordAudio = () =>
  new Promise<Recorder>((resolve, reject) => {
    (async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          /**
           * [workaround]
           * 주변 소음으로 인한 Input Volume이 자동 조절 되지 않게 속성 세팅(G-star 2023 에서 헤드셋을 통한 환경 테스트 완료)
           */
          audio: {
            autoGainControl: false,
            noiseSuppression: false,
          },
        });

        if (!MediaRecorder || typeof MediaRecorder !== 'function') {
          reject(new Error('MediaRecorder is not supported in this browser.'));
          return;
        }

        const mediaRecorder = new MediaRecorder(stream);
        const audioChunks: BlobPart[] = [];

        mediaRecorder.addEventListener('dataavailable', (event) => {
          audioChunks.push(event.data);
        });

        const start = () =>
          new Promise<void>((resolve) => {
            mediaRecorder.addEventListener('start', () => {
              resolve();
            });
            mediaRecorder.start();
          });

        const stop = () =>
          new Promise<Blob>((resolve) => {
            mediaRecorder.addEventListener('stop', async () => {
              resolve(new Blob(audioChunks));
            });

            mediaRecorder.stop();
          });

        resolve({ start, stop });
      } catch (error) {
        reject(
          new Error(
            'Error accessing the microphone: ' + (error as Error).message
          )
        );
      }
    })();
  });

const getDigitNumber = (num: number) => {
  return num >= 10 ? num : '0' + num;
};

const getFileDate = () => {
  let now = new Date();
  let year = now.getFullYear();
  let month = getDigitNumber(now.getMonth() + 1);
  let date = getDigitNumber(now.getDate());
  let hour = getDigitNumber(now.getHours());
  let minute = getDigitNumber(now.getMinutes());
  let second = getDigitNumber(now.getSeconds());
  let apm = now.getHours() >= 12 ? 'PM' : 'AM';
  return `${year}-${month}-${date} ${hour}:${minute}:${second} ${apm}`;
};

interface RecordProps {
  addFiles: (files: File[]) => void;
  captions?: string[];
  defaultName?: string;
  className?: string;
}

const Record: React.FC<RecordProps> = ({
  addFiles,
  captions,
  className,
  defaultName = 'Recorded',
}) => {
  const [isRecording, setRecording] = React.useState<boolean | undefined>();
  const [recorder, setRecorder] = React.useState<Recorder | undefined>();
  const onClick = useCallback(() => {
    setRecording((pre) => !pre);
  }, [setRecording]);

  useEffect(() => {
    if (isRecording && !recorder) {
      recordAudio().then((recorder) => {
        setRecorder(recorder);
        recorder.start();
      });
    } else if (isRecording === false) {
      recorder?.stop().then(async (blob) => {
        // todo 나중에 Modal 팝업으로 변경
        const recordFileName = `${defaultName}_${getFileDate()}`;
        const name =
          prompt('Enter file name', recordFileName) ?? recordFileName;
        const audioBuffer = await getAudioBuffer(
          await new Response(blob).arrayBuffer()
        );
        addFiles([
          new File(
            [audioBufferToWav(audioBuffer as AudioBuffer)],
            `${name}.wav`,
            { type: 'audio/wav' }
          ),
        ]);
        setRecorder(undefined);
      });
    }
  }, [isRecording, addFiles, recorder, defaultName]);

  return (
    <Button
      style={captions ? recordStyle : simpleRecordStyle}
      onClick={onClick}
      startIcon={captions && (isRecording ? <StopIcon /> : <RecordIcon />)}
      className={classNames('file-record-button', className)}
    >
      {/* {todo 디자인 최종 확인 필요} */}
      {captions ? isRecording ? captions[1] : captions[0] : <RecordIcon />}
    </Button>
  );
};
export default Record;
