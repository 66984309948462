import { ReactComponent as BottomArrowIcon } from '@/components/assets/icons/BottomArrowIcon.svg';
import { ReactComponent as RightArrowIcon } from '@/components/assets/icons/RightArrowIcon2.svg';
import IconButton from '@/components/Button/IconButton';
import Checkbox from '@/components/Checkbox/Checkbox';
import { ListItemData } from '@/components/List/types';
import StyledFileListItem from '@/ListItem/StyledFileListItem';
import { DRAG_FROM_RESULT, DRAG_ITEM_KEY } from '@/pages/cvc/config';
import { ExtendFileInfo, resultFileListModel } from '@/stores/cvc';
import { currentEditFileModel } from '@/stores/cvc';
import { Grey, White } from '@/styles/Colors';
import classNames from 'classnames';
import { CSSProperties, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import useIsHighlightPanel from '../pages/cvc/hooks/useIsHighlightPanel';
import DisplayTime from './DisplayTime';
import EditableName from './EditableName';
import FileLoading from './FileLoading';
import FilePlayer from './FilePlayer';

const ResultFileListItem: React.FC<{
  index: number;
  style: CSSProperties;
  data: ListItemData;
}> = ({ index, style, data }) => {
  const { list, setToggleItem, setCheckItem, type, changeName, updateFile } =
    data;
  const item = list[index] as ExtendFileInfo;
  const { t } = useTranslation();
  const currentEditFile = useRecoilValue(currentEditFileModel);
  const [currentPlayTime, setCurrentPlayTime] = useState<number>();
  const isPanelActive = useIsHighlightPanel('Results');

  const setResultList = useSetRecoilState(resultFileListModel);
  const onCancelFetching = useCallback(() => {
    setResultList((prev) => prev.filter((v) => v.id !== item.id));
  }, [item, setResultList]);
  const isFailed = useMemo(() => item.fetchStatus === 'FAILED', [item]);
  const ref = useRef<HTMLLIElement>(null);
  const onDragStart = useCallback(
    (e: React.DragEvent) => {
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.dropEffect = 'none';
      e.dataTransfer?.setData(
        DRAG_ITEM_KEY,
        JSON.stringify({
          id: item.id,
          from: DRAG_FROM_RESULT,
        })
      );
      e.dataTransfer?.setDragImage(
        ref.current as Element,
        e.clientX - e.currentTarget.getBoundingClientRect().left,
        0
      );
    },
    [item.id]
  );

  // [workaround] nth-child even 사용시 vsScroll 동작과 충돌로 스크롤시 색이 일시적으로 섞이는 현상이 생김
  return (
    <StyledFileListItem
      style={style}
      className={classNames(
        'sup-file-list-item',
        index % 2 && 'even',
        item.id === currentEditFile?.id && 'edit'
      )}
      ref={ref}
      key={item.id}
      onDragStart={onDragStart}
      draggable={!item.isFetching}
    >
      <section className="inner">
        <span>
          <span className="checked">
            <Checkbox
              color={item.isChecked ? White : Grey[400]}
              checked={item.isChecked}
              onChange={() => setCheckItem(item.id)}
            />
          </span>
          <EditableName item={item} changeName={changeName} />
        </span>
        <span>
          <DisplayTime
            type={type}
            id={item.id}
            currentTime={currentPlayTime}
            duration={item.duration}
            isExpand={item.isExpanded}
          />
          <IconButton
            color="transparent"
            onClick={() => setToggleItem(item.id)}
            onKeyDown={(e) => {
              if (e.code === 'Space') {
                e.preventDefault();
              }
            }}
          >
            {item.isExpanded ? <BottomArrowIcon /> : <RightArrowIcon />}
          </IconButton>
        </span>
      </section>
      {item.isExpanded && (
        <FilePlayer
          item={item}
          type={type}
          updateCurrentTime={setCurrentPlayTime}
          updateFile={updateFile}
          isPanel={true}
          isPanelActive={isPanelActive}
        />
      )}
      {item.isFetching && (
        <FileLoading
          isFailed={isFailed}
          text={isFailed ? t('Generating Failed') : t('Generating')}
          onCancel={onCancelFetching}
        />
      )}
    </StyledFileListItem>
  );
};
export default ResultFileListItem;
