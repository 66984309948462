import classNames from 'classnames';
import { scaleLinear } from 'd3-scale';
import { pointer, select } from 'd3-selection';
import { line } from 'd3-shape';
import {
  MouseEvent,
  PointerEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Grey } from '../../styles/Colors';
import { simpleResampleAudioData } from '../../util/audio';
import { ReactComponent as PauseIcon } from '../assets/icons/PauseButtonIcon.svg';
import { ReactComponent as PlayIcon } from '../assets/icons/PlayButtonIcon.svg';
import { Size } from '../AudioEditor/types';
import IconButton from '../Button/IconButton';
import Loading from '../Loading/Loading';
import StyledAudioPlayer from './StyledAudioPlayer';

interface AudioPlayerProps {
  audioBuffer?: AudioBuffer | null;
  isCanvas?: boolean;
  currentTime?: number;
  updateCurrentTime?: (time: number) => void;
  playAudio?: (time?: number) => void;
  isPlaying?: boolean;
  pauseAudio?: () => void;
}

const AudioPlayer = ({
  audioBuffer,
  isCanvas = false,
  currentTime,
  playAudio,
  isPlaying,
  pauseAudio,
}: AudioPlayerProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const indicatorRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });
  const [isLoading, setIsLoading] = useState(true);

  const xScale = useMemo(
    () =>
      scaleLinear()
        .domain([0, audioBuffer?.duration || 0])
        .range([0, size.width]),
    [audioBuffer, size]
  );

  // 재생 여부에 따라 Play/Pause
  const handlePlayPause = useCallback(
    (_: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      if (isPlaying) {
        pauseAudio?.();
      } else {
        playAudio?.();
      }
    },
    [isPlaying, pauseAudio, playAudio]
  );

  const drawCanvas = useCallback(() => {
    if (!size.width || !size.height) return;
    if (!audioBuffer || !canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    let { width, height } = size;
    const ratio = window.devicePixelRatio || 1;
    width *= ratio;
    height *= ratio;
    canvas.width = width;
    canvas.height = height;
    // audio player에서는 mono로만 처리
    const data = audioBuffer.getChannelData(0);
    const resampledData = simpleResampleAudioData(data, width);
    ctx.clearRect(0, 0, width, height);
    ctx.beginPath();
    ctx.moveTo(0, height / 2);
    for (let i = 0; i < resampledData.length; i++) {
      const x = (i / resampledData.length) * width;
      const y = (resampledData[i] * height) / 2 + height / 2;
      ctx.lineTo(x, y);
    }
    ctx.strokeStyle = Grey[200];
    ctx.scale(ratio, ratio);
    ctx.stroke();
    setIsLoading(false);
  }, [audioBuffer, size]);

  const drawSvg = useCallback(() => {
    if (!size.width || !size.height) return;
    if (!audioBuffer || !svgRef.current) return;
    const wrapper = select(svgRef.current);
    const path = wrapper.select<SVGPathElement>('.wave-path');
    const { width, height } = size;

    wrapper
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', `0 0 ${width} ${height}`);

    const data = audioBuffer.getChannelData(0);
    const resampledData = simpleResampleAudioData(data, width);

    const [min, max] = [-1, 1];
    const pathYScale = scaleLinear().domain([min, max]).range([0, height]);
    const pathXscale = scaleLinear()
      .domain([0, resampledData.length - 1])
      .range([0, width]);

    const lineGenerator = line<number>()
      .x((_, i) => pathXscale(i))
      .y((d) => {
        if (d > max) return pathYScale(max);
        return pathYScale(d);
      });

    path
      .datum(resampledData)
      .attr('d', lineGenerator)
      .attr('fill', 'none')
      .attr('stroke', Grey[200])
      .attr('stroke-width', 1);

    setIsLoading(false);
  }, [audioBuffer, size]);

  // PointerDown 시 해당 포지션의 시간 재생
  const handlePointerDown = useCallback(
    (e: PointerEvent<HTMLCanvasElement | SVGGElement>) => {
      if (!audioBuffer) return;
      let time;
      if (isCanvas) {
        const canvas = canvasRef.current;
        if (!canvas) return;
        time = xScale.invert(pointer(e, canvas)[0]);
      } else {
        const svg = svgRef.current;
        if (!svg) return;
        time = xScale.invert(pointer(e, svg)[0]);
      }
      playAudio?.(time);
    },
    [audioBuffer, playAudio, xScale, isCanvas]
  );

  useEffect(() => {
    const indicator = indicatorRef.current;
    if (!indicator) return;
    indicator.style.transform = `translateX(${xScale(currentTime || 0)}px)`;
  }, [currentTime, xScale]);

  useEffect(() => {
    if (!audioBuffer) {
      setIsLoading(true);
      return;
    }
    isCanvas ? drawCanvas() : drawSvg();
  }, [drawCanvas, drawSvg, isCanvas, audioBuffer]);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;
    // Resize 시 size 업데이트
    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      if (width === 0 || height === 0) return;
      if (width === size.width && height === size.height) return;
      setSize({ width, height });
    });

    resizeObserver.observe(wrapper);
    return () => {
      resizeObserver.unobserve(wrapper);
    };
  }, [size]);

  return (
    <StyledAudioPlayer className="sup-audio-player">
      <IconButton
        className="audio-player-button"
        color={Grey[400]}
        radius="none"
        onClick={handlePlayPause}
        onKeyDown={(e) => {
          if (e.code === 'Space') {
            e.preventDefault();
          }
        }}
        disabled={isLoading}
      >
        {isPlaying ? (
          <PauseIcon className="icon-pause" />
        ) : (
          <PlayIcon className="icon-play" />
        )}
      </IconButton>
      <div className="audio-player-wrapper" ref={wrapperRef}>
        {isLoading && <Loading className="audio-player-loading" />}
        {isCanvas && (
          <canvas
            className={classNames('audio-player-wave', isLoading && 'hidden')}
            ref={canvasRef}
            onPointerDown={handlePointerDown}
          />
        )}
        {!isCanvas && (
          <svg
            className="audio-player-wave"
            ref={svgRef}
            onPointerDown={handlePointerDown}
          >
            <g className={classNames(isLoading && 'hidden')}>
              <path className="wave-path" />
            </g>
          </svg>
        )}
        <div
          className={classNames(
            'audio-player-indicator',
            isLoading && 'hidden'
          )}
          ref={indicatorRef}
        ></div>
      </div>
    </StyledAudioPlayer>
  );
};

export default AudioPlayer;
