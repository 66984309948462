import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Grey, Primary } from '../../styles/Colors';
import { FontSize, FontWeight } from '../../styles/Typography';

export const CommonSwitchStyles = {
  Container: css`
    background-color: ${Grey[500]};
    color: ${Grey[400]};
    font-size: ${FontSize['Md']};
    font-weight: ${FontWeight['SemiBold']};
    display: inline-grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    height: 1.25rem;
    border-radius: 0.13rem;
    overflow: hidden;
  `,
  Label: css`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.5rem;
    transition: 0.1s ease-in-out;
    transition-property: background-color, color, box-shadow;
    user-select: none;
  `,
  SelectedLabel: css`
    background-color: ${Primary[400]};
    color: ${Primary[100]};
    box-shadow: 0px 0px 6px rgba(0, 80, 255, 0.4),
      2px 0px 2px rgba(0, 0, 0, 0.32), -2px 0px 2px rgba(0, 0, 0, 0.32);
  `,
};

export const StyledSwitch = styled.label`
  ${CommonSwitchStyles.Container}
  cursor: pointer;

  input {
    appearance: none;
    // NOTE: 영역을 차지 하지 않도록 하기 위해
    position: absolute;
  }

  .sup-switch-label {
    ${CommonSwitchStyles.Label}
  }

  &:not(&:has(input:checked)) .sup-switch-label.unchecked {
    ${CommonSwitchStyles.SelectedLabel}
  }

  &:has(input:checked) .sup-switch-label.checked {
    ${CommonSwitchStyles.SelectedLabel}
  }

  &:has(input:disabled) {
    cursor: not-allowed;
  }
`;
