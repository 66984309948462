import { getResourceInfo } from '@/api';
import { EXPIRES_PARAM } from '@/consts';
import {
  WS_EVENTS,
  WebSocketContext,
  conditionalCallback,
} from '@/providers/WebSocketProvider';
import { ResourceStatus } from '@/stores/resource';
import { fromNowBySecond } from '@/util/time';
import { getParam } from '@/util/url';
import { useCallback, useContext, useEffect } from 'react';
import { SetterOrUpdater } from 'recoil';

export interface ResourceInfo {
  originalUrl: string;
  transcodedUrl: string;
  duration: number;
  originalExpires: string | number | null;
}
const useUploadEvents = (
  uploadingStatus: ResourceStatus,
  setUploadingStatus: SetterOrUpdater<ResourceStatus>,
  complete: (data: ResourceInfo, resourceId: string) => void,
  addFiles: (sessionId: string, files: File[], group?: string) => void
) => {
  const { sessionId, on, off } = useContext(WebSocketContext);
  useEffect(() => {
    const onPending = conditionalCallback(({ resource_id }: any) => {
      setUploadingStatus((prev) => {
        return {
          ...prev,
          [resource_id]: 'PENDING',
        };
      });
    }, uploadingStatus);
    const onProcessing = conditionalCallback(({ resource_id }: any) => {
      setUploadingStatus((prev) => {
        return {
          ...prev,
          [resource_id]: 'PROCESSING',
        };
      });
    }, uploadingStatus);
    const onComplete = conditionalCallback(async ({ resource_id }: any) => {
      setUploadingStatus((prev) => {
        return {
          ...prev,
          [resource_id]: 'COMPLETE',
        };
      });
      const {
        data: { data },
      } = await getResourceInfo(resource_id);
      const originalExpires = getParam(data.original.url, EXPIRES_PARAM);

      complete(
        {
          originalUrl: data.original.url,
          transcodedUrl: data.transcoded[0].url,
          duration: data.original.metadata.props.duration,
          originalExpires: originalExpires && fromNowBySecond(originalExpires),
        },
        resource_id
      );
    }, uploadingStatus);
    const onFailed = conditionalCallback(({ resource_id }: any) => {
      setUploadingStatus((prev) => {
        return {
          ...prev,
          [resource_id]: 'FAILED',
        };
      });
    }, uploadingStatus);
    on(WS_EVENTS.JOB_PENDING, onPending);
    on(WS_EVENTS.JOB_PROCESSING, onProcessing);
    on(WS_EVENTS.JOB_DONE, onComplete);
    on(WS_EVENTS.JOB_FAILED, onFailed);
    return () => {
      off(WS_EVENTS.JOB_PENDING, onPending);
      off(WS_EVENTS.JOB_PROCESSING, onProcessing);
      off(WS_EVENTS.JOB_DONE, onComplete);
      off(WS_EVENTS.JOB_FAILED, onFailed);
    };
  }, [uploadingStatus, on, off, setUploadingStatus, complete]);
  const uploadFile = useCallback(
    (data: File[], group?: string) => {
      addFiles(sessionId as string, data, group);
    },
    [sessionId, addFiles]
  );

  return { uploadFile };
};
export default useUploadEvents;
