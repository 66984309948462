import { CvCResultFileInfo, sourceFileListModel } from '@/stores/cvc';
import { sourceUploadingStatesModel } from '@/stores/resource';
import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

const useAsSource = () => {
  const sourceList = useRecoilValue(sourceFileListModel);
  const setSourceList = useSetRecoilState(sourceFileListModel);
  const setSourceUploadingStates = useSetRecoilState(
    sourceUploadingStatesModel
  );
  return useCallback(
    (item: CvCResultFileInfo) => {
      if (!sourceList.find((source) => source.id === item.resource_id)) {
        setSourceList((prev) => [
          {
            ...item,
            id: item.resource_id as string,
            isSource: true,
            isChecked: true,
          },
          ...prev,
        ]);
        setSourceUploadingStates((prev) => {
          return { ...prev, [item.resource_id as string]: 'COMPLETE' };
        });
      } else {
        // todo custom modal and fix description
        alert('이미 추가된 파일입니다.');
      }
    },
    [sourceList, setSourceList, setSourceUploadingStates]
  );
};
export default useAsSource;
