import HotkeyManagerContextProvider from '@/providers/HotkeyManagerContextProvider';
import {
  panelDefaultSizeModel,
  panelsModelSelector,
  selectedCVCPanelModel,
} from '@/stores//panels';
import { useEffect, useMemo, useRef } from 'react';
import { ImperativePanelGroupHandle, PanelGroup } from 'react-resizable-panels';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import ConfirmModal from '../../components/Modal/ConfirmModal/ConfirmModal';
import {
  CVC_PANEL_ORDER_MAP,
  GRID_GAP,
  PANEL_AUTO_SAVE_ID,
} from '../../consts/cvc';
import { EditData } from '../../hooks/useUndoManager/setAudioEditSnapshot';
import { UndoManagerProvider } from '../../hooks/useUndoManager/UndoManager';
import BrandWrapper from '../../layout/Landing/BrandWrapper';
import {
  SectionPanel,
  SectionPanelResizeHandle,
} from '../../layout/SectionPanel';
import { currentEditFileModel, prepareEditFileModel } from '../../stores/cvc';
import AudioEditorPanel from './AudioEditorPanel';
import { MODEL_PANEL_DEFAULT, RESULTS_PANEL_DEFAULT } from './config';
import CvcGuide from './CvcGuide';
import useConfirmModal from './hooks/useConfirmModal';
import SelectModelPanel from './ModelControl/SelectModelPanel';
import ResultsPanel from './ResultPanel/ResultsPanel';
import StyledCvcContent from './styled/StyledCvcContent';
import VoiceFilesPanel from './VoiceFilesPanel';

const CvcPage = () => {
  const panelState = useRecoilValue(panelsModelSelector);
  const resetSelectedCVCPanel = useResetRecoilState(selectedCVCPanelModel);
  const setPanelDefaultSizes = useSetRecoilState(panelDefaultSizeModel);

  const { confirmModalState, closeConfirmModal } = useConfirmModal();
  const resetPrepareEditFile = useResetRecoilState(prepareEditFileModel);
  const resetCurrentEditFile = useResetRecoilState(currentEditFileModel);
  // todo 페이지 벗어나기 전에, 현재 작업 내역이 날아 갈 수 있다는 경고를 띄워주는건 어떤지 확인이 필요할듯.
  useEffect(() => {
    return () => {
      resetSelectedCVCPanel();
      // 페이지 전환 시 현재 편집 내역 초기화
      resetPrepareEditFile();
      resetCurrentEditFile();
    };
  }, [resetPrepareEditFile, resetCurrentEditFile, resetSelectedCVCPanel]);

  const ref = useRef<ImperativePanelGroupHandle>(null);
  // Resize Event for Panel layout,
  // todo throttle 적용 필요할듯 함.
  useEffect(() => {
    const resetSize = () => {
      const newWidth = document.body.clientWidth;
      // [workaround] only center panel ratio is effected by panel state(show/hide)
      let centerWidth = newWidth;
      if (panelState[CVC_PANEL_ORDER_MAP.CONTROL]) {
        centerWidth -= MODEL_PANEL_DEFAULT;
      }
      if (panelState[CVC_PANEL_ORDER_MAP.RESULTS]) {
        centerWidth -= RESULTS_PANEL_DEFAULT;
      }
      const isVisibleCenterPanel =
        panelState[CVC_PANEL_ORDER_MAP.AUDIO_EDITOR] ||
        panelState[CVC_PANEL_ORDER_MAP.VOICE_FILES];
      const panelWidths = [
        panelState[CVC_PANEL_ORDER_MAP.CONTROL] ? MODEL_PANEL_DEFAULT : 0,
        isVisibleCenterPanel ? centerWidth : 0,
        panelState[CVC_PANEL_ORDER_MAP.RESULTS] ? RESULTS_PANEL_DEFAULT : 0,
      ];
      const totalWidth = panelWidths.reduce((acc, cur) => acc + cur, 0);
      // [workaround]
      // The panel component's setLayout only allows the sum of the parameters to be 100.
      // But, the sum of the float values does not always add up to 100.
      // So, the float value is rounded -
      const panelRatios = panelWidths.map((width) =>
        Math.round((width / totalWidth) * 100)
      );
      // and, If the sum of the values is not 100, the first value is adjusted.
      const totalRatio = panelRatios.reduce((acc, cur) => acc + cur, 0);
      if (totalRatio !== 100) {
        const diff = totalRatio - 100;
        const index = panelRatios.findIndex((ratio) => ratio > 0);
        panelRatios[index] -= diff;
      }
      // setLayout command for refresh panel size
      ref.current?.setLayout(panelRatios.filter((ratio) => ratio));
      // Need to setup default size based new clientWidth
      setPanelDefaultSizes((preData) => {
        const newData = { ...preData };
        if (panelRatios[0])
          newData[CVC_PANEL_ORDER_MAP.CONTROL] = panelRatios[0];
        if (panelRatios[2])
          newData[CVC_PANEL_ORDER_MAP.RESULTS] = panelRatios[2];
        return newData;
      });
    };
    // For panelState change(show/hide)
    resetSize();
    window.addEventListener('resize', resetSize);
    return () => {
      window.removeEventListener('resize', resetSize);
    };
  }, [setPanelDefaultSizes, panelState]);

  const isAllHide = useMemo(
    () => Object.values(panelState).every((state) => !state),
    [panelState]
  );

  return (
    <HotkeyManagerContextProvider>
      <StyledCvcContent>
        {/* {Panel layout, each Panel has order number for resizing} */}
        {!isAllHide && (
          <PanelGroup
            autoSaveId={PANEL_AUTO_SAVE_ID}
            direction="horizontal"
            ref={ref}
          >
            {/* {#1 Control panel} */}
            {panelState[CVC_PANEL_ORDER_MAP.CONTROL] && (
              <>
                <SelectModelPanel />
                <SectionPanelResizeHandle />
              </>
            )}
            {(panelState[CVC_PANEL_ORDER_MAP.AUDIO_EDITOR] ||
              panelState[CVC_PANEL_ORDER_MAP.VOICE_FILES]) && (
              <>
                {/* {#2 Wrap Panel} */}
                <SectionPanel
                  order={CVC_PANEL_ORDER_MAP.AUDIO_FILE_WRAPPER}
                  style={{ marginTop: GRID_GAP, marginBottom: GRID_GAP }}
                >
                  <PanelGroup
                    autoSaveId={PANEL_AUTO_SAVE_ID}
                    direction="vertical"
                  >
                    {/* {#4 Audio panel} */}
                    {panelState[CVC_PANEL_ORDER_MAP.AUDIO_EDITOR] && (
                      <>
                        <UndoManagerProvider<EditData>>
                          <AudioEditorPanel />
                        </UndoManagerProvider>
                        <SectionPanelResizeHandle direction="vertical" />
                      </>
                    )}
                    {/* {#5 Voice Files attach & upload} */}
                    {panelState[CVC_PANEL_ORDER_MAP.VOICE_FILES] && (
                      <VoiceFilesPanel />
                    )}
                  </PanelGroup>
                </SectionPanel>
                <SectionPanelResizeHandle />
              </>
            )}
            {/* {#3 Result List} */}
            {panelState[CVC_PANEL_ORDER_MAP.RESULTS] && <ResultsPanel />}
          </PanelGroup>
        )}
        {isAllHide && <BrandWrapper />}
        {/* Confirm Modal */}
        <ConfirmModal
          isPortal={false}
          className="confirm-modal"
          {...confirmModalState}
          onConfirm={() => {
            confirmModalState.onConfirm?.();
            closeConfirmModal();
          }}
          onCancel={() => {
            confirmModalState.onCancel?.();
            closeConfirmModal();
          }}
        />
      </StyledCvcContent>
      {/* {CvcGuide area} */}
      <CvcGuide />
    </HotkeyManagerContextProvider>
  );
};

export default CvcPage;
