import styled from '@emotion/styled';

const StyledAudioEditorContent = styled.div`
  width: 100%;
  display: flex;
  flex: auto;
  margin-bottom: 3.75rem;
  overflow: hidden;
  .sup-audio-controller {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1.13rem;
  }
`;

export default StyledAudioEditorContent;
