import { ListItemData } from '@/components/List/types';
import { CvcTargetFileInfo } from '@/stores/cvc';
import { CSSProperties } from 'react';

import MergeGroupItem from './MergeGroupItem';
import TargetFileListItem from './TargetFileListItem';
import TargetGroupItem from './TargetGroupItem';

const TargetItemList: React.FC<{
  index: number;
  style: CSSProperties;
  data: ListItemData;
}> = ({ index, style, data }) => {
  const item = data.list[index] as CvcTargetFileInfo;
  // [workaround] nth-child even 사용시 vsScroll 동작과 충돌로 스크롤시 색이 일시적으로 섞이는 현상이 생김
  if (item.isGroup) {
    return <TargetGroupItem index={index} style={style} data={data} />;
  }
  return item.isMergeGroup ? (
    <MergeGroupItem index={index} style={style} data={data} />
  ) : (
    <TargetFileListItem index={index} style={style} data={data} />
  );
};
export default TargetItemList;
