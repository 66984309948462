import { ReactComponent as DeleteIcon } from '@/components/assets/icons/DeleteIcon.svg';
import { ReactComponent as DownloadIcon } from '@/components/assets/icons/DownloadIcon.svg';
import IconButton from '@/components/Button/IconButton';
import useVoiceFileList from '@/hooks/useVoiceFileList';
import {
  MssExtendFileInfo,
  mssResultFileGroupBySourceModel,
  mssResultFileListModel,
  selectedMssResultFileListSelector,
} from '@/stores/mss';
import { mssProcessingStatesModel } from '@/stores/resource';
import { White } from '@/styles/Colors';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import useDownload from '../cvc/hooks/useDownload';

const MssResultListControl = () => {
  const { t } = useTranslation();
  const resultList = useRecoilValue(mssResultFileListModel);
  const setResultList = useSetRecoilState(mssResultFileListModel);
  const { deleteFiles } = useVoiceFileList(resultList, setResultList);
  const selectedList = useRecoilValue(selectedMssResultFileListSelector).filter(
    (item) => !item.isGroup
  );
  const counter = useMemo(() => {
    const total = resultList.filter((file) => !file.isGroup).length;
    return `${
      selectedList.length ? selectedList.length + ' / ' : ''
    }${total} File${total > 1 ? 's' : ''}`;
  }, [selectedList, resultList]);

  const selectedResultList = useRecoilValue(selectedMssResultFileListSelector);
  const setMssResultFileGroupBySource = useSetRecoilState(
    mssResultFileGroupBySourceModel
  );

  const processingMssStates = useRecoilValue(mssProcessingStatesModel);
  const deleteMssResultFile = useCallback(() => {
    setMssResultFileGroupBySource((pre) => {
      const newFileList = pre.map((group) => {
        return {
          ...group,
          files: group.files.filter(
            (id) => !selectedResultList.find((file) => file.id === id)
          ),
        };
      });
      return newFileList.filter((group) => !!group.files.length);
    });
    deleteFiles(processingMssStates);
  }, [
    deleteFiles,
    selectedResultList,
    setMssResultFileGroupBySource,
    processingMssStates,
  ]);

  const { onDownload } = useDownload();
  const onClickDownload = useCallback(async () => {
    const items = (await onDownload(selectedList)) as MssExtendFileInfo[];
    setResultList((prev) => {
      return prev.map((item) => {
        const newItem = items.find((v) => v.id === item.id);
        return {
          ...item,
          originalUrl: newItem?.originalUrl,
          transcodedUrl: newItem?.transcodedUrl,
        };
      });
    });
  }, [setResultList, onDownload, selectedList]);

  return (
    <section
      className={classNames(
        'sup-file-info',
        !!selectedList.length && 'checked'
      )}
    >
      <span className="status">
        <span>{counter}</span>
      </span>
      <span className="buttons">
        <IconButton
          color={White}
          size="lg"
          variant="none"
          onClick={deleteMssResultFile}
          tooltip={t('Delete Selections')}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          color={White}
          size="lg"
          variant="none"
          tooltip={t('Download Selections')}
          onClick={onClickDownload}
        >
          <DownloadIcon />
        </IconButton>
      </span>
    </section>
  );
};
export default MssResultListControl;
