import { BasicFontSize } from '../../styles/Typography';

// X Axis Height
export const X_AXIS_HEIGHT = Math.round(1.5 * BasicFontSize);

// The minimum number of samples to draw a wave
export const MIN_WAVE_SAMPLES = 2;

export const MARKER_SIZE = {
  width: 7,
  height: 4,
};

// Indicator Union이 에디터 영역에 넘쳐서(overflow) 위치해야 해서 viewport 너비를 indicator union 크기만큼 설정
export const VIEWPORT_PADDING = {
  top: MARKER_SIZE.height,
  right: MARKER_SIZE.width / 2,
  bottom: 0,
  left: MARKER_SIZE.width / 2,
};

// Waveform border width
export const BORDER_WIDTH = 1;

// Editor Offset
export const OFFSET_SIZE = {
  top: Math.round(3.25 * BasicFontSize),
  left: Math.round(1.75 * BasicFontSize),
  right: Math.round(1.75 * BasicFontSize),
  bottom: Math.round(2.2 * BasicFontSize),
};

// Width of the loop select handle
export const SELECT_HANDLE_WIDTH = 6;
export const SELECT_HANDLE_CLASS = {
  left: 'editor-loop-handle-start',
  right: 'editor-loop-handle-end',
};

export const MIN_DRAG_WIDTH = 5;

export const DEFAULT_Y_SCALE_INFO_MIN: [number, number] = [-0.1, 0.1];
export const DEFAULT_Y_SCALE_INFO_MAX: [number, number] = [-1, 1];
export const DEFAULT_Y_SCALE_STEP = 0.1;
