import { Grey, White } from '@/styles/Colors';
import { resampleAudioData } from '@/util/audio';
import classNames from 'classnames';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { area, line } from 'd3-shape';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { DEFAULT_Y_SCALE_INFO_MAX } from '../const';
import { PathData, Size } from '../types';

interface WaveProps {
  channelData?: Float32Array;
  size: Size;
  translate?: { x: number; y: number };
  showClipPath?: boolean;
  clipPathId?: string;
  yScaleInfo?: [number, number];
}

const Wave = ({
  channelData,
  size,
  translate = { x: 0, y: 0 },
  showClipPath = true,
  clipPathId,
  yScaleInfo,
}: WaveProps) => {
  const waveRef = useRef<SVGGElement>(null);
  const worker: Worker = useMemo(
    () => new Worker(new URL('./resample.worker.ts', import.meta.url)),
    []
  );

  const drawWave = useCallback(
    (resampledData: PathData) => {
      if (!waveRef.current || !resampledData) return;

      const wave = waveRef.current;
      const wrapper = select(wave);
      const path = wrapper.select<SVGPathElement>('.wave-path');
      const clipPath = wrapper.select<SVGPathElement>('.wave-clip-path');
      const zeroLine = wrapper.select<SVGLineElement>('line');

      const { width, height } = size;
      const xScale = scaleLinear()
        .domain([0, resampledData.buffer.length - 1])
        .range([0, width]);

      const [min, max] = yScaleInfo
        ? [yScaleInfo[0], yScaleInfo[1]]
        : [DEFAULT_Y_SCALE_INFO_MAX[0], DEFAULT_Y_SCALE_INFO_MAX[1]];
      const yScale = scaleLinear().domain([min, max]).range([0, height]);

      // Draw zero value line
      zeroLine
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', yScale(0))
        .attr('y2', yScale(0));

      if (resampledData.type === 'line') {
        const lineGenerator = line<number>()
          .x((_, i) => xScale(i))
          .y((d) => {
            if (d > max) return yScale(max);
            return yScale(d);
          });

        path
          .datum(resampledData.buffer)
          .attr('d', lineGenerator)
          .attr('fill', 'none')
          .attr('stroke', Grey[800])
          .attr('stroke-width', 1);

        clipPath
          .datum(resampledData.buffer)
          .attr('d', lineGenerator)
          .attr('fill', 'none')
          .attr('stroke', White)
          .attr('stroke-width', 1);
      } else {
        const areaGenerator = area<number[]>()
          .x((_, i) => xScale(i))
          .y0((d) => {
            if (d[0] < min) return yScale(min);
            return yScale(d[0]);
          })
          .y1((d) => {
            if (d[1] > max) return yScale(max);
            return yScale(d[1]);
          });

        path
          .datum(resampledData.buffer)
          .attr('d', areaGenerator)
          .attr('fill', Grey[800])
          .attr('stroke', Grey[800])
          .attr('stroke-width', 1);

        clipPath
          .datum(resampledData.buffer)
          .attr('d', areaGenerator)
          .attr('fill', White)
          .attr('stroke', White)
          .attr('stroke-width', 1);
      }
    },
    [size, yScaleInfo]
  );

  useEffect(() => {
    if (!size.width || !size.height || !channelData) return;
    // Worker를 통해 resample된 데이터를 받아와서 wave를 그림
    worker.onmessage = ({ data }) => {
      // Worker에서 에러가 발생한 경우 worker를 종료하고 resample 계산(main thread) 후 wave를 그림
      if (data.error) {
        console.error(data.error);
        worker?.terminate();
        if (!channelData) return;
        const resampledData = resampleAudioData(channelData, size.width);
        drawWave(resampledData);
      } else {
        drawWave(data);
      }
    };
    worker.postMessage({ channelData, width: size.width });
  }, [channelData, size.width, size.height, worker, drawWave, yScaleInfo]);

  return (
    <g
      className="editor-wave"
      ref={waveRef}
      transform={`translate(${translate.x}, ${translate.y})`}
      clipPath={`inset(0 0 0 0)`}
    >
      {/* zero guide line */}
      <line className="wave-zero" />
      {/* Wave path */}
      <path className="wave-path" />
      {/* Wave path for clip-path (Selected area) */}
      <path
        className={classNames(
          'wave-clip-path',
          (!showClipPath || !clipPathId) && 'hide'
        )}
        clipPath={`url(#${clipPathId})`}
      />
    </g>
  );
};

export default Wave;
