import { ReactComponent as ExpandIcon } from '@/components/assets/icons/ExpandIcon.svg';
import { ReactComponent as ShrinkIcon } from '@/components/assets/icons/ShrinkIcon.svg';
import IconButton from '@/components/Button/IconButton';
import { White } from '@/styles/Colors';
import { useId, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from '../Checkbox/Checkbox';
import { ListItem } from './types';

interface ListHeaderProps {
  // list data
  list: ListItem[];
  // all item expanded
  expandedAll?: boolean;
  // checked All file
  checkedAll?: boolean;
  // toggle all item checked
  checkAll?: () => void;
  // toggle all item expanded
  expandAll?: () => void;
  // list header captions
  headers: string[];
  // header has all check flag
  enableAllCheck?: boolean;
}
const VoiceFileGridHeader: React.FC<ListHeaderProps> = ({
  list,
  expandedAll,
  checkAll,
  expandAll,
  headers,
  checkedAll,
  enableAllCheck,
}) => {
  const { t } = useTranslation();
  const headerId = useId();
  const checkedAllListItem = useMemo(
    () => checkedAll ?? list.every((item) => item.isChecked),
    [list, checkedAll]
  );
  return (
    <section className="sup-file-header">
      <span>
        {enableAllCheck && (
          <Checkbox
            checked={checkedAllListItem}
            onChange={() => checkAll?.()}
          />
        )}
      </span>
      {headers.map((header) => (
        <span key={headerId + header}>
          <span>{t(header)}</span>
        </span>
      ))}
      {expandedAll !== undefined && (
        <span>
          <IconButton
            color={White}
            size="lg"
            variant="none"
            onClick={() => expandAll?.()}
          >
            {expandedAll ? <ShrinkIcon /> : <ExpandIcon />}
          </IconButton>
        </span>
      )}
    </section>
  );
};

export default VoiceFileGridHeader;
