import { RESULT_TOGGLE_DEFAULT_VALUE } from '@/consts';
import { MSSControlType, MSS_DESCRIPTION } from '@/consts/mss';
import {
  MssDereverbType,
  MssModelType,
} from '@/pages/mss/SeparateControl/config';
import { atom, selector } from 'recoil';

import {
  Description,
  ExtendFileInfo,
  FileInfo,
  FileItemToggleModel,
} from './cvc';
import { mssProcessingStatesModel, mssUploadingStatesModel } from './resource';

export interface MssExtendFileInfo extends ExtendFileInfo {
  type?: MSSControlType;
  isGroup?: boolean;
}

export const mssSourceFileListModel = atom<FileInfo[]>({
  key: 'MssSourceFileListModel',
  default: [],
});

export const mssSourceFileToggleModel = atom<FileItemToggleModel>({
  key: 'MssSourceFileToggleModel',
  default: {},
});

export const mssSourceFileListSelector = selector<ExtendFileInfo[]>({
  key: 'MssSourceFileListSelector',
  get: ({ get }) => {
    const list = get(mssSourceFileListModel);
    const toggleList = get(mssSourceFileToggleModel);
    return list.map((item) => ({
      ...item,
      isFetching: get(mssUploadingStatesModel)[item.id] !== 'COMPLETE',
      fetchStatus: get(mssUploadingStatesModel)[item.id],
      isExpanded: toggleList[item.id],
    }));
  },
});

export const selectedMssSourceFileListSelector = selector<ExtendFileInfo[]>({
  key: 'SelectedMssSourceFileListSelector',
  get: ({ get }) => {
    const list = get(mssSourceFileListSelector);
    return list.filter((item) => item.isChecked && !item.isFetching);
  },
});

export const mssResultFileListModel = atom<MssExtendFileInfo[]>({
  key: 'MssResultFileListModel',
  default: [],
});

export const selectedMssResultFileListSelector = selector<MssExtendFileInfo[]>({
  key: 'dMssResultFileListSelector',
  get: ({ get }) => {
    const list = get(mssResultFileListModel);
    return list.filter((item) => item.isChecked);
  },
});

export const mssResultFileToggleModel = atom<FileItemToggleModel>({
  key: 'MssResultFileToggleModel',
  default: {},
});

export interface ResultGroupInfo {
  id: string;
  source: string;
  files: string[];
  isChecked: boolean;
  isExpanded: boolean;
}
export const mssResultFileGroupBySourceModel = atom<ResultGroupInfo[]>({
  key: 'MssResultFileGroupBySource',
  default: [],
});

export const mssResultFileListSelector = selector<MssExtendFileInfo[]>({
  key: 'MssResultFileListSelector',
  get: ({ get }) => {
    const list = get(mssResultFileListModel);
    const toggleList = get(mssResultFileToggleModel);
    const processing = get(mssProcessingStatesModel);
    let result = list.map((item) => ({
      ...item,
      isFetching: processing[item.id] !== 'COMPLETE',
      fetchStatus: processing[item.id],
      isExpanded:
        processing[item.id] === 'COMPLETE' &&
        (toggleList[item.id] ?? RESULT_TOGGLE_DEFAULT_VALUE),
    })) as MssExtendFileInfo[];
    const groupBySource = get(mssResultFileGroupBySourceModel);
    let groupResult = [] as MssExtendFileInfo[];
    groupBySource.forEach((group) => {
      const groupItem = {
        isGroup: true,
        isChecked: group.files.every(
          (id) => list.find((item) => item.id === id)?.isChecked
        ),
        isExpanded: group.isExpanded,
        id: group.id,
        name: group.source,
      } as MssExtendFileInfo;
      groupResult.push(groupItem);
      if (group.isExpanded) {
        groupResult = groupResult.concat(
          result.filter((item) => group.files.includes(item.id))
        );
      }
    });
    return groupResult;
  },
});

export const mssHelpDescriptionModel = atom<Description>({
  key: 'mss/helpDescriptionModel',
  default: {} as Description,
});

export const mssDescriptionSelector = selector<Description>({
  key: 'mss/descriptionSelector',
  get: ({ get }) => {
    const helpDescription = get(mssHelpDescriptionModel);
    return {
      ...helpDescription,
      main: helpDescription.main ?? MSS_DESCRIPTION,
    };
  },
});

export const mssSeparateControlDisableModel = atom<
  Record<MssModelType, boolean>
>({
  key: 'mss/separateControlDiableModel',
  default: {} as Record<MssModelType, boolean>,
});

export const mssDereverbModel = atom<MssDereverbType>({
  key: 'mss/dereverbEnableModel',
  default: 'dry',
});
