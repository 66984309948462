import { getUploadInfo, upload } from '@/api';
import { CardUIContent } from '@/components/CardUI';
import List from '@/components/List/List';
import { ListItem } from '@/components/List/types';
import { AUDIO_FILE_ACCEPT, FILE_LIST_HEADERS } from '@/consts';
import useUploadEvents, { ResourceInfo } from '@/hooks/useUploadEvents';
import useVoiceFileList from '@/hooks/useVoiceFileList';
import {
  ExtendFileInfo,
  resultFileListSelector,
  sourceFileListModel,
  sourceFileListSelector,
  sourceFileListToggleModel,
} from '@/stores/cvc';
import { sourceUploadingStatesModel } from '@/stores/resource';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import DropZone from '../../../components/FileInput/DropZone';
import useItemHeight from '../../../hooks/useItemHeight';
import useToggleItem from '../../../hooks/useToggleItem';
import SourceFileListItem from '../../../ListItem/SourceFileListItem';
import { DRAG_FROM_RESULT, DRAG_ITEM_KEY } from '../config';
import useAsSource from '../hooks/useAsSource';
import SourceListControl from './SourceListControl';
import SourcePanelHeader from './SourcePanelHeader';

const SourcePanel: React.FC<{ resizeDep: number }> = ({ resizeDep }) => {
  const setSourceList = useSetRecoilState(sourceFileListModel);
  const [uploadingStatus, setUploadingStatus] = useRecoilState(
    sourceUploadingStatesModel
  );
  const completeUpload = useCallback(
    (data: ResourceInfo, resource_id: string) => {
      setSourceList((prev) => {
        return prev.map((item) => {
          if (item.id === resource_id) {
            return { ...item, ...data };
          }
          return item;
        });
      });
    },
    [setSourceList]
  );
  const addFiles = useCallback(
    async (sessionId: string, files: File[]) => {
      let resources = [] as ExtendFileInfo[];
      const resourceResponses = await Promise.all(
        files.map((file) => {
          return getUploadInfo(sessionId, file.name, file.type);
        })
      );
      for (let i = 0; i < resourceResponses.length; i++) {
        const resourceData = resourceResponses[i].data.data;
        const newFiles = {
          name: files[i].name,
          file: files[i],
          id: resourceData.resource_id,
          isChecked: true,
          upload_url: resourceData.upload_url,
        } as ExtendFileInfo;
        resources.push(newFiles);
      }
      setUploadingStatus((prev) => {
        const status = { ...prev };
        resources.forEach((file) => {
          status[file.id] = 'READY';
        });
        return status;
      });
      setSourceList((list = []) => resources.concat(list) as ExtendFileInfo[]);
      for (let i = 0; i < resources.length; i++) {
        await upload(resources[i].upload_url as string, files[i]);
      }
    },
    [setSourceList, setUploadingStatus]
  );
  const { uploadFile } = useUploadEvents(
    uploadingStatus,
    setUploadingStatus,
    completeUpload,
    addFiles
  );

  const sourceList = useRecoilValue(sourceFileListSelector);
  // File list Control
  const { checkAll, setCheckItem, changeName, updateFile } = useVoiceFileList(
    sourceList,
    setSourceList
  );
  // Toggle File List Item
  const setToggleList = useSetRecoilState(sourceFileListToggleModel);
  const { expandAll, toggleItem, expandedAll } = useToggleItem(
    sourceList,
    setToggleList
  );
  // For virtual scroll size
  const { getItemHeight } = useItemHeight(sourceList);
  const { t } = useTranslation();

  const resultList = useRecoilValue(resultFileListSelector);
  const asSource = useAsSource();
  const onDrop = useCallback(
    (e: React.DragEvent<HTMLElement>) => {
      const target = e.dataTransfer?.getData(DRAG_ITEM_KEY);
      if (target) {
        const { id, from } = JSON.parse(target);
        if (from !== DRAG_FROM_RESULT) {
          return;
        }
        const dragTarget = resultList.find((item) => item.id === id);
        dragTarget && asSource(dragTarget);
      }
    },
    [asSource, resultList]
  );

  return (
    <>
      <SourcePanelHeader addFiles={uploadFile} />
      <CardUIContent className="sup-files black">
        <DropZone
          dargKey={DRAG_ITEM_KEY}
          onDrop={onDrop}
          addFiles={uploadFile}
          accept={AUDIO_FILE_ACCEPT}
        >
          <List
            control={<SourceListControl />}
            enableAllCheck={true}
            headers={FILE_LIST_HEADERS}
            list={sourceList as ListItem[]}
            checkAll={checkAll}
            expandAll={expandAll}
            expandedAll={expandedAll}
            ListItem={SourceFileListItem}
            setToggleItem={toggleItem}
            setCheckItem={setCheckItem}
            resizeDep={resizeDep}
            getItemSize={getItemHeight}
            emptyMessage={t('No imported audio files yet.') as string}
            changeName={changeName}
            type="Source"
            updateFile={updateFile}
          />
        </DropZone>
      </CardUIContent>
    </>
  );
};
export default SourcePanel;
