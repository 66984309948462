import {
  panelDefaultSizeModel,
  selectedVoiceFilePanelModel,
} from '@/stores//panels';
import classNames from 'classnames';
import { FC, Fragment, useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { ReactComponent as RightArrowIcon } from '../../components/assets/icons/RightArrowIcon.svg';
import { CVCVoiceFilePanelTypes, CVC_PANEL_ORDER_MAP } from '../../consts/cvc';
import { SectionPanel } from '../../layout/SectionPanel';
import { White } from '../../styles/Colors';
import { VOICE_FILES_PANEL_HEIGHT_MIN } from './config';
import GeneratePanel from './GeneratePanel/GeneratePanel';
import useCvcModelState from './hooks/useCvcModelState';
import useIsHighlightPanel from './hooks/useIsHighlightPanel';
import SourcePanel from './SourcePanel/SourcePanel';
import StyledVoiceFilesContent from './styled/StyledVoiceFileContent';
import TargetPanel from './TargetPanel/TargetPanel';
import VoiceFileInnerPanel from './VoiceFileInnerPanel';
import VoiceFilesPanelHeader from './VoiceFilesPanelHeader';

const VoiceFileComponentMap: Record<
  CVCVoiceFilePanelTypes,
  FC<{ resizeDep: number }>
> = {
  Source: SourcePanel,
  Target: TargetPanel,
  Generate: GeneratePanel,
};
const VoiceFilesPanel = () => {
  const resetSelectedPanel = useResetRecoilState(selectedVoiceFilePanelModel);
  const isHighlight = useIsHighlightPanel('VoiceFiles');
  const panelDefaultSize = useRecoilValue(panelDefaultSizeModel);
  const { voiceFilePanelIds } = useCvcModelState();
  useEffect(() => {
    if (!isHighlight) {
      resetSelectedPanel();
    }
  }, [isHighlight, resetSelectedPanel]);

  const [ratio, setRatio] = useState(0);

  return (
    <SectionPanel
      panelId="VoiceFiles"
      order={CVC_PANEL_ORDER_MAP.VOICE_FILES}
      theme="transparent"
      title={<VoiceFilesPanelHeader isPanelActive={isHighlight} />}
      className={classNames('voice-files-panel', isHighlight && 'highlight')}
      defaultSize={panelDefaultSize[CVC_PANEL_ORDER_MAP.VOICE_FILES]}
      onResize={(ratio) => {
        setRatio(ratio);
      }}
      minSize={VOICE_FILES_PANEL_HEIGHT_MIN}
    >
      <StyledVoiceFilesContent className="voice-files-content">
        {voiceFilePanelIds.map((panelId) => {
          const VoiceFileComponent = VoiceFileComponentMap[panelId];
          return (
            <Fragment key={`voice_files_${panelId}`}>
              <VoiceFileInnerPanel panelId={panelId}>
                <VoiceFileComponent resizeDep={ratio} />
              </VoiceFileInnerPanel>
              {voiceFilePanelIds.length > 1 && panelId !== 'Generate' && (
                <section className="move-button" style={{ color: White }}>
                  <RightArrowIcon />
                </section>
              )}
            </Fragment>
          );
        })}
      </StyledVoiceFilesContent>
    </SectionPanel>
  );
};

export default VoiceFilesPanel;
