import { ExtendFileInfo } from '@/stores/cvc';
import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import { VariableSizeList } from 'react-window';

import useVirtualScroll from './hooks/useVirtualScroll';
import ListHeader from './ListHeader';
import StyledList from './styled/StyledList';
import { EditableListType, ListItem, ListItemData } from './types';

interface ListProps {
  // control Component
  control?: React.ReactNode;
  // captions for default header
  headers?: string[];
  // expanded all flag
  expandedAll?: boolean;
  // checked all flag
  checkedAll?: boolean;
  // check all item callback
  checkAll?: () => void;
  // expand all item callback
  expandAll?: () => void;
  // list data
  list: ListItem[];
  // toggle Item callback
  setToggleItem?: (id: string) => void;
  // check item callback
  setCheckItem: (id: string) => void;
  // resize dependency
  resizeDep?: number;
  // custom class name
  className?: string;
  // empty list message
  emptyMessage?: string;
  // get item size callback(It used by react-window VariableSizeList)
  getItemSize: (index: number) => number;
  // list item component(It defined by react-window VariableSizeList)
  ListItem: React.FC<{
    index: number;
    style: CSSProperties;
    data: ListItemData;
  }>;
  // enabled merge flag
  enableAllCheck?: boolean;
  // type of list
  type?: EditableListType;
  // file name change callback
  changeName?: (id: string, name: string) => void;
  // file update callback
  updateFile?: <E extends ExtendFileInfo>(id: string, fileInfo: E) => void;
}
const List: React.FC<ListProps> = ({
  control,
  headers,
  expandedAll,
  checkAll,
  checkedAll,
  expandAll,
  list,
  setToggleItem,
  setCheckItem,
  resizeDep,
  className,
  emptyMessage,
  getItemSize,
  ListItem,
  enableAllCheck,
  type,
  changeName,
  updateFile,
}) => {
  const isEmpty = list.length === 0;
  const { ref, width, height, listRef } = useVirtualScroll(list, resizeDep);
  return (
    <StyledList className={classNames(className)}>
      {isEmpty ? (
        <section className="sup-file-empty">
          <div className="empty-message">{emptyMessage || ''}</div>
        </section>
      ) : (
        <>
          {headers && (
            <>
              {control}
              <ListHeader
                list={list}
                expandedAll={expandedAll}
                checkedAll={checkedAll}
                checkAll={checkAll}
                expandAll={expandAll}
                headers={headers}
                enableAllCheck={enableAllCheck}
              />
            </>
          )}
          <section className="sup-file-list">
            <section
              className={classNames(
                'sup-file-list-inner',
                headers && 'has-header'
              )}
              ref={ref}
              style={{ width: width, height: height }}
            >
              <VariableSizeList
                innerElementType="ul"
                className="sup-file-v-scroll"
                outerRef={ref}
                ref={listRef}
                height={height}
                width={width}
                itemSize={getItemSize}
                itemCount={list?.length || 0}
                itemData={{
                  width: width,
                  setToggleItem,
                  setCheckItem,
                  changeName,
                  list: list,
                  type: type,
                  updateFile,
                }}
              >
                {ListItem}
              </VariableSizeList>
            </section>
          </section>
        </>
      )}
    </StyledList>
  );
};
export default List;
