import { postMssGenerate } from '@/api';
import { ReactComponent as GroupIcon } from '@/assets/icons/GroupIcon.svg';
import Button from '@/components/Button/Button';
import Switch from '@/components/Switch/Switch';
import ToggleButton from '@/components/ToggleButton/ToggleButton';
import useProcessingEvents from '@/hooks/useProcessingEvents';
import { SliderControl } from '@/pages/cvc/ModelControl/SliderControl';
import { WebSocketContext } from '@/providers/WebSocketProvider';
import { mssParameterValueModel } from '@/stores/models';
import {
  MssExtendFileInfo,
  ResultGroupInfo,
  mssDereverbModel,
  mssResultFileGroupBySourceModel,
  mssResultFileListModel,
  mssSeparateControlDisableModel,
  selectedMssSourceFileListSelector,
} from '@/stores/mss';
import { mssProcessingStatesModel } from '@/stores/resource';
import classNames from 'classnames';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  MSSControlParameter,
  MSSSliderParameter,
  MssDereverbType,
  controlParamList,
} from './config';

interface MssParameters {
  vocals_ratio?: number;
  accomp_ratio?: number;
  dry_ratio?: number;
  reverb_ratio?: number;
  noise_ratio?: number;
}
type DereverbOptionType = MssDereverbType & 'off';
const dereverbOptions = ['dry', 'reverb', 'noise', 'off'] as DereverbOptionType;

const SeparateControl = () => {
  const { t } = useTranslation();
  const [mssSeparateControlDisable, setMssSeparateControlDisable] =
    useRecoilState(mssSeparateControlDisableModel);
  const controls = useMemo(
    () =>
      controlParamList.map((c) => {
        return {
          ...c,
          disabled: mssSeparateControlDisable[c.params.model_type],
        };
      }),
    [mssSeparateControlDisable]
  );

  const handleChange = useCallback(
    (control: MSSControlParameter) => {
      setMssSeparateControlDisable((prev) => {
        return {
          ...prev,
          [control.params.model_type]: !prev[control.params.model_type],
        };
      });
    },
    [setMssSeparateControlDisable]
  );
  const selectedList = useRecoilValue(selectedMssSourceFileListSelector);
  const setMssResultList = useSetRecoilState(mssResultFileListModel);
  const [mssProcessingStatus, setMssProcessingStatus] = useRecoilState(
    mssProcessingStatesModel
  );
  useProcessingEvents(
    setMssResultList,
    mssProcessingStatus,
    setMssProcessingStatus
  );

  const [parameterValue, setParametersValue] = useRecoilState(
    mssParameterValueModel
  );
  const onChangeParameters = useCallback(
    (value: { [key: string]: number | boolean }) => {
      setParametersValue((pre) => {
        return {
          ...pre,
          ...(value as { [key: string]: number }),
        };
      });
    },
    [setParametersValue]
  );

  const updateJobIds = useCallback(
    (jobIds: string[], mssTypes: string[]) => {
      setMssProcessingStatus((prev) => {
        const newStatus = { ...prev };
        jobIds.forEach((job_id: string) => {
          newStatus[job_id] = 'READY';
        });
        return newStatus;
      });
      setMssResultList((prev) => {
        return jobIds
          .map(
            (job_id, index) =>
              ({
                id: job_id,
                type: mssTypes[index % mssTypes.length],
                isChecked: false,
              } as MssExtendFileInfo)
          )
          .concat(prev);
      });
    },
    [setMssProcessingStatus, setMssResultList]
  );
  const setResultFileGroupBySource = useSetRecoilState(
    mssResultFileGroupBySourceModel
  );
  const { sessionId } = useContext(WebSocketContext);
  const [dereverbType, setDereverbType] = useRecoilState(mssDereverbModel);

  // @see https://www.notion.so/supertone/MSS-SE-torchserve-API-request-ebc86ebcf83048728104a2ebf72970cc
  const generate = useCallback(async () => {
    const controlsMap = controls
      .filter((control) => !control.disabled)
      .map((control) => {
        const parameters = {} as MssParameters;
        const value =
          parameterValue[control.name] ?? (control.defaultValue as number);
        if (control.params.model_type === 'vocals') {
          parameters.vocals_ratio = value;
          if (value !== 1) {
            parameters.accomp_ratio = (10 - value * 10) / 10;
          }
        }
        if (control.params.model_type === 'accomp') {
          parameters.accomp_ratio = value;
          if (value !== 1) {
            parameters.vocals_ratio = (10 - value * 10) / 10;
          }
        }
        if (control.params.model_type === 'hifi_brunet') {
          parameters[`${dereverbType}_ratio`] = value;
        }
        return {
          ...control.params,
          parameters,
        };
      });

    const params = selectedList.reduce((acc: any, file: MssExtendFileInfo) => {
      return [
        ...acc,
        ...controlsMap.map((control) => {
          const param = { ...control } as { [key: string]: any };
          if (control.model_type === 'hifi_brunet') {
            if (Object.values(control.parameters).some((v) => v !== 1)) {
              param.out_type = 'custom';
            }
          } else {
            if (control.model_type === 'vocals') {
              if (control.parameters.accomp_ratio) {
                param.model_type = 'custom';
              }
            } else if (control.model_type === 'accomp') {
              if (control.parameters.vocals_ratio) {
                param.model_type = 'custom';
              }
            }
          }
          return {
            ...param,
            resource_id: file.id,
          };
        }),
      ];
    }, []);

    const job_ids = await postMssGenerate(sessionId, params);

    updateJobIds(
      job_ids,
      controlsMap.map((c) => c.model_type)
    );

    setResultFileGroupBySource((pre) => {
      let newGroup = [...pre];
      selectedList.forEach((file, index) => {
        const groupId = file.id + new Date().getTime().toString();
        const startIndex = index * controlsMap.length;
        newGroup = [
          {
            id: groupId,
            isExpanded: true,
            source: file.name,
            files: job_ids.slice(startIndex, startIndex + controlsMap.length),
          },
        ].concat(newGroup) as ResultGroupInfo[];
      });
      return newGroup;
    });
  }, [
    selectedList,
    controls,
    updateJobIds,
    sessionId,
    setResultFileGroupBySource,
    parameterValue,
    dereverbType,
  ]);

  const onChangeDereverb = useCallback(
    (option: { value: DereverbOptionType }) => {
      if (option.value === 'off') {
        return setMssSeparateControlDisable((prev) => {
          return { ...prev, hifi_brunet: true };
        });
      }
      setMssSeparateControlDisable((prev) => {
        return { ...prev, hifi_brunet: false };
      });
      setDereverbType(option.value as MssDereverbType);
    },
    [setDereverbType, setMssSeparateControlDisable]
  );

  return (
    <div className="sup-controls">
      {controls.map((control, idx) => (
        <div
          className={classNames('sup-control', control.disabled && 'disabled')}
          key={control.name}
        >
          <>
            <SliderControl
              value={parameterValue[control.name]}
              useRatio={control.params.model_type !== 'hifi_brunet'}
              parameter={control as MSSSliderParameter}
              onChange={onChangeParameters}
            />
            {control.params.model_type === 'hifi_brunet' ? (
              <section className="dereverb-control">
                <ToggleButton
                  options={dereverbOptions}
                  onChange={onChangeDereverb}
                />
              </section>
            ) : (
              <Switch
                className="mss-control-switch"
                checked={!control.disabled}
                onChange={() => handleChange(control)}
              />
            )}
          </>
        </div>
      ))}
      <Button
        startIcon={<GroupIcon />}
        className="mss-control-button"
        disabled={!selectedList.length || controls.every((c) => c.disabled)}
        onClick={generate}
      >
        {t('Separate Selection')}
      </Button>
    </div>
  );
};

export default SeparateControl;
