import { HTMLAttributes, PropsWithChildren, forwardRef } from 'react';

interface DropdownHeaderProps extends HTMLAttributes<HTMLDivElement> {}

const DropdownHeader = forwardRef<
  HTMLDivElement,
  PropsWithChildren<DropdownHeaderProps>
>(({ children, ...props }, ref) => {
  return (
    <div
      className="sup-dropdown-header"
      ref={ref}
      style={{ height: '5rem' }}
      {...props}
    >
      {children}
    </div>
  );
});

export default DropdownHeader;
