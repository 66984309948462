import { ReactComponent as AddIcon } from '@/components/assets/icons/AddIcon.svg';
import { ReactComponent as RemoveIcon } from '@/components/assets/icons/RemoveIcon.svg';
import IconButton from '@/components/Button/IconButton';
import Slider from '@/components/Slider/Slider';
import Title from '@/components/Title/Title';
import {
  interpolationRatioModel,
  selectedCvcTypeSelector,
} from '@/stores/models';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { DEFAULT_RATIO, TOTAL_RATIO } from '../../../consts/cvc';

const Ratio = () => {
  const [ratio, setRatio] = useRecoilState(interpolationRatioModel);
  const resetRatio = useResetRecoilState(interpolationRatioModel);
  const { t } = useTranslation();

  const ratioLabel = useMemo(() => {
    return `${TOTAL_RATIO - ratio}:${ratio}`;
  }, [ratio]);

  const cvcType = useRecoilValue(selectedCvcTypeSelector);

  // when model changed, reset ratio (from interpolation to interpolation case)
  useEffect(() => {
    return () => resetRatio();
  }, [resetRatio, cvcType]);

  return (
    <section className="ratio">
      <strong className="ratio-title">
        <Title size="sm">{t('Mix Ratio')}</Title>
      </strong>
      <section className="ratio-slider">
        <Slider
          defaultValue={DEFAULT_RATIO}
          value={ratio}
          onChange={setRatio}
          max={TOTAL_RATIO}
          maxLabel="B"
          min={0}
          step={1}
          showMarks={false}
          minLabel="A"
        />
      </section>
      <section className="ratio-stepper">
        <IconButton
          className="ratio-minus"
          onPointerDown={() => ratio > 0 && setRatio((prev) => prev - 1)}
          disabled={ratio === 0}
        >
          <RemoveIcon />
        </IconButton>
        <input className="ratio-input" value={ratioLabel} disabled={true} />
        <IconButton
          className="ratio-plus"
          onPointerDown={() =>
            ratio < TOTAL_RATIO && setRatio((prev) => prev + 1)
          }
          disabled={ratio === TOTAL_RATIO}
        >
          <AddIcon />
        </IconButton>
      </section>
    </section>
  );
};
export default Ratio;
