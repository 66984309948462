import { ReactComponent as PlusIcon } from '@/components/assets/icons/PlusIcon.svg';
import classNames from 'classnames';
import React from 'react';

import { StyledInputLabel } from './StyledInput';

let inputLastId = 0;
export interface InputProps {
  id?: string;
  addFiles: (files: File[]) => void;
  className?: string;
  caption?: string | null;
  accept?: string;
  isMultiple?: boolean;
}
const Input: React.FC<InputProps> = ({
  id = `input-file-${inputLastId++}`,
  addFiles,
  className,
  caption,
  accept,
  isMultiple = true,
}) => {
  return (
    <>
      <StyledInputLabel
        isSimple={!!caption}
        htmlFor={id}
        className={classNames('file-add-button', className)}
      >
        <PlusIcon />
        {caption}
      </StyledInputLabel>
      <input
        id={id}
        type="file"
        multiple={isMultiple}
        accept={accept}
        onChange={(e) => {
          addFiles(Array.prototype.slice.call(e.target.files));
        }}
      />
    </>
  );
};
export default Input;
