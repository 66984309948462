import { InputHTMLAttributes, PropsWithChildren, useId } from 'react';

import { StyledToggleButton } from './StyledToggleButton';
import { ToggleOption } from './types';
import { getToggleOptions } from './utils';

interface ToggleButtonProps<T extends string | number>
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'type' | 'id' | 'value' | 'name' | 'checked' | 'defaultChecked' | 'onChange'
  > {
  name?: string;
  options:
    | [T, T, ...T[]]
    | [ToggleOption<T>, ToggleOption<T>, ...ToggleOption<T>[]];
  value?: T;
  onChange?: (option: ToggleOption<T>) => void;
}

const ToggleButton = <T extends string | number>({
  name,
  options,
  value: selectedValue,
  onChange,
  ...props
}: PropsWithChildren<ToggleButtonProps<T>>) => {
  const uniqueName = useId();
  const nameAttribute = name ?? uniqueName;
  const toggleOptions = getToggleOptions(options);
  const defaultValue = toggleOptions[0].value;

  return (
    <StyledToggleButton>
      {toggleOptions.map((option) => {
        const { value, label } = option;
        const id = `${uniqueName}_${value}`;
        /**
         * NOTE: selectedValue가 있으면 controlled, 없으면 uncontrolled로 판단
         * controlled인 경우, checked attribute를 사용하고,
         * uncontrolled인 경우 defaultChecked attribute를 사용한다.
         */
        const checked = selectedValue ? value === selectedValue : undefined;
        const defaultChecked = selectedValue
          ? undefined
          : value === defaultValue;

        return (
          <label key={value} htmlFor={id}>
            <input
              type="radio"
              name={nameAttribute}
              id={id}
              value={value}
              onChange={() => onChange?.(option)}
              checked={checked}
              defaultChecked={defaultChecked}
              {...props}
            />
            {label}
          </label>
        );
      })}
    </StyledToggleButton>
  );
};

export default ToggleButton;
