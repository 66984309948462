import { getResourceInfo } from '@/api';
import { EXPIRES_PARAM } from '@/consts';
import {
  WS_EVENTS,
  WebSocketContext,
  conditionalCallback,
} from '@/providers/WebSocketProvider';
import { ExtendFileInfo } from '@/stores/cvc';
import { ResourceStatus } from '@/stores/resource';
import { fromNowBySecond } from '@/util/time';
import { getParam } from '@/util/url';
import { useContext, useEffect } from 'react';
import { SetterOrUpdater } from 'recoil';

const useProcessingEvents = <E extends ExtendFileInfo>(
  setResultFileList: SetterOrUpdater<E[]>,
  processingStatus: ResourceStatus,
  setProcessingStatus: SetterOrUpdater<ResourceStatus>
) => {
  const { on, off, sessionId } = useContext(WebSocketContext);
  useEffect(() => {
    if (!sessionId) return;
    const onPending = conditionalCallback(
      (data: any) => {
        setProcessingStatus((prev) => {
          const newStatus = { ...prev };
          newStatus[data.job_id] = 'PENDING';
          return newStatus;
        });
      },
      processingStatus,
      'job_id'
    );
    // todo processing action change
    const onProcessing = conditionalCallback(
      (data: any) => {
        setProcessingStatus((prev) => {
          const newStatus = { ...prev };
          newStatus[data.job_id] = 'PROCESSING';
          return newStatus;
        });
      },
      processingStatus,
      'job_id'
    );
    const onFailed = conditionalCallback(
      (data: any) => {
        setProcessingStatus((prev) => {
          const newStatus = { ...prev };
          newStatus[data.job_id] = 'FAILED';
          return newStatus;
        });
      },
      processingStatus,
      'job_id'
    );
    const onComplete = conditionalCallback(
      (data: any) => {
        getResourceInfo(data.resource_id).then((res) => {
          const { data: resData } = res.data;
          const originalExpires = getParam(resData.original.url, EXPIRES_PARAM);
          setResultFileList((prev) => {
            return prev.map((item) => {
              if (item.id === data.job_id) {
                return {
                  ...item,
                  name: resData.name,
                  resource_id: data.resource_id,
                  originalUrl: resData.original.url,
                  transcodedUrl: resData.transcoded[0].url,
                  duration: resData.original.metadata.props.duration,
                  isFetching: false,
                  originalExpires:
                    originalExpires && fromNowBySecond(originalExpires),
                };
              }
              return item;
            });
          });
          setProcessingStatus((prev) => {
            const newStatus = { ...prev };
            newStatus[data.job_id] = 'COMPLETE';
            return newStatus;
          });
        });
      },
      processingStatus,
      'job_id'
    );
    on(WS_EVENTS.JOB_PENDING, onPending);
    on(WS_EVENTS.JOB_PROCESSING, onProcessing);
    on(WS_EVENTS.JOB_FAILED, onFailed);
    on(WS_EVENTS.JOB_DONE, onComplete);
    return () => {
      off(WS_EVENTS.JOB_PENDING, onPending);
      off(WS_EVENTS.JOB_PROCESSING, onProcessing);
      off(WS_EVENTS.JOB_FAILED, onFailed);
      off(WS_EVENTS.JOB_DONE, onComplete);
    };
  }, [
    sessionId,
    processingStatus,
    setProcessingStatus,
    setResultFileList,
    on,
    off,
  ]);
};
export default useProcessingEvents;
