import { ReactComponent as DeleteIcon } from '@/components/assets/icons/DeleteIcon.svg';
import { ReactComponent as DownloadIcon } from '@/components/assets/icons/DownloadIcon.svg';
import IconButton from '@/components/Button/IconButton';
import useVoiceFileList from '@/hooks/useVoiceFileList';
import {
  ExtendFileInfo,
  selectedSourceFileListSelector,
  sourceFileListModel,
  sourceFileListSelector,
} from '@/stores/cvc';
import { sourceUploadingStatesModel } from '@/stores/resource';
import { White } from '@/styles/Colors';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import useDownload from '../hooks/useDownload';

const SourceListControl = () => {
  const { t } = useTranslation();
  const setSourceList = useSetRecoilState(sourceFileListModel);
  const sourceList = useRecoilValue(sourceFileListSelector);
  // File list Control
  const uploadingStates = useRecoilValue(sourceUploadingStatesModel);
  const { deleteFiles } = useVoiceFileList(sourceList, setSourceList);
  const onClickDelete = useCallback(() => {
    deleteFiles(uploadingStates, true);
  }, [deleteFiles, uploadingStates]);
  const selectedList = useRecoilValue(selectedSourceFileListSelector);
  const counter = useMemo(() => {
    const total = sourceList.filter((item) => !item.isFetching).length;
    return `${
      selectedList.length ? selectedList.length + ' / ' : ''
    }${total} File${total > 1 ? 's' : ''}`;
  }, [selectedList, sourceList]);
  const { onDownload } = useDownload();
  const onClickDownload = useCallback(async () => {
    const items = (await onDownload(selectedList)) as ExtendFileInfo[];
    setSourceList((prev) => {
      return prev.map((item) => {
        const newItem = items.find((v) => v.id === item.id);
        return {
          ...item,
          originalUrl: newItem?.originalUrl,
          transcodedUrl: newItem?.transcodedUrl,
        };
      });
    });
  }, [setSourceList, onDownload, selectedList]);
  return (
    <section
      className={classNames(
        'sup-file-info',
        !!selectedList.length && 'checked'
      )}
    >
      <span className="status">
        <span>{counter}</span>
      </span>
      <span className="buttons">
        <IconButton
          color={White}
          size="lg"
          variant="none"
          onClick={onClickDelete}
          tooltip={t('Delete Selections')}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          color={White}
          size="lg"
          variant="none"
          tooltip={t('Download Selections')}
          onClick={onClickDownload}
        >
          <DownloadIcon />
        </IconButton>
      </span>
    </section>
  );
};
export default SourceListControl;
