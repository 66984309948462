import {
  PanelResizeHandle,
  PanelResizeHandleProps,
} from 'react-resizable-panels';

interface SectionPanelResizeHandleProps extends PanelResizeHandleProps {
  direction?: 'horizontal' | 'vertical';
}
const SectionPanelResizeHandle: React.FC<SectionPanelResizeHandleProps> = (
  props
) => {
  return (
    <PanelResizeHandle
      {...props}
      tagName={'section'}
      style={{
        [props.direction === 'vertical' ? 'height' : 'width']: '6px',
        [props.direction === 'vertical' ? 'marginTop' : 'marginLeft']: '-2px',
        [props.direction === 'vertical' ? 'marginBottom' : 'marginRight']:
          '-2px',
        zIndex: 100,
      }}
    />
  );
};
export default SectionPanelResizeHandle;
