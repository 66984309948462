import { LoopRange } from '../useAudioController';

export interface EditData {
  loopRange: LoopRange | null;
  audioBufferId?: string;
}

const setAudioEditSnapshot = (data: EditData) => data;

export default setAudioEditSnapshot;
