import classNames from 'classnames';
import { isValidElement } from 'react';

import { Primary } from '../../styles/Colors';
import { ReactComponent as BackwardIcon } from '../assets/icons/BackwardIcon.svg';
import { ReactComponent as ForwardIcon } from '../assets/icons/ForwardIcon.svg';
import { ReactComponent as PauseIcon } from '../assets/icons/PauseIcon.svg';
import { ReactComponent as PlayIcon } from '../assets/icons/PlayIcon.svg';
import { ReactComponent as RepeatIcon } from '../assets/icons/RepeatIcon.svg';
import { ReactComponent as StopIcon } from '../assets/icons/StopIcon.svg';
import IconButton from '../Button/IconButton';
import { ControlAction, ControlButtonProps } from './types';

interface ControlItemProps {
  action: ControlAction;
}

const ControlIcon = ({ action }: ControlItemProps) => {
  switch (action) {
    case 'stop':
      return <StopIcon className="sup-icon-stop" />;
    case 'play':
      return <PlayIcon className="sup-icon-play" />;
    case 'pause':
      return <PauseIcon className="sup-icon-pause" />;
    case 'repeat':
      return <RepeatIcon className="sup-icon-repeat" />;
    case 'backward':
      return <BackwardIcon className="sup-icon-backward" />;
    case 'forward':
      return <ForwardIcon className="sup-icon-forward" />;
    default:
      return null;
  }
};

export const ControlButton = ({
  isControllable = false,
  action,
  color,
  disabled,
  onClick,
  isActive,
  icon,
}: ControlButtonProps) => (
  <IconButton
    variant="none"
    color={color || Primary[400]}
    disabled={!isControllable || disabled}
    onKeyDown={(e) => {
      if (e.code === 'Space') {
        e.preventDefault();
      }
    }}
    onClick={onClick}
    className={classNames({
      active: isActive,
    })}
  >
    {isValidElement(icon) ? icon : <ControlIcon action={action} />}
  </IconButton>
);
