import { ConfirmModalState, confirmModalState } from '@/stores/cvc';
import { useRecoilState } from 'recoil';

const useConfirmModal = () => {
  const [modalState, setModalState] = useRecoilState(confirmModalState);

  const openModal = (config: Omit<ConfirmModalState, 'isOpen'>) => {
    setModalState({
      isOpen: true,
      ...config,
    });
  };

  const closeModal = () => {
    setModalState({
      isOpen: false,
    });
  };

  return {
    openConfirmModal: openModal,
    closeConfirmModal: closeModal,
    confirmModalState: modalState,
  };
};

export default useConfirmModal;
