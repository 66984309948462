import { ReactComponent as BottomArrowIcon } from '@/components/assets/icons/BottomArrowIcon.svg';
import IconButton from '@/components/Button/IconButton';
import Checkbox from '@/components/Checkbox/Checkbox';
import { ListItemData } from '@/components/List/types';
import { ExtendFileInfo } from '@/stores/cvc';
import {
  mssResultFileGroupBySourceModel,
  mssResultFileListModel,
  selectedMssResultFileListSelector,
} from '@/stores/mss';
import { Black, Primary } from '@/styles/Colors';
import classNames from 'classnames';
import { CSSProperties, useMemo } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

const MssGroupItem: React.FC<{
  index: number;
  style: CSSProperties;
  data: ListItemData;
}> = ({ index, style, data }) => {
  const { list } = data;
  const item = list[index] as ExtendFileInfo;

  const resultGroupMap = useRecoilValue(mssResultFileGroupBySourceModel);
  const resultFileList = useRecoilValue(selectedMssResultFileListSelector);
  const checkedAll = useMemo(() => {
    const group = resultGroupMap.find(({ id }) => id === item.id);
    return (
      group?.isChecked ??
      group?.files.every((id) => {
        const file = resultFileList.find((item) => item.id === id);
        return file?.isChecked;
      })
    );
  }, [resultGroupMap, resultFileList, item]);
  const checkAllGroup = useRecoilCallback(
    ({ set }) =>
      async (groupId: string) => {
        const group = resultGroupMap.find((item) => item.id === groupId);
        set(mssResultFileListModel, (prev) => {
          const newList = [...prev];
          return newList.map((item) => {
            if (group?.files.includes(item.id)) {
              return {
                ...item,
                isChecked: !checkedAll,
              };
            }
            return item;
          });
        });

        set(mssResultFileGroupBySourceModel, (prev) => {
          const newResult = [...prev];
          const groupIndex = resultGroupMap.findIndex(
            (item) => item.id === groupId
          );
          const group = resultGroupMap[groupIndex];
          newResult[groupIndex] = {
            ...group,
            isChecked: !checkedAll,
          };
          return newResult;
        });
      },
    [item.id, resultGroupMap, checkedAll]
  );

  const toggleGroup = useRecoilCallback(
    ({ set }) =>
      () => {
        set(mssResultFileGroupBySourceModel, (prev) => {
          const newResult = [...prev];
          const groupIndex = resultGroupMap.findIndex(
            ({ id }) => id === item.id
          );
          const group = resultGroupMap[groupIndex];
          newResult[groupIndex] = {
            ...group,
            isExpanded: !group.isExpanded,
          };
          return newResult;
        });
      },
    [resultGroupMap, item]
  );

  return (
    <li
      style={style}
      className={classNames(
        'sup-file-list-item',
        'mss-group',
        item.isChecked && 'selected'
      )}
    >
      <section className="inner">
        <span>
          <span className="checked">
            <Checkbox
              className="mss-result-check"
              color={item.isChecked ? Primary[400] : Black}
              checked={item.isChecked}
              onChange={() => checkAllGroup(item.id)}
            />
          </span>
          <span className="group-name">{item.name}</span>
        </span>
      </section>
      <span className="mss-group-toggle">
        <IconButton
          color="transparent"
          onClick={toggleGroup}
          style={item.isExpanded ? { transform: `rotate(180deg)` } : {}}
        >
          <BottomArrowIcon />
        </IconButton>
      </span>
    </li>
  );
};
export default MssGroupItem;
