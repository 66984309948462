import { ToggleOption } from './types';

export const getToggleOptions = <T extends string | number>(
  options: (T | ToggleOption<T>)[]
) => {
  return options.map((option) => {
    if (typeof option === 'object') return option;

    return {
      label: `${option}`,
      value: option,
    };
  });
};
