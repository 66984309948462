import { ReactComponent as ApplyParametersIcon } from '@/assets/icons/ApplyParametersIcon.svg';
import { ReactComponent as UseAsSourceIcon } from '@/assets/icons/UseAsSourceIcon.svg';
import { ReactComponent as DeleteIcon } from '@/components/assets/icons/DeleteIcon.svg';
import { ReactComponent as DownloadIcon } from '@/components/assets/icons/DownloadIcon.svg';
import IconButton from '@/components/Button/IconButton';
import useVoiceFileList from '@/hooks/useVoiceFileList';
import {
  CvCResultFileInfo,
  resultFileListModel,
  resultFileListSelector,
  resultParametersMapModel,
  selectedResultFileSelector,
} from '@/stores/cvc';
import { parametersValueModel, selectedCvcTypeSelector } from '@/stores/models';
import {
  cvcProcessingStatesModel,
  resultUploadingStatesModel,
} from '@/stores/resource';
import { White } from '@/styles/Colors';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import useAsSource from '../hooks/useAsSource';
import useDownload from '../hooks/useDownload';
import { ModelType } from '../ModelControl/config';

const ResultListControl = () => {
  const { t } = useTranslation();
  const resultList = useRecoilValue(resultFileListSelector);
  const setResultList = useSetRecoilState(resultFileListModel);
  const { deleteFiles } = useVoiceFileList(resultList, setResultList);
  const selectedList = useRecoilValue(selectedResultFileSelector);

  const asSource = useAsSource();
  const useAsSourceCallBack = useCallback(() => {
    asSource(selectedList[0]);
  }, [asSource, selectedList]);

  const counter = useMemo(() => {
    const total = resultList.filter((item) => !item.isFetching).length;
    return `${
      selectedList.length ? selectedList.length + ' / ' : ''
    }${total} File${total > 1 ? 's' : ''}`;
  }, [selectedList, resultList]);

  const setResultParametersMap = useSetRecoilState(resultParametersMapModel);
  const fileProcessingStates = useRecoilValue(cvcProcessingStatesModel);
  const fileUploadingStates = useRecoilValue(resultUploadingStatesModel);
  const onDelete = useCallback(() => {
    deleteFiles({ ...fileProcessingStates, ...fileUploadingStates }, true);
    setResultParametersMap((pre) => {
      const newMap = { ...pre };
      selectedList.forEach((file) => {
        delete newMap[file.id];
      });
      return newMap;
    });
    setResultList((pre) =>
      pre.filter((file) => !selectedList.find((item) => item.id === file.id))
    );
  }, [
    deleteFiles,
    selectedList,
    setResultList,
    setResultParametersMap,
    fileProcessingStates,
    fileUploadingStates,
  ]);

  const resultParametersMap = useRecoilValue(resultParametersMapModel);
  const setParametersValue = useSetRecoilState(parametersValueModel);
  const setSelectedCvcType = useSetRecoilState(selectedCvcTypeSelector);
  const applyParams = useCallback(() => {
    let model = resultParametersMap[selectedList[0].id] || {};
    setSelectedCvcType(model.name as ModelType);
    // [workaround] defer selected model changed reset default
    setTimeout(() => setParametersValue(model.params), 0);
  }, [
    selectedList,
    resultParametersMap,
    setParametersValue,
    setSelectedCvcType,
  ]);
  const { onDownload } = useDownload();
  const onClickDownload = useCallback(async () => {
    const items = (await onDownload(selectedList)) as CvCResultFileInfo[];
    setResultList((prev) => {
      return prev.map((item) => {
        const newItem = items.find((v) => v.id === item.id);
        return {
          ...item,
          originalUrl: newItem?.originalUrl,
          transcodedUrl: newItem?.transcodedUrl,
        };
      });
    });
  }, [setResultList, onDownload, selectedList]);

  return (
    <section
      className={classNames(
        'sup-file-info',
        !!selectedList.length && 'checked'
      )}
    >
      <span className="status">
        <span>{counter}</span>
      </span>
      <span className="buttons">
        {selectedList.length === 1 && (
          <>
            <IconButton
              color={White}
              size="lg"
              variant="none"
              onClick={applyParams}
            >
              <ApplyParametersIcon />
            </IconButton>
            <IconButton
              color={White}
              size="lg"
              variant="none"
              onClick={useAsSourceCallBack}
              tooltip={t('Use as Source')}
            >
              <UseAsSourceIcon />
            </IconButton>
          </>
        )}
        <IconButton
          color={White}
          size="lg"
          variant="none"
          onClick={onDelete}
          tooltip={t('Delete Selections')}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          color={White}
          size="lg"
          variant="none"
          tooltip={t('Download Selections')}
          onClick={onClickDownload}
        >
          <DownloadIcon />
        </IconButton>
      </span>
    </section>
  );
};
export default ResultListControl;
