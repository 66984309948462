import {
  CheckboxParameter,
  ParameterType,
  SliderParameter,
} from '@/pages/cvc/ModelControl/config';

export type MssModelType = 'vocals' | 'accomp' | 'hifi_brunet' | 'custom';
export type MssDereverbType = 'dry' | 'reverb' | 'noise';
interface MSSParameter {
  params: {
    api: 'mss' | 'se';
    model_type: MssModelType;
  };
  suffix: string;
  disabled?: boolean;
}

export type MSSSliderParameter = MSSParameter & SliderParameter;
export type MSSCheckboxParameter = MSSParameter & CheckboxParameter;
export type MSSControlParameter = MSSSliderParameter | MSSCheckboxParameter;

export const controlParamList: MSSControlParameter[] = [
  {
    type: ParameterType.Slider,
    name: 'vocals',
    label: 'Vocals',
    defaultValue: 1,
    minValue: 0,
    maxValue: 1,
    minLabel: 'Instrumental',
    maxLabel: 'Vocals',
    step: 0.1,
    tooltip: 'Vocals',
    params: {
      api: 'mss',
      model_type: 'vocals',
    },
    suffix: '_vocals',
    disabled: false,
  },
  {
    type: ParameterType.Slider,
    name: 'accomp',
    label: 'Instrumental',
    defaultValue: 1,
    minValue: 0,
    maxValue: 1,
    minLabel: 'Vocals',
    maxLabel: 'Instrumental',
    step: 0.1,
    tooltip: 'Instrumental',
    params: {
      api: 'mss',
      model_type: 'accomp',
    },
    suffix: '_instrumental',
    disabled: false,
  },
  {
    type: ParameterType.Slider,
    name: 'vocals-dereverb',
    label: 'Vocals Dereverb',
    defaultValue: 1,
    minValue: 0,
    maxValue: 1,
    minLabel: 'Min',
    maxLabel: 'Max',
    step: 0.1,
    tooltip: 'Vocals Dereverb',
    params: {
      api: 'se',
      model_type: 'hifi_brunet',
    },
    suffix: '_dereverb',
    disabled: false,
  },
];
