import Button from '@/components/Button/Button';
import Loading from '@/components/Loading/Loading';
import { Secondary } from '@/styles/Colors';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface FileLoadingProps {
  text?: string | null;
  onCancel?: () => void;
  isFailed?: boolean;
}
const FileLoading: React.FC<FileLoadingProps> = ({
  text,
  onCancel,
  isFailed,
}) => {
  const { t } = useTranslation();
  return (
    <section className="file-loading">
      <section className="fetching">
        <span className="narrow"></span>
        <span className="wide">
          {isFailed ? (
            <Button variant="outlined" color={Secondary[50]} size="sm">
              Retry
            </Button>
          ) : (
            <Loading className="loading" />
          )}
          <span className="text">{text || t('Loading')}</span>
        </span>
        <span className="narrow"></span>
      </section>
      <section className="cancel-button">
        <Button className="btn-cancel" onClick={() => onCancel?.()}>
          {t('Cancel')}
        </Button>
      </section>
    </section>
  );
};
export default FileLoading;
