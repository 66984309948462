import { ResourceResponseData } from '@/api';
import { ExtendFileInfo } from '@/stores/cvc';
import { ResourceStatus } from '@/stores/resource';

export const getNewUploadItem = <T extends ExtendFileInfo>(
  data: ResourceResponseData,
  overwrite?: boolean,
  currentId?: string
) => {
  return (prev: T[], file: File, defaultChecked?: boolean) => {
    if (overwrite) {
      const newList = [...prev];
      const index = newList.findIndex((item) => item.id === currentId);
      newList[index] = {
        ...newList[index],
        name: file.name,
        file,
        id: data.resource_id,
        upload_url: data.upload_url,
        resource_id: data.resource_id,
        audioBuffer: null,
      } as T;
      return newList;
    }
    return [
      {
        id: data.resource_id,
        name: file.name,
        file,
        upload_url: data.upload_url,
        isChecked: defaultChecked ?? false,
      } as T,
    ].concat(prev);
  };
};

export const setReady = <T extends ResourceStatus>(prev: T, id: string) => {
  return {
    ...prev,
    [id]: 'READY',
  } as T;
};
