import { EditableListType } from '@/components/List/types';
import { RESULT_TOGGLE_DEFAULT_VALUE } from '@/consts';
import { MODEL_DESCRIPTIONS } from '@/pages/cvc/ModelControl/config';
import { ReactNode } from 'react';
import { atom, selector } from 'recoil';

import {
  isInterpolationSelector,
  isSourceOnlySelector,
  selectedCvcTypeSelector,
} from './models';
import {
  FETCH_STATUS,
  cvcProcessingStatesModel,
  resultUploadingStatesModel,
  sourceUploadingStatesModel,
  targetUploadingStatesModel,
} from './resource';

export interface FileInfo {
  // need to matrix key
  id: string;
  name: string;
  isChecked: boolean;
  file: File | null;
  isFetching?: boolean;
  fetchStatus?: FETCH_STATUS;
  originalUrl?: string;
  transcodedUrl?: string;
}
export interface ExtendFileInfo extends FileInfo {
  isExpanded?: boolean;
  audioBuffer?: AudioBuffer | null;
  duration?: number;
  upload_url?: string;
  originalExpires?: string | number | null;
}
export interface CvcTargetFileInfo extends ExtendFileInfo {
  isMerged?: boolean;
  isMergeGroup?: boolean;
  mergeIndex?: number;
  isLastGroupFile?: boolean;
  isGroup?: boolean;
  groupName?: string;
}

export interface CvCResultFileInfo extends ExtendFileInfo {
  generatedId: string;
  resource_id?: string;
}

export interface FileItemToggleModel {
  [key: string]: boolean;
}
export interface MergeMapModel {
  [key: string]: {
    list: string[];
    isExpanded: boolean;
    name?: string;
    groupIndex: number;
  };
}
export interface MatrixModel {
  [key: string]: boolean;
}
export type GenerateSelectSourceMap = {
  [key: string]: boolean;
};

export const sourceFileListModel = atom<FileInfo[]>({
  key: 'cvc/sourceFileListModel',
  default: [],
});
export const sourceFileListToggleModel = atom<FileItemToggleModel>({
  key: 'cvc/sourceFileListToggleModel',
  default: {},
});
export const sourceFileListSelector = selector<ExtendFileInfo[]>({
  key: 'cvc/sourceFileListSelector',
  get: ({ get }) => {
    const files = get(sourceFileListModel);
    const toggles = get(sourceFileListToggleModel);
    const uploading = get(sourceUploadingStatesModel);
    return files.map((file) => ({
      ...file,
      isFetching: uploading[file.id] !== 'COMPLETE',
      fetchStatus: uploading[file.id],
      isExpanded: toggles[file.id],
    }));
  },
});

// target file list
export const targetFileListModel = atom<FileInfo[]>({
  key: 'cvc/targetFileListModel',
  default: [],
});
// target file toggle list(not include merge group)
export const targetFileListToggleModel = atom<FileItemToggleModel>({
  key: 'cvc/targetFileListToggleModel',
  default: {},
});
// Merge Type Models
// merge target map(include toggle info=>isExpanded)
export const mergedTargetFileMapModel = atom<MergeMapModel>({
  key: 'cvc/mergedTargetFileMapModel',
  default: {},
});

// merged group counter for initial name
export const mergedGroupCounterModel = atom<number>({
  key: 'cvc/mergedGroupCounterModel',
  default: 0,
});

// merged target items id list
export const mergedTargetIdListModel = atom<string[]>({
  key: 'cvc/mergedTargetIdListModel',
  default: [],
});

export const mergedTargetListSelector = selector<CvcTargetFileInfo[]>({
  key: 'cvc/mergedTargetListSelector',
  get: ({ get }) => {
    const ids = get(mergedTargetIdListModel);
    const targetList = get(targetFileListModel);
    const toggles = get(targetFileListToggleModel);
    return targetList
      .filter((file) => ids.includes(file.id))
      .map((file) => ({
        ...file,
        isExpanded: toggles[file.id],
        isMerged: true,
      }));
  },
});

// target file list included merged info & interpolation info
export const targetFileListSelector = selector<CvcTargetFileInfo[]>({
  key: 'cvc/targetFileListSelector',
  get: ({ get }) => {
    const files = get(targetFileListModel);
    const toggles = get(targetFileListToggleModel);
    const uploading = get(targetUploadingStatesModel);
    const mergedMap = get(mergedTargetFileMapModel);
    const mergedTargetIdList = get(mergedTargetIdListModel);
    let mergeCount = 0;
    // Attach merge info to file items
    const result: CvcTargetFileInfo[] = files
      .filter(
        (file) => mergedMap[file.id] || !mergedTargetIdList.includes(file.id)
      )
      .map((file) => {
        const mergeGroupInfo = mergedMap[file.id];
        const extendFileInfo: CvcTargetFileInfo = {
          ...file,
          isMergeGroup: !!mergeGroupInfo,
          isExpanded: mergedMap[file.id]?.isExpanded ?? toggles[file.id],
          isFetching: uploading[file.id] !== 'COMPLETE',
          fetchStatus: uploading[file.id],
        };
        // set merge group name
        if (!!mergeGroupInfo) {
          extendFileInfo.name =
            mergeGroupInfo.name ?? `Group ${mergeGroupInfo.groupIndex}`;
        }
        // index for merge group,
        // todo if group name could be changed, need to fix this code for merge group name
        if (extendFileInfo.isMergeGroup)
          extendFileInfo.mergeIndex = mergeCount++;
        return extendFileInfo;
      });
    // file list relocation for group and merged group(only for selector=readonly)
    let groupResult = [...result];

    const isInterpolation = get(isInterpolationSelector);
    if (isInterpolation) {
      const interpolationMap = get(interpolationMapModel);
      // todo 그룹이 늘어날 경우 수정필요
      const interpolationList = interpolationMap.GroupB ?? [];
      const interpolationFiles = groupResult
        .filter((file) => interpolationList.includes(file.id))
        .map((file) => ({ ...file, groupName: 'GroupB' }));
      groupResult = groupResult
        .filter((file) => !interpolationList.includes(file.id))
        .map((file) => ({ ...file, groupName: 'GroupA' }));
      groupResult = [
        {
          isChecked:
            !!groupResult.length && groupResult.every((file) => file.isChecked),
          name: 'A',
          id: 'GroupA',
          file: null,
          isGroup: true,
        },
        ...groupResult,
      ];
      groupResult = groupResult.concat([
        {
          isChecked:
            !!interpolationFiles.length &&
            interpolationFiles.every((file) => file.isChecked),
          name: 'B',
          id: 'GroupB',
          file: null,
          isGroup: true,
        },
        ...interpolationFiles,
      ]);
    }
    const mergedList = get(mergedTargetListSelector);
    for (let i = groupResult.length - 1; i >= 0; i--) {
      const file = groupResult[i];
      // When merge group item expanded, insert merged items after it
      if (file.isMergeGroup && file.isExpanded) {
        const merged = mergedMap[file.id];
        if (merged) {
          groupResult = groupResult.slice(0, i + 1).concat([
            ...merged.list.map((id, index) => {
              const item = {
                ...mergedList.find((item) => item.id === id),
              } as CvcTargetFileInfo;
              return {
                ...item,
                isChecked: false,
                isMerged: true,
                isExpanded: toggles[id],
                isFetching: uploading[id] !== 'COMPLETE',
                isLastGroupFile: index === merged.list.length - 1,
              };
            }),
            ...groupResult.slice(i + 1),
          ]);
        }
      }
    }
    return groupResult;
  },
});

// For generate panel source file list
export const selectedSourceFileListSelector = selector<ExtendFileInfo[]>({
  key: 'cvc/selectedSourceFileListSelector',
  get: ({ get }) => {
    const files = get(sourceFileListSelector);
    return files.filter((file) => file.isChecked && !file.isFetching);
  },
});

// For generate panel target file list
export const selectedTargetFileListSelector = selector<CvcTargetFileInfo[]>({
  key: 'cvc/selectedTargetFileListSelector',
  get: ({ get }) => {
    const files = get(targetFileListSelector);
    return files.filter(
      (file) => file.isGroup || (file.isChecked && !file.isFetching)
    );
  },
});

// Matrix model for generate panel selection
export const generateMatrixModel = atom<MatrixModel>({
  key: 'cvc/genMatrixModel',
  default: {},
});
export const generateMatrixModelSelector = selector<MatrixModel>({
  key: 'cvc/genMatrixModelSelector',
  get: ({ get }) => {
    const sourceList = get(selectedSourceFileListSelector);
    const selectedTargetPanelList = get(selectedTargetFileListSelector);
    const targetList = selectedTargetPanelList.filter((file) => !file.isGroup);
    const isSourceOnly = get(isSourceOnlySelector);
    const model = get(generateMatrixModel);
    const matrix: MatrixModel = {};
    if (isSourceOnly) {
      sourceList.forEach((source) => {
        matrix[`${source.id}`] = model[`${source.id}`] ?? true;
      });
      return matrix;
    }
    sourceList.forEach((source) => {
      targetList.forEach((target) => {
        matrix[`${source.id}:${target.id}`] =
          model[`${source.id}:${target.id}`] ?? true;
      });
    });
    return matrix;
  },
  set: ({ get, set }, newValue) => {
    const sourceList = get(selectedSourceFileListSelector);
    const selectedTargetPanelList = get(selectedTargetFileListSelector);
    const targetList = selectedTargetPanelList.filter((file) => !file.isGroup);
    const isSourceOnly = get(isSourceOnlySelector);
    const matrix: MatrixModel = {};
    if (isSourceOnly) {
      sourceList.forEach((source) => {
        matrix[`${source.id}`] = (newValue as MatrixModel)[`${source.id}`];
      });
    } else {
      sourceList.forEach((source) => {
        targetList.forEach((target) => {
          matrix[`${source.id}:${target.id}`] = (newValue as MatrixModel)[
            `${source.id}:${target.id}`
          ];
        });
      });
    }
    set(generateMatrixModel, (pre) => ({ ...pre, ...matrix }));
  },
});
export const generateSourceListSelector = selector<FileInfo[]>({
  key: 'cvc/generateSourceListSelector',
  get: ({ get }) => {
    const isSourceOnly = get(isSourceOnlySelector);
    const list = get(selectedSourceFileListSelector);
    const toggles = get(generateMatrixModelSelector);
    return list.map((file) => {
      const isChecked = Object.keys(toggles)
        .filter((toggle) => toggles[toggle])
        .some((id) => id.startsWith(`${file.id}:`));
      return { ...file, isChecked: isSourceOnly ? file.isChecked : isChecked };
    });
  },
});
export const generateSelectedTargetModel = atom<GenerateSelectSourceMap>({
  key: 'cvc/generateSelectedTargetModel',
  default: {},
});
export const generateTargetListSelector = selector<CvcTargetFileInfo[]>({
  key: 'cvc/generateTargetListSelector',
  get: ({ get }) => {
    const list = get(selectedTargetFileListSelector);
    const toggles = get(generateMatrixModelSelector);
    // todo group 끼워 넣기
    // isMergeGroup은 selected에서 처리
    return list.map((file) => ({
      ...file,
      isGroup: file.isGroup ?? false,
      groupName: file.groupName ?? '',
      isChecked: Object.keys(toggles)
        .filter((toggle) => toggles[toggle])
        .some((id) => id.endsWith(`:${file.id}`)),
    }));
  },
});

// For Audio Editor Panel
interface EditFileInfo {
  id: string;
  type: EditableListType;
}
export type EditorFileInfo = Pick<
  ExtendFileInfo,
  'audioBuffer' | 'file' | 'name' | 'originalUrl' | 'duration'
> &
  EditFileInfo;

export const currentPlayFileModel = atom<EditFileInfo | null>({
  key: 'cvc/currentPlayFileModel',
  default: null,
});

export const prepareEditFileModel = atom<EditFileInfo | null>({
  key: 'cvc/prepareEditFileModel',
  default: null,
});

export const currentEditFileModel = atom<EditorFileInfo | null>({
  key: 'cvc/currentEditFileModel',
  default: null,
});

export const isEditingModel = atom<boolean>({
  key: 'cvc/isEditingModel',
  default: false,
});

// For Result Panel
export const resultFileListModel = atom<CvCResultFileInfo[]>({
  key: 'cvc/resultFileListModel',
  default: [],
});
export const resultFileListToggleModel = atom<FileItemToggleModel>({
  key: 'cvc/resultFileListToggleModel',
  default: {},
});

interface ModelParams {
  name: string;
  params: {
    [paramName: string]: number | boolean;
  };
}
// result file sync
interface ResultParametersMap {
  [jobId: string]: ModelParams;
}
export const resultParametersMapModel = atom<ResultParametersMap>({
  key: 'cvc/parameters',
  default: {},
});

export const resultFileListSelector = selector<CvCResultFileInfo[]>({
  key: 'cvc/resultFileListSelector',
  get: ({ get }) => {
    const files = get(resultFileListModel);
    const toggles = get(resultFileListToggleModel);
    const processing = get(cvcProcessingStatesModel);
    const uploading = get(resultUploadingStatesModel);
    return files.map((file) => {
      return {
        ...file,
        isFetching:
          processing[file.id] !== 'COMPLETE' &&
          uploading[file.id] !== 'COMPLETE',
        fetchStatus: processing[file.id] ?? uploading[file.id],
        isExpanded:
          (processing[file.id] === 'COMPLETE' ||
            uploading[file.id] === 'COMPLETE') &&
          (toggles[file.id] ?? RESULT_TOGGLE_DEFAULT_VALUE),
      };
    });
  },
});

export const selectedResultFileSelector = selector<CvCResultFileInfo[]>({
  key: 'cvc/selectedResultFileSelector',
  get: ({ get }) => {
    const files = get(resultFileListSelector);
    return files.filter((file) => file.isChecked);
  },
});

export const selectedResultFileParamSelector = selector<ModelParams>({
  key: 'cvc/selectedResultFileParamSelector',
  get: ({ get }) => {
    const files = get(selectedResultFileSelector);
    const params = get(resultParametersMapModel);
    if (files.length === 1) {
      return params[files[0].id] ?? ({} as ModelParams);
    }
    return {} as ModelParams;
  },
});

// 미지정은 Selector에서 A로 처리
export type GroupType = 'GroupA' | 'GroupB';
type InterpolationMap = Record<GroupType, string[]>;
export const interpolationMapModel = atom<Omit<InterpolationMap, 'GroupA'>>({
  key: 'cvc/interpolationMap',
  default: {
    GroupB: [],
  },
});

export const interpolationSelector = selector<InterpolationMap>({
  key: 'cvc/interpolationSelector',
  get: ({ get }) => {
    const map = get(interpolationMapModel);
    const targetList = get(selectedTargetFileListSelector).filter(
      (file) => file.isChecked && !file.isGroup
    );
    return {
      GroupA: targetList
        .filter((file) => !map.GroupB.includes(file.id) && !file.isGroup)
        .map((file) => file.id),
      GroupB: map.GroupB.filter((id) =>
        targetList.some((file) => file.id === id)
      ),
    };
  },
});

// for generate panel selection count
export const generateInterpolationMapSelector = selector<InterpolationMap>({
  key: 'cvc/generateInterpolationMapSelector',
  get: ({ get }) => {
    const map = get(interpolationMapModel);
    const generateTargetList = get(generateTargetListSelector);
    return {
      GroupA: generateTargetList
        .filter((file) => !map.GroupB.includes(file.id) && !file.isGroup)
        .map((file) => file.id),
      GroupB: map.GroupB.filter((id) =>
        generateTargetList.some((file) => file.id === id)
      ),
    };
  },
});

// for generate panel selection count
export const selectedGeneratedInterpolationMapSelector =
  selector<InterpolationMap>({
    key: 'cvc/selectedGeneratedInterpolationMapSelector',
    get: ({ get }) => {
      const map = get(interpolationMapModel);
      const selectedList = get(generateTargetListSelector).filter(
        (item) => item.isChecked
      );
      return {
        GroupA: selectedList
          .filter((file) => !map.GroupB.includes(file.id) && !file.isGroup)
          .map((file) => file.id),
        GroupB: map.GroupB.filter((id) =>
          selectedList.some((file) => file.id === id)
        ),
      };
    },
  });

export interface Description {
  main: string;
  sub?: string;
}
export const helpDescriptionModel = atom<Description>({
  key: 'cvc/helpDescriptionModel',
  default: {} as Description,
});

export const descriptionSelector = selector<Description>({
  key: 'cvc/descriptionSelector',
  get: ({ get }) => {
    const model = get(selectedCvcTypeSelector);
    const helpDescription = get(helpDescriptionModel);
    return {
      ...helpDescription,
      main: helpDescription.main ?? MODEL_DESCRIPTIONS[model],
    };
  },
});

// cvc file list moving state
export const targetInnerDragStateModel = atom<boolean>({
  key: 'cvc/targetInnerDragStateModel',
  default: false,
});

// api session control
export const sessionIDModel = atom<string>({
  key: 'cvc/sessionIDModel',
  default: '',
});

// Confirm modal state
export interface ConfirmModalState {
  isOpen: boolean;
  isPortal?: boolean;
  title?: ReactNode;
  message?: ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const confirmModalState = atom<ConfirmModalState>({
  key: 'confirmModalState',
  default: {
    isPortal: false,
    isOpen: false,
  },
});
