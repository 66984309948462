import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { getAudioContext } from '../util/audio';

export interface LoopRange {
  startTime: number;
  endTime: number;
}

interface AudioControllerState {
  loadAudio: (audioBuffer: AudioBuffer) => void;
  resetAudio: () => void;
  playAudio: (time?: number) => void;
  pauseAudio: () => void;
  stopAudio: () => void;
  backwardAudio: () => void;
  forwardAudio: () => void;
  setIsLoop: (isLoop: boolean) => void;
  trimAudio: () => void;
  cutAudio: () => void;
  audioBuffer?: AudioBuffer;
  isPlaying: boolean;
  isLoop: boolean;
  currentTime: number;
  updateCurrentTime: (time: number) => void;
  loopRange: LoopRange | null;
  setLoopRange: (loopRange: LoopRange | null) => void;
}

const useAudioController = (
  editCallback?: (audioBuffer: AudioBuffer) => void
): AudioControllerState => {
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoop, setIsLoop] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [pausedTime, setPausedTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  // Drag로 선택한 구간
  const [loopRange, setLoopRange] = useState<LoopRange | null>(null);
  // 반복재생 중인 구간
  const [activeLoopRange, setActiveLoopRange] = useState<LoopRange | null>(
    null
  );
  const sourceNodeRef = useRef<AudioBufferSourceNode | null>(null);
  const rafId = useRef<number | null>(null);

  const getCurrentTime = useCallback(() => {
    const audioCxt = getAudioContext();
    return pausedTime + audioCxt.currentTime - startTime;
  }, [pausedTime, startTime]);

  const isEnd = useMemo(() => {
    if (!audioBuffer) return false;
    return currentTime === audioBuffer.duration;
  }, [audioBuffer, currentTime]);

  const isLoopStart = useMemo(() => {
    if (!isLoop || !loopRange) return false;
    if (
      (isPlaying && currentTime !== loopRange.startTime) ||
      (!isPlaying && pausedTime !== loopRange.startTime)
    )
      return false;
    return true;
  }, [isLoop, loopRange, isPlaying, currentTime, pausedTime]);

  const isLoopEnd = useMemo(() => {
    if (!isLoop || !loopRange) return false;
    if (
      (isPlaying && currentTime !== loopRange.endTime) ||
      (!isPlaying && pausedTime !== loopRange.endTime)
    )
      return false;
    return true;
  }, [isLoop, loopRange, isPlaying, currentTime, pausedTime]);

  // Update currentTime when audio is playing
  useEffect(() => {
    const updateTime = () => {
      if (!isPlaying || !audioBuffer) return;
      const currentTime = getCurrentTime();
      // currentTime이 duration을 넘어가면 재생을 멈춤
      if (currentTime >= audioBuffer.duration) {
        setIsPlaying(false);
        setCurrentTime(audioBuffer.duration);
        return;
      }
      // loop 지점 도달 시, 만약에 activeLoopRange가 loopRange와 다르거나 없는경우 loopRange로 업데이트
      if (
        isLoop &&
        loopRange &&
        currentTime >= loopRange.startTime &&
        currentTime <= loopRange.endTime
      ) {
        setActiveLoopRange(loopRange);
      }

      setCurrentTime(currentTime);
      rafId.current = requestAnimationFrame(updateTime);
    };

    if (isPlaying) {
      rafId.current = requestAnimationFrame(updateTime);
    } else {
      rafId.current && cancelAnimationFrame(rafId.current);
      rafId.current = null;
    }
    return () => {
      rafId.current && cancelAnimationFrame(rafId.current);
    };
  }, [
    isPlaying,
    getCurrentTime,
    audioBuffer,
    loopRange,
    activeLoopRange,
    isLoop,
  ]);

  // Update currentTime when audio is paused
  useEffect(() => {
    if (isPlaying) return;
    setPausedTime(currentTime);
  }, [isPlaying, currentTime]);

  const resetState = useCallback(() => {
    setIsPlaying(false);
    setIsLoop(false);
    setStartTime(0);
    setPausedTime(0);
    setCurrentTime(0);
    setLoopRange(null);
  }, [
    setIsPlaying,
    setIsLoop,
    setStartTime,
    setPausedTime,
    setCurrentTime,
    setLoopRange,
  ]);

  const loadAudio = useCallback(
    (newAudioBuffer: AudioBuffer) => {
      resetState();
      setAudioBuffer(newAudioBuffer);
    },
    [resetState]
  );

  const resetAudio = useCallback(() => {
    resetState();
    setAudioBuffer(undefined);
  }, [resetState]);

  const resetSourceNode = useCallback(() => {
    if (!sourceNodeRef.current) return;
    sourceNodeRef.current.stop();
    sourceNodeRef.current.disconnect();
    sourceNodeRef.current = null;
  }, [sourceNodeRef]);

  /* Play audio 
    재생 중인 경우 
      repeat이 아닌 경우 -> 처음부터 재생
      repeat인 경우 -> loopRange의 startTime부터 재생
    재생 중이 아닌 경우 -> currentTime부터 재생
  */
  const playAudio = useCallback(
    (time?: number) => {
      if (!audioBuffer) return;
      const audioCxt = getAudioContext();

      resetSourceNode();
      // source노드를 재생성하는 것이 비효율적인 것으로 보이지만 sourcenode는 이 패턴에 최적화되어 있다.
      sourceNodeRef.current = audioCxt.createBufferSource();
      sourceNodeRef.current.buffer = audioBuffer;
      sourceNodeRef.current.connect(audioCxt.destination);

      let startOffset;
      // 시간이 지정된 경우 해당 시간부터 재생
      if (typeof time === 'number') {
        startOffset = time;
      }
      // repeat인 경우 재생중인 여부과 상관없이 startTime부터 재생
      else if (isLoop && loopRange) {
        // 내장 loop를 사용할 경우 현재 시간 계산이 어려움
        startOffset = loopRange.startTime;
      } else {
        startOffset = isPlaying ? 0 : isEnd ? 0 : pausedTime;
      }
      sourceNodeRef.current.start(0, startOffset);

      setStartTime(audioCxt.currentTime);
      setPausedTime(startOffset);
      setIsPlaying(true);
    },
    [
      audioBuffer,
      loopRange,
      isLoop,
      pausedTime,
      isPlaying,
      isEnd,
      resetSourceNode,
    ]
  );

  // currentTime이 변경될 때 activeLoopRange가 있고, currentTime이 activeLoopRange의 endTime을 넘어가면 startTime으로 이동
  useEffect(() => {
    if (!isPlaying || !activeLoopRange || !isLoop) return;
    const { startTime, endTime } = activeLoopRange;
    if (currentTime >= endTime) {
      playAudio(startTime);
    }
  }, [isPlaying, currentTime, isLoop, activeLoopRange, playAudio]);

  // 반복재생 중일 때 loopRange가 변경되면 activeLoopRange를 업데이트
  useEffect(() => {
    if (
      !loopRange ||
      !activeLoopRange ||
      loopRange?.startTime === activeLoopRange?.startTime ||
      loopRange?.endTime === activeLoopRange?.endTime
    )
      return;
    setActiveLoopRange(loopRange);
  }, [loopRange, activeLoopRange]);

  const pauseAudio = useCallback(() => {
    if (!sourceNodeRef.current) return;
    sourceNodeRef.current.stop();
    setIsPlaying(false);
    setPausedTime(getCurrentTime());
  }, [getCurrentTime]);

  const stopAudio = useCallback(() => {
    resetSourceNode();
    setIsPlaying(false);
    setPausedTime(0);
    requestAnimationFrame(() => {
      setCurrentTime(0);
    });
  }, [resetSourceNode, setIsPlaying, setPausedTime, setCurrentTime]);

  /*
    Backward audio
    loopRange startTime이 있고
    currentTime이 loopRange startTime이 아닌 경우 -> startTime으로 이동
    그렇지 않은 경우 -> 처음으로 이동 (0)
  */
  const backwardAudio = useCallback(() => {
    const newStartTime = isLoopStart ? 0 : loopRange?.startTime || 0;
    setPausedTime(newStartTime);
    setCurrentTime(newStartTime);
    isPlaying && playAudio(newStartTime);
  }, [isLoopStart, loopRange, playAudio, isPlaying]);

  /*
    Forward audio
    loopRange endTime이 있고
    currentTime이 loopRange endTime이 아닌 경우 -> endTime으로 이동
    그렇지 않은 경우 -> 끝으로 이동 (audioBuffer.duration)
  */
  const forwardAudio = useCallback(() => {
    if (!audioBuffer) return;
    const newEndTime = isLoopEnd
      ? audioBuffer.duration
      : loopRange?.endTime || audioBuffer.duration;

    setPausedTime(newEndTime);
    setCurrentTime(newEndTime);
    // 오디오의 끝에 도달한 경우 pause
    if (newEndTime === audioBuffer.duration) {
      pauseAudio();
    } else {
      isPlaying && playAudio(newEndTime);
    }
  }, [audioBuffer, isLoopEnd, loopRange, playAudio, isPlaying, pauseAudio]);

  // 선택한 구간을 제외한 나머지 부분을 자르기 (trim)
  const trimAudio = useCallback(() => {
    if (!audioBuffer || !loopRange) return;

    // Stop audio before edit
    stopAudio();

    const audioCxt = getAudioContext();
    const { startTime, endTime } = loopRange;
    const numberOfChannels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const selectedStartIdx = Math.floor(startTime * sampleRate);
    const selectedEndIdx = Math.floor(endTime * sampleRate);

    const newAudioBuffer = audioCxt?.createBuffer(
      numberOfChannels,
      selectedEndIdx - selectedStartIdx,
      sampleRate
    );

    for (let channel = 0; channel < numberOfChannels; channel++) {
      const channelData = audioBuffer.getChannelData(channel);
      const newChannelData = newAudioBuffer?.getChannelData(channel);
      newChannelData?.set(
        channelData.subarray(selectedStartIdx, selectedEndIdx)
      );
    }
    newAudioBuffer && loadAudio(newAudioBuffer);
    editCallback?.(newAudioBuffer);
  }, [audioBuffer, loopRange, stopAudio, loadAudio, editCallback]);

  // 선택 구간만 자르기 (cut)
  const cutAudio = useCallback(() => {
    if (!audioBuffer || !loopRange) return;

    // Stop audio before edit
    stopAudio();

    const audioCxt = getAudioContext();
    const { startTime, endTime } = loopRange;
    const numberOfChannels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const selectedStartIdx = Math.floor(startTime * sampleRate);
    const selectedEndIdx = Math.floor(endTime * sampleRate);
    const endIdx = Math.floor(audioBuffer.duration * sampleRate);

    const newAudioBuffer = audioCxt?.createBuffer(
      numberOfChannels,
      endIdx - selectedEndIdx + selectedStartIdx,
      sampleRate
    );

    for (let channel = 0; channel < numberOfChannels; channel++) {
      const channelData = audioBuffer.getChannelData(channel);
      const newChannelData = newAudioBuffer?.getChannelData(channel);

      newChannelData?.set(channelData.subarray(0, selectedStartIdx), 0);
      newChannelData?.set(
        channelData.subarray(selectedEndIdx, endIdx),
        selectedStartIdx
      );
    }
    newAudioBuffer && loadAudio(newAudioBuffer);
    editCallback?.(newAudioBuffer);
  }, [audioBuffer, loopRange, stopAudio, loadAudio, editCallback]);

  const updateCurrentTime = useCallback(
    (time: number) => {
      // 현재 반복재생 구간이 지정되어 있는데, 해당 범위를 벗어나면(예를 들어 시작점, 끝점 벗어나도록 재생시간을 변경)
      // 현재 활성화된 반복재생구간을 해제
      if (activeLoopRange) {
        const { startTime, endTime } = activeLoopRange;
        if (time < startTime || time > endTime) {
          activeLoopRange && setActiveLoopRange(null);
        }
      }
      // 재생중인 경우 해당 시간부터 재생
      if (isPlaying) {
        playAudio(time);
      }
      // 재생중이 아닌 경우 currentTime만 업데이트
      else {
        setCurrentTime(time);
      }
    },
    [activeLoopRange, isPlaying, playAudio]
  );

  // 페이지 전환이 일어나면 audio를 정지
  useEffect(() => {
    return () => {
      stopAudio();
    };
  }, [stopAudio]);

  return {
    loadAudio,
    resetAudio,
    playAudio,
    pauseAudio,
    stopAudio,
    backwardAudio,
    forwardAudio,
    setIsLoop,
    trimAudio,
    cutAudio,
    audioBuffer,
    isPlaying,
    isLoop,
    currentTime,
    updateCurrentTime,
    loopRange,
    setLoopRange,
  };
};

export default useAudioController;
