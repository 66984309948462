import { selectedCVCPanelModel } from '@/stores/panels';
import classNames from 'classnames';
import { isValidElement, useCallback } from 'react';
import { Panel, PanelProps } from 'react-resizable-panels';
import { useRecoilState } from 'recoil';

import { CardUI, CardUIContent, CardUIHeader } from '../../components/CardUI';
import Title from '../../components/Title/Title';
import { CVCPanelTypes } from '../../consts/cvc';
import { Grey } from '../../styles/Colors';

// fixme 기본값이 어떤 색인지 여부 확인 후, 타입 재정의 -> 이 부분은 CardUI쪽으로 빠지는게 맞을것으로 예상됨.
type PanelTheme = 'grey' | 'transparent';
interface SectionPanelProps extends PanelProps {
  theme?: PanelTheme;
  title?: React.ReactNode;
  panelId?: CVCPanelTypes;
  onResize?: (size: number) => void;
}
const SectionPanel: React.FC<SectionPanelProps> = (props) => {
  const [selectedPanelId, setSelectedPanel] = useRecoilState(
    selectedCVCPanelModel
  );
  const panelId = props.panelId;
  const active = useCallback(() => {
    if (panelId && panelId !== selectedPanelId) {
      setSelectedPanel(panelId);
    }
  }, [panelId, selectedPanelId, setSelectedPanel]);

  if (props.theme) {
    return (
      <Panel
        tagName={'section'}
        {...props}
        style={{
          ...props.style,
          color: props.theme === 'grey' ? Grey[600] : Grey[500],
        }}
        onResize={props.onResize}
        className={classNames('cvc-section-panel', props.className)}
      >
        <CardUI className={classNames('card', props.theme)} onClick={active}>
          <CardUIHeader>
            {isValidElement(props.title)
              ? props.title
              : props.title && <Title size="lg">{props.title}</Title>}
          </CardUIHeader>
          <CardUIContent className="main-panel-content" padding={0}>
            {props.children}
          </CardUIContent>
        </CardUI>
      </Panel>
    );
  }
  return (
    <Panel tagName={'section'} {...props}>
      {props.children}
    </Panel>
  );
};
export default SectionPanel;
