import styled from '@emotion/styled';

import { Grey, PureBlack } from '../../../styles/Colors';

const StyledVoiceFilesContent = styled.section`
  background-color: ${PureBlack};
  display: flex;
  min-width: 100%;
  height: 100%;
  padding: 1.25rem 1.38rem;
  margin-bottom: 1.25rem;
  .source-audio-files {
    background-color: ${Grey[600]};
    width: 36.1rem;
    border-radius: 1rem;
    margin-right: 0.88rem;
  }
  .target-audio-files {
    background-color: ${Grey[450]};
    width: 36.1rem;
    border-radius: 1rem;
    margin-right: 0.88rem;
  }
  .generate-audio-files {
    background-color: ${Grey[700]};
    width: 46.2rem;
    border-radius: 1rem;
  }
  .move-button {
    display: inline-flex;
    align-items: center;
    margin-right: 0.88rem;
    .button-section-move {
      width: 2rem;
      height: 2rem;
      color: currentColor;
    }
  }
  .selected {
    outline: 1px solid ${Grey['300']};
  }
`;

export default StyledVoiceFilesContent;
