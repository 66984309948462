import { getUploadInfo, upload } from '@/api';
import { HotkeyManagerContext } from '@/providers/HotkeyManagerContextProvider';
import { WebSocketContext } from '@/providers/WebSocketProvider';
import {
  CvCResultFileInfo,
  CvcTargetFileInfo,
  EditorFileInfo,
  ExtendFileInfo,
  FileInfo,
  MergeMapModel,
  currentEditFileModel,
  currentPlayFileModel,
  isEditingModel,
  mergedTargetFileMapModel,
  mergedTargetIdListModel,
  prepareEditFileModel,
  resultFileListModel,
  resultFileListSelector,
  sourceFileListModel,
  sourceFileListSelector,
  targetFileListModel,
  targetFileListSelector,
  targetFileListToggleModel,
} from '@/stores/cvc';
import { panelDefaultSizeModel, selectedCVCPanelModel } from '@/stores/panels';
import {
  resultUploadingStatesModel,
  sourceUploadingStatesModel,
  targetUploadingStatesModel,
} from '@/stores/resource';
import classNames from 'classnames';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import AudioControlPanel from '../../components/AudioControlPanel/AudioControlPanel';
import { Control } from '../../components/AudioControlPanel/types';
import AudioEditor from '../../components/AudioEditor/AudioEditor';
import { CVC_PANEL_ORDER_MAP } from '../../consts/cvc';
import useAudioBufferStore from '../../hooks/useAudioBufferStore';
import useAudioController, { LoopRange } from '../../hooks/useAudioController';
import setAudioEditSnapshot from '../../hooks/useUndoManager/setAudioEditSnapshot';
import useUndoManager from '../../hooks/useUndoManager/useUndoManager';
import { SectionPanel } from '../../layout/SectionPanel';
import { fetchAudio, getAudioBuffer } from '../../util/audio';
import FileNameDisplay from './AudioEditorPanel/FileNameDisplay';
import { AUDIO_EDITOR_PANEL_HEIGHT_MIN } from './config';
import useIsHighlightPanel from './hooks/useIsHighlightPanel';
import useResultUpload from './ResultPanel/useResultUpload';
import StyledAudioEditorContent from './styled/StyledAudioEditorContent';
import { getNewUploadItem, setReady } from './util/items';

const AudioEditorPanel = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const isHighlight = useIsHighlightPanel('AudioEditor');
  const setSelectedPanel = useSetRecoilState(selectedCVCPanelModel);
  const panelDefaultSize = useRecoilValue(panelDefaultSizeModel);
  const [fileName, setFileName] = useState<string>();
  const [editorSize, setEditorSize] = useState({ width: 0, height: 0 });
  const [showSaveModal, setShowSaveModal] = useState(false);
  // drag range for draw loop selection
  const [dragRange, setDragRange] = useState<LoopRange | null>(null);
  // Loading status
  const [isLoading, setIsLoading] = useState(false);

  const { push, currentSnapshot, reset: resetUndoManager } = useUndoManager();
  // Register hotkey
  const { register, unRegister } = useContext(HotkeyManagerContext);
  const [currentEditFileInfo, setCurrentEditFileInfo] =
    useRecoilState(currentEditFileModel);
  const prepareEditFile = useRecoilValue(prepareEditFileModel);
  const resetPrepareEditFile = useResetRecoilState(prepareEditFileModel);
  // Editing status
  const [isEditing, setIsEditing] = useRecoilState(isEditingModel);

  const {
    addAudioBufferItem,
    clearAudioBufferStore,
    findAudioBufferIndex,
    getAudioBufferById,
  } = useAudioBufferStore();

  const [currentAudioBufferId, setCurrentAudioBufferId] = useState<
    string | null
  >(null);
  const setCurrentPlayFile = useSetRecoilState(currentPlayFileModel);

  useEffect(() => {
    const editingStatus = !!(
      currentSnapshot && findAudioBufferIndex(currentSnapshot.audioBufferId) > 0
    );
    setIsEditing(editingStatus);
  }, [currentSnapshot, findAudioBufferIndex, setIsEditing]);

  // trim, cut 등 audioBuffer가 변경되었을 경우 실행하는 콜백
  const handleEdit = useCallback(
    (buffer: AudioBuffer) => {
      const newId = addAudioBufferItem(buffer);
      setCurrentAudioBufferId(newId);
      push(
        setAudioEditSnapshot({
          loopRange: null,
          audioBufferId: newId,
        })
      );
    },
    [addAudioBufferItem, push]
  );

  const {
    audioBuffer,
    loadAudio,
    resetAudio,
    playAudio,
    pauseAudio,
    stopAudio,
    backwardAudio,
    forwardAudio,
    trimAudio,
    cutAudio,
    isPlaying,
    isLoop,
    setIsLoop,
    loopRange,
    setLoopRange,
    currentTime,
    updateCurrentTime,
  } = useAudioController(handleEdit);

  const audioControls: Control[][] = useMemo(
    () => [
      [
        {
          action: 'stop',
          onClick: stopAudio,
          disabled: currentTime === 0,
        },
        {
          action: 'play',
          onClick: playAudio,
          isActive: isPlaying,
        },
        { action: 'pause', onClick: pauseAudio, disabled: !isPlaying },
        {
          action: 'repeat',
          onClick: () => {
            setIsLoop(!isLoop);
          },
          isActive: isLoop,
          disabled: !(loopRange?.startTime && loopRange?.endTime),
        },
      ],
      [
        { action: 'backward', onClick: backwardAudio },
        { action: 'forward', onClick: forwardAudio },
      ],
    ],
    [
      backwardAudio,
      forwardAudio,
      isPlaying,
      isLoop,
      pauseAudio,
      playAudio,
      loopRange,
      setIsLoop,
      stopAudio,
      currentTime,
    ]
  );

  // 재생중일 때 다른 패널 선택시 재생 중지
  useEffect(() => {
    if (!isPlaying) return;
    if (!isHighlight) {
      stopAudio();
    }
  }, [stopAudio, isHighlight, isPlaying]);

  // Undo, Redo 시 audioBufferId가 변경되었을 경우 audioBuffer를 업데이트
  useEffect(() => {
    if (
      !currentSnapshot?.audioBufferId ||
      currentAudioBufferId === currentSnapshot.audioBufferId
    )
      return;
    const newAudioBuffer = getAudioBufferById(currentSnapshot.audioBufferId);
    newAudioBuffer && loadAudio(newAudioBuffer);
    setCurrentAudioBufferId(currentSnapshot.audioBufferId);
  }, [
    currentSnapshot?.audioBufferId,
    currentAudioBufferId,
    getAudioBufferById,
    loadAudio,
  ]);

  const sourceFileList = useRecoilValue(sourceFileListSelector);
  const targetFileList = useRecoilValue(targetFileListSelector);
  const setTargetFileListToggle = useSetRecoilState(targetFileListToggleModel);
  const resultFileList = useRecoilValue(resultFileListSelector);
  const resourceMap = useMemo(
    () => ({
      Source: sourceFileList,
      Target: targetFileList,
      Result: resultFileList,
    }),
    [sourceFileList, targetFileList, resultFileList]
  );
  useEffect(() => {
    if (!prepareEditFile) return;
    if (prepareEditFile.id === currentEditFileInfo?.id) return;
    if (isEditing) {
      if (currentEditFileInfo) {
        setShowSaveModal(true);
      }
      // 편집 중인 파일을 삭제했을 경우
      else {
        resetAudio();
        resetUndoManager();
        resetPrepareEditFile();
        setShowSaveModal(false);
      }
    } else {
      setShowSaveModal(false);
      resetUndoManager();
      resourceMap[prepareEditFile.type].forEach((file) => {
        if (file.id === prepareEditFile.id) {
          setCurrentEditFileInfo({
            type: prepareEditFile.type,
            id: file.id,
            name: file.name,
            duration: file.duration,
            file: file.file,
            originalUrl: file.originalUrl,
            audioBuffer: file.audioBuffer,
          });
        }
      });
      !isHighlight && setSelectedPanel('AudioEditor');
    }
  }, [
    resourceMap,
    prepareEditFile,
    currentEditFileInfo,
    currentSnapshot,
    findAudioBufferIndex,
    setCurrentEditFileInfo,
    setSelectedPanel,
    isHighlight,
    resetUndoManager,
    resetAudio,
    resetPrepareEditFile,
    isEditing,
  ]);

  const handlePlayPause = useCallback(() => {
    if (isPlaying) {
      pauseAudio();
    } else {
      playAudio(currentTime);
    }
  }, [isPlaying, pauseAudio, playAudio, currentTime]);

  const updateCurrentPlayFile = useCallback(() => {
    if (!currentEditFileInfo?.id || !currentEditFileInfo?.type) return;
    setCurrentPlayFile({
      id: currentEditFileInfo.id,
      type: currentEditFileInfo.type,
    });
  }, [currentEditFileInfo?.id, currentEditFileInfo?.type, setCurrentPlayFile]);

  useEffect(() => {
    if (!isHighlight || !currentEditFileInfo?.id) return;
    updateCurrentPlayFile();
    // space로 play/pause
    register('space', handlePlayPause);
    // move to start(zero)
    register('alt+tab', () => {
      updateCurrentTime(0);
    });
    // move to end
    register('tab', () => {
      updateCurrentTime(audioBuffer?.duration || 0);
    });
    // Loop
    loopRange &&
      register('ctrl+shift+l', () => {
        setIsLoop(!isLoop);
      });
    return () => {
      unRegister('space');
      unRegister('alt+tab');
      unRegister('tab');
      unRegister('ctrl+shift+l');
    };
  }, [
    currentEditFileInfo?.id,
    audioBuffer?.duration,
    loopRange,
    register,
    unRegister,
    isHighlight,
    handlePlayPause,
    updateCurrentPlayFile,
    updateCurrentTime,
    isLoop,
    setIsLoop,
  ]);

  // 드래그 종료 후 loop range를 업데이트
  const updateLoopRange = useCallback(
    (range: LoopRange | null, activeLoop?: boolean) => {
      // loop range를 설정한 경우 loop mode를 활성화, 해제 시 비활성화
      activeLoop && setIsLoop(activeLoop);
      setLoopRange(range);
    },
    [setIsLoop, setLoopRange]
  );

  // 드래그 중일 때 loop range를 업데이트
  const updateDragRange = useCallback(
    (range: LoopRange | null) => {
      // 재생중일 경우 drag range 선택 시 재생 중지
      if (isPlaying) pauseAudio();
      setDragRange(range);
    },
    [isPlaying, pauseAudio]
  );

  // Observe container size change and update editor size
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      if (width === editorSize.width && height === editorSize.height) return;
      setEditorSize({ width, height });
    });

    resizeObserver.observe(container);
    return () => {
      resizeObserver.unobserve(container);
    };
  }, [editorSize]);

  useEffect(() => {
    // undoManager에서 loop range가 변경되었을 경우 loop range를 업데이트
    const range = currentSnapshot?.loopRange || null;
    setLoopRange(range);
    setDragRange(range);
    setIsLoop(!!range);
  }, [currentSnapshot?.loopRange, setLoopRange, setIsLoop]);

  // onSave 시작
  // 각 status 저장을 위한 model
  const [sourceUploadingStates, setSourceUploadingStates] = useRecoilState(
    sourceUploadingStatesModel
  );
  const [targetUploadingStates, setTargetUploadingStates] = useRecoilState(
    targetUploadingStatesModel
  );
  const [resultUploadingStates, setResultUploadingStates] = useRecoilState(
    resultUploadingStatesModel
  );

  const currentEditFileUploadingState = useMemo(() => {
    if (!currentEditFileInfo?.id) return;
    if (currentEditFileInfo?.type === 'Source') {
      return sourceUploadingStates[currentEditFileInfo.id];
    } else if (currentEditFileInfo?.type === 'Target') {
      return targetUploadingStates[currentEditFileInfo.id];
    } else if (currentEditFileInfo?.type === 'Result') {
      return resultUploadingStates[currentEditFileInfo.id];
    }
  }, [
    currentEditFileInfo?.id,
    currentEditFileInfo?.type,
    resultUploadingStates,
    sourceUploadingStates,
    targetUploadingStates,
  ]);

  useEffect(() => {
    if (
      !currentEditFileUploadingState ||
      currentEditFileUploadingState === 'COMPLETE' ||
      currentEditFileUploadingState === 'FAILED'
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [currentEditFileUploadingState]);

  // 각 overwrite 및 추가를 위한 데이터
  const setSourceList = useSetRecoilState(sourceFileListModel);
  const setTargetList = useSetRecoilState(targetFileListModel);
  const setResultList = useSetRecoilState(resultFileListModel);
  const [mergedTargetIdList, setMergedTargetIdList] = useRecoilState(
    mergedTargetIdListModel
  );
  const setMergedTargetFileMap = useSetRecoilState(mergedTargetFileMapModel);
  // result 의 경우 upload로직을 editor에서만 사용하기 때문에 별도 처리
  const { uploadFile: uploadResultFile } = useResultUpload();
  // upload전에 resource 정보를 받을때 socket session 정보 필요
  const { sessionId } = useContext(WebSocketContext);

  const updateFileInfo = useCallback(
    (file: EditorFileInfo) => {
      const updateAudioBuffer = (list: FileInfo[] | CvCResultFileInfo[]) =>
        list.map((item) => {
          if (item.id === file.id) {
            return {
              ...item,
              audioBuffer: file.audioBuffer,
            };
          }
          return item;
        });

      if (file.type === 'Source') {
        setSourceList((prev) => updateAudioBuffer(prev));
      } else if (file.type === 'Target') {
        setTargetList((prev) => updateAudioBuffer(prev));
      } else if (file.type === 'Result') {
        setResultList((prev) => updateAudioBuffer(prev) as CvCResultFileInfo[]);
      }
    },
    [setSourceList, setTargetList, setResultList]
  );

  const selectAudio = useCallback(async () => {
    const fileInfo = currentEditFileInfo as EditorFileInfo;
    if (!fileInfo) return;
    let newAudioBuffer;
    const { audioBuffer: fileAudioBuffer, name, file, originalUrl } = fileInfo;
    // audioBuffer가 있는 경우 해당 audioBuffer를 사용
    if (fileAudioBuffer) {
      newAudioBuffer = fileAudioBuffer;
    } else {
      const url = file ? file : originalUrl;
      if (!url) return;
      setIsLoading(true);
      const { arrayBuffer } = await fetchAudio(url);
      newAudioBuffer = await getAudioBuffer(arrayBuffer);
      if (!newAudioBuffer) return;
      // audioBuffer가 없는 경우 해당 file의 audioBuffer, duration정보를 store에 추가
      const newFileInfo: EditorFileInfo = {
        ...fileInfo,
        audioBuffer: newAudioBuffer,
      };
      setCurrentEditFileInfo(newFileInfo);
      // store update
      updateFileInfo(newFileInfo);
      setIsLoading(false);
    }
    // Update audiobuffer, filename
    setFileName(name);
    addAudioBufferItem(newAudioBuffer);
    loadAudio(newAudioBuffer);
  }, [
    loadAudio,
    addAudioBufferItem,
    setCurrentEditFileInfo,
    currentEditFileInfo,
    updateFileInfo,
  ]);

  // onSave handler 시작
  const onSave = useCallback(
    async (file: File, overwrite?: boolean) => {
      const {
        data: { data },
      } = await getUploadInfo(sessionId, file.name, file.type);

      // 만약에 다른파일을 선택하면서 저장을 눌렀을 경우에는 바꾸지 않고
      // 같은 파일을 선택하면서 create new 혹은 overwrite를 눌렀을 경우에만 id update
      if (
        prepareEditFile?.id === currentEditFileInfo?.id &&
        currentEditFileInfo?.type
      ) {
        // overwrite일 경우 id가 변경이 되기 때문에(새로 생성) Create new와 동일 로직 사용
        setCurrentEditFileInfo({
          id: data.resource_id,
          name: currentEditFileInfo.name,
          type: currentEditFileInfo.type,
          file,
        } as EditorFileInfo);
      }
      resetPrepareEditFile();

      if (currentEditFileInfo?.type === 'Result') {
        // result만 별도 처리
        if (!overwrite) {
          uploadResultFile([file]);
        } else {
          setResultUploadingStates((prev) => setReady(prev, data.resource_id));
          setResultList((prev) =>
            getNewUploadItem<CvCResultFileInfo>(
              data,
              overwrite,
              currentEditFileInfo?.id
            )(prev, file)
          );
        }
      } else if (currentEditFileInfo?.type === 'Source') {
        setSourceUploadingStates((prev) => setReady(prev, data.resource_id));
        setSourceList((prev) =>
          getNewUploadItem<ExtendFileInfo>(
            data,
            overwrite,
            currentEditFileInfo?.id
          )(prev, file)
        );
      } else if (currentEditFileInfo?.type === 'Target') {
        setTargetUploadingStates((prev) => setReady(prev, data.resource_id));
        // merged 케이스 처리
        const isMerged = !!mergedTargetIdList.find(
          (id) => id === currentEditFileInfo.id
        );
        if (isMerged) {
          if (overwrite) {
            setMergedTargetFileMap((prev) => {
              const newMap = { ...prev };
              // merged 된 아이디를 찾아서 먼저 수정
              Object.keys(newMap)
                .filter((f) => f)
                .forEach((id) => {
                  const group = newMap[id];
                  if (group.list.includes(currentEditFileInfo.id)) {
                    newMap[id] = {
                      ...group,
                      list: group.list.map((id) =>
                        id === currentEditFileInfo.id ? data.resource_id : id
                      ),
                    };
                  }
                });
              // group 대표 아이디의 경우 변경
              return Object.keys(newMap).reduce((acc, id) => {
                if (id === currentEditFileInfo.id) {
                  return { ...acc, [data.resource_id]: newMap[id] };
                } else {
                  return { ...acc, [id]: newMap[id] };
                }
              }, {} as MergeMapModel);
            });
            // merge된 타겟 아이디 리스트 변경
            setMergedTargetIdList((prev) =>
              prev.map((id) =>
                id === currentEditFileInfo.id ? data.resource_id : id
              )
            );
          } else {
            setMergedTargetFileMap((prev) => {
              const newMap = { ...prev };
              const targetGroup = Object.keys(newMap).find((id) => {
                return newMap[id].list.includes(currentEditFileInfo.id);
              });
              if (targetGroup) {
                newMap[targetGroup] = {
                  ...newMap[targetGroup],
                  list: [data.resource_id].concat(newMap[targetGroup].list),
                };
              }
              return newMap;
            });
            setMergedTargetIdList((prev) => prev.concat(data.resource_id));
          }

          // 아이디값 변경에 따른 토글 상태도 변경
          setTargetFileListToggle((prev) => ({
            ...prev,
            [data.resource_id]: overwrite
              ? prev[currentEditFileInfo.id]
              : false,
          }));
        }
        setTargetList((prev) =>
          getNewUploadItem<CvcTargetFileInfo>(
            data,
            overwrite,
            currentEditFileInfo?.id
          )(prev, file, isMerged)
        );
      }
      // fetching 상태 아이템 생성 후 upload 호출
      await upload(data.upload_url as string, file);
    },
    [
      sessionId,
      currentEditFileInfo,
      uploadResultFile,
      setSourceUploadingStates,
      setSourceList,
      setTargetUploadingStates,
      setTargetList,
      setResultUploadingStates,
      setResultList,
      setCurrentEditFileInfo,
      resetPrepareEditFile,
      prepareEditFile?.id,
      mergedTargetIdList,
      setMergedTargetFileMap,
      setMergedTargetIdList,
      setTargetFileListToggle,
    ]
  );

  const onReset = useCallback(() => {
    selectAudio();
  }, [selectAudio]);

  const onCancel = useCallback(() => {
    resetPrepareEditFile();
  }, [resetPrepareEditFile]);

  // 선택된 파일이 변경되었을 경우 audioBuffer를 업데이트
  useEffect(() => {
    // 선택된 파일이 없는 경우 audioBuffer를 초기화
    if (!currentEditFileInfo?.id) {
      resetAudio();
      return;
    }
    clearAudioBufferStore();
    selectAudio();
  }, [currentEditFileInfo?.id, selectAudio, clearAudioBufferStore, resetAudio]);

  return (
    <SectionPanel
      panelId="AudioEditor"
      title={t('AUDIO EDITOR')}
      order={CVC_PANEL_ORDER_MAP.AUDIO_EDITOR}
      theme="transparent"
      defaultSize={panelDefaultSize[CVC_PANEL_ORDER_MAP.AUDIO_EDITOR]}
      className={classNames('audio-editor-panel', isHighlight && 'highlight')}
      minSize={AUDIO_EDITOR_PANEL_HEIGHT_MIN}
    >
      <StyledAudioEditorContent ref={containerRef}>
        <FileNameDisplay fileName={fileName} />
        <AudioEditor
          onSave={onSave}
          onReset={onReset}
          onCancel={onCancel}
          audioBuffer={audioBuffer}
          loopRange={loopRange}
          updateLoopRange={updateLoopRange}
          currentTime={audioBuffer ? currentTime : undefined}
          updateCurrentTime={updateCurrentTime}
          playAudio={playAudio}
          pauseAudio={pauseAudio}
          cutAudio={cutAudio}
          trimAudio={trimAudio}
          isPlaying={isPlaying}
          size={editorSize}
          showModal={showSaveModal}
          updateShowModal={setShowSaveModal}
          isHotkeyEnabled={isHighlight}
          dragRange={dragRange}
          updateDragRange={updateDragRange}
          fileName={fileName}
          isLoading={isLoading}
        />
        <AudioControlPanel
          controls={audioControls}
          isControllable={!isLoading && !!audioBuffer && !showSaveModal}
        />
      </StyledAudioEditorContent>
    </SectionPanel>
  );
};

export default AudioEditorPanel;
