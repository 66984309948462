import { FontSize, FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

import { Grey, PureBlack, White } from '../../../styles/Colors';

export const StyledCvcGuide = styled.aside`
  height: 3.38rem;
  background-color: ${Grey[600]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.25rem;
  color: ${Grey[200]};
  border-bottom: 1px solid ${Grey[500]};
  border-top: 1px solid ${PureBlack};
  font-size: ${FontSize['Md']};
  .panel-control {
    background-color: ${Grey[800]};
    padding: 0.37rem 0.62rem;
    gap: 1rem;
    border-radius: 0.25rem;
    color: ${Grey[50]};
    button: {
      width: 1.5rem;
      height: 1.5rem;
    }
    .closed {
      color: ${Grey[500]};
    }
  }
  .description {
    padding-left: 1.25rem;
    display: inline-flex;
    gap: 1rem;
    .main-desc {
      font-size: ${FontSize['Md']};
      color: ${White};
      font-weight: ${FontWeight['SemiBold']};
    }
  }
`;
export default StyledCvcGuide;
