import { getPanelRatio, panelDefaultSizeModel } from '@/stores//panels';
import {
  enabledModelSelector,
  parametersValueModel,
  selectedCvcTypeSelector,
} from '@/stores/models';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { CVC_PANEL_ORDER_MAP } from '../../../consts/cvc';
import { SectionPanel } from '../../../layout/SectionPanel';
import { MODEL_PANEL_MIN } from '../config';
import useIsHighlightPanel from '../hooks/useIsHighlightPanel';
import { ModelType } from './config';
import ModelSelect from './ModelSelect';
import Parameters from './Parameters';

const minSize = getPanelRatio(MODEL_PANEL_MIN);

const SelectModelPanel = () => {
  const { t } = useTranslation();
  const panelDefaultWidth = useRecoilValue(panelDefaultSizeModel);
  const isHighlight = useIsHighlightPanel('SelectModel');

  const models = useRecoilValue(enabledModelSelector);
  const [value, setValue] = useRecoilState(selectedCvcTypeSelector);

  const resetParameters = useResetRecoilState(parametersValueModel);

  const onChangeModel = useCallback(
    (model: ModelType) => {
      setValue(model);
      resetParameters();
    },
    [setValue, resetParameters]
  );

  return (
    <SectionPanel
      panelId="SelectModel"
      title={t('SELECT MODEL')}
      className={classNames('sup-control-panel', isHighlight && 'highlight')}
      order={CVC_PANEL_ORDER_MAP.CONTROL}
      theme="grey"
      defaultSize={panelDefaultWidth[CVC_PANEL_ORDER_MAP.CONTROL]}
      minSize={minSize}
      maxSize={panelDefaultWidth[CVC_PANEL_ORDER_MAP.CONTROL]}
    >
      <section className="sup-models">
        <ModelSelect models={models} value={value} onChange={onChangeModel} />
        <Parameters />
      </section>
    </SectionPanel>
  );
};

export default SelectModelPanel;
