import { InputHTMLAttributes, useId } from 'react';

import { StyledSwitch } from './StyledSwitch';

interface SwitchProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  checkedLabel?: string;
  uncheckedLabel?: string;
}

const Switch = ({
  checkedLabel = 'on',
  uncheckedLabel = 'off',
  ...props
}: SwitchProps) => {
  const checkboxId = useId();

  return (
    <StyledSwitch htmlFor={checkboxId}>
      <input type="checkbox" role="switch" id={checkboxId} {...props} />
      <span className="sup-switch-label checked">{checkedLabel}</span>
      <span className="sup-switch-label unchecked">{uncheckedLabel}</span>
    </StyledSwitch>
  );
};

export default Switch;
