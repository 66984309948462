import { LoopRange } from '@/hooks/useAudioController';
import { White } from '@/styles/Colors';
import { ScaleLinear } from 'd3-scale';

import { SELECT_HANDLE_WIDTH, X_AXIS_HEIGHT } from '../const';
import { Size } from '../types';

interface LoopAreaProps {
  size: Size;
  loopRange: LoopRange;
  xScale: ScaleLinear<number, number>;
}

const LoopArea = ({ size, loopRange, xScale }: LoopAreaProps) => {
  if (
    typeof loopRange?.startTime !== 'number' ||
    typeof loopRange?.endTime !== 'number'
  )
    return null;

  return (
    <g className="editor-loop">
      <g>
        <rect
          x={xScale(loopRange?.startTime) - SELECT_HANDLE_WIDTH}
          width={SELECT_HANDLE_WIDTH}
          height={X_AXIS_HEIGHT}
          className="editor-loop-handle-start"
        />
        <line
          x1={xScale(loopRange?.startTime)}
          x2={xScale(loopRange?.startTime)}
          y1={0}
          y2={size.height + X_AXIS_HEIGHT}
        />
      </g>
      <g>
        <rect
          x={xScale(loopRange?.endTime)}
          width={SELECT_HANDLE_WIDTH}
          height={X_AXIS_HEIGHT}
          className="editor-loop-handle-end"
        />
        <line
          x1={xScale(loopRange?.endTime)}
          x2={xScale(loopRange?.endTime)}
          y1={0}
          y2={size.height + X_AXIS_HEIGHT}
        />
      </g>
      {/* clip-path */}
      <defs>
        <clipPath id="loop-clip-path">
          <rect
            x={xScale(loopRange?.startTime)}
            width={xScale(loopRange?.endTime) - xScale(loopRange?.startTime)}
            height={size.height + X_AXIS_HEIGHT}
            fill={White}
          />
        </clipPath>
      </defs>
    </g>
  );
};

export default LoopArea;
