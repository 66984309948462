import { ExtendFileInfo } from '@/stores/cvc';
import React, { useCallback, useEffect, useRef, useState } from 'react';

interface EditableNameProps {
  item: ExtendFileInfo;
  changeName: (id: string, name: string) => void;
}
const EditableName: React.FC<EditableNameProps> = ({ item, changeName }) => {
  const [isEditName, setIsEditName] = useState(false);
  const [name, setName] = useState('');
  const onBlur = useCallback(() => {
    setIsEditName(false);
  }, [setIsEditName]);

  useEffect(() => {
    setName(item.name);
  }, [item.name]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.code !== 'Escape' && e.code !== 'Enter') {
        return;
      }
      if (e.code === 'Escape') {
        return setIsEditName(false);
      }
      e.preventDefault();
      const target = e.target as HTMLTextAreaElement;
      if (e.code === 'Enter') {
        if (target.value.trim()) {
          setName(target.value);
          changeName(item.id, target.value);
        } else {
          setName(item.name);
        }
        setIsEditName(false);
      }
    },
    [item, setName, setIsEditName, changeName]
  );

  const textRef = useRef<HTMLTextAreaElement>(null);
  const onDbClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setIsEditName(true);
    },
    [setIsEditName]
  );

  useEffect(() => {
    isEditName && textRef.current?.focus();
  }, [isEditName, textRef]);

  return (
    <>
      <textarea
        style={{ display: isEditName ? '' : 'none' }}
        ref={textRef}
        className="editable"
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        defaultValue={name}
      ></textarea>
      <span
        style={{ display: isEditName ? 'none' : '' }}
        className="file-name"
        onDoubleClick={onDbClick}
      >
        {name}
      </span>
    </>
  );
};
export default EditableName;
