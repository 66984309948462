import { ReactComponent as MergeIcon } from '@/assets/icons/MergeIcon.svg';
import { ReactComponent as DeleteIcon } from '@/components/assets/icons/DeleteIcon.svg';
import { ReactComponent as DownloadIcon } from '@/components/assets/icons/DownloadIcon.svg';
import IconButton from '@/components/Button/IconButton';
import useVoiceFileList from '@/hooks/useVoiceFileList';
import {
  CvcTargetFileInfo,
  interpolationMapModel,
  mergedGroupCounterModel,
  mergedTargetFileMapModel,
  mergedTargetIdListModel,
  selectedTargetFileListSelector,
  targetFileListModel,
  targetFileListSelector,
} from '@/stores/cvc';
import { isInterpolationSelector } from '@/stores/models';
import { targetUploadingStatesModel } from '@/stores/resource';
import { Primary, White } from '@/styles/Colors';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import useDownload from '../hooks/useDownload';

const TargetListControl = () => {
  const { t } = useTranslation();
  const setTargetList = useSetRecoilState(targetFileListModel);
  const targetList = useRecoilValue(targetFileListSelector);
  const selectedList = useRecoilValue(selectedTargetFileListSelector).filter(
    (item) => !item.isGroup
  );
  // Common File list Control
  const { deleteFiles, files } = useVoiceFileList<CvcTargetFileInfo>(
    targetList,
    setTargetList
  );
  const counter = useMemo(() => {
    const total = targetList.filter(
      (file) => !file.isMerged && !file.isGroup && !file.isFetching
    ).length;
    return `${
      selectedList.length ? selectedList.length + ' / ' : ''
    }${total} File${total > 1 ? 's' : ''}`;
  }, [selectedList, targetList]);

  const isInterpolation = useRecoilValue(isInterpolationSelector);
  const interpolationMap = useRecoilValue(interpolationMapModel);
  const checkedList = useMemo(
    () => targetList.filter((item) => item.isChecked),
    [targetList]
  );
  const checkItems = useMemo(
    () => checkedList.filter((item) => !item.isGroup),
    [checkedList]
  );
  const isAllGroupB = useMemo(
    () =>
      !!checkItems.length &&
      checkItems.every((item) => interpolationMap.GroupB.includes(item.id)),
    [checkItems, interpolationMap]
  );
  const isAllGroupA = useMemo(
    () =>
      !!checkItems.length &&
      checkItems.every((item) => !interpolationMap.GroupB.includes(item.id)),
    [checkItems, interpolationMap]
  );
  const isGroupModifiable = useMemo(() => {
    return isInterpolation && (isAllGroupB || isAllGroupA);
  }, [isInterpolation, isAllGroupB, isAllGroupA]);

  // merge 및 unmerge가능 상태 체크, interpolation 의 경우 같은 group에서 체크 했는지 확인
  const mergeable = useMemo(() => {
    return (
      (isGroupModifiable || !isInterpolation) &&
      checkItems.every(
        (item) => !item.isFetching && !item.isMerged && !item.isMergeGroup
      ) &&
      checkItems.length > 1
    );
  }, [checkItems, isGroupModifiable, isInterpolation]);

  const unmergeable = useMemo(() => {
    return (
      (isGroupModifiable || !isInterpolation) &&
      checkItems.every((item) => item.isMergeGroup || item.isMerged)
    );
  }, [checkItems, isGroupModifiable, isInterpolation]);

  const [mergedTargetFileMap, setMergedTargetFileMap] = useRecoilState(
    mergedTargetFileMapModel
  );
  const setMergedTargetIdList = useSetRecoilState(mergedTargetIdListModel);
  const [mergedGroupCounter, setMergedGroupCounter] = useRecoilState(
    mergedGroupCounterModel
  );
  // merge on unmerge 가능 상태에 따른 콜백 처리
  const mergeFiles = useCallback(() => {
    if (mergeable) {
      const [head, ...tails] = checkedList.filter((item) => !item.isGroup);
      setMergedTargetIdList((prev) => [
        ...prev,
        ...checkedList.map((file) => file.id),
      ]);
      setMergedTargetFileMap((prev) => {
        const merged = { ...prev };
        merged[head.id] = {
          list: [head.id, ...tails.map((file) => file.id)],
          isExpanded: true,
          groupIndex: mergedGroupCounter + 1,
        };
        return merged;
      });
      setMergedGroupCounter((cnt) => cnt + 1);
    }
    if (unmergeable) {
      const mergeGroupList = checkedList.filter((file) => file.isMergeGroup);
      const mergedList = mergeGroupList
        .map((file) => mergedTargetFileMap[file.id].list)
        .flat();
      setMergedTargetIdList((prev) =>
        prev.filter((id) => !mergedList.find((fileId) => fileId === id))
      );
      setMergedTargetFileMap((prev) => {
        const merged = { ...prev };
        mergedList.forEach((fileId) => {
          delete merged[fileId];
        });
        return merged;
      });
    }
  }, [
    mergeable,
    unmergeable,
    checkedList,
    mergedTargetFileMap,
    mergedGroupCounter,
    setMergedTargetIdList,
    setMergedTargetFileMap,
    setMergedGroupCounter,
  ]);

  const uploadingTargetStates = useRecoilValue(targetUploadingStatesModel);

  // delete 처리시 merged 상태인 파일들 추가 처리
  const deleteTargetFiles = useCallback(() => {
    const mergedList = files.filter((file) => file.isChecked && file.isMerged);
    console.log(mergedList, uploadingTargetStates);
    setMergedTargetFileMap((prev) => {
      const merged = { ...prev };
      mergedList.forEach((file) => {
        delete merged[file.id];
      });
      return merged;
    });
    setMergedTargetIdList((prev) =>
      prev.filter((id) => !mergedList.find((file) => file.id === id))
    );
    deleteFiles(uploadingTargetStates, true);
  }, [
    files,
    deleteFiles,
    setMergedTargetFileMap,
    setMergedTargetIdList,
    uploadingTargetStates,
  ]);

  const downloadList = useMemo(() => {
    const list = [] as CvcTargetFileInfo[];
    selectedList.forEach((item) => {
      if (item.isMergeGroup) {
        const group = mergedTargetFileMap[item.id];
        // targetList에서 찾아서 넣으면 됨.
        const groupList = group.list.map((id) =>
          targetList.find((v) => v.isMerged && v.id === id)
        ) as CvcTargetFileInfo[];
        list.push(...groupList);
      } else {
        list.push(item);
      }
    });
    return list;
  }, [selectedList, mergedTargetFileMap, targetList]);
  const { onDownload } = useDownload();
  const onClickDownload = useCallback(async () => {
    const items = (await onDownload(downloadList)) as CvcTargetFileInfo[];
    setTargetList((prev) => {
      return prev.map((item) => {
        const newItem = items.find((v) => v.id === item.id);
        return {
          ...item,
          originalUrl: newItem?.originalUrl,
          transcodedUrl: newItem?.transcodedUrl,
        };
      });
    });
  }, [setTargetList, onDownload, downloadList]);

  return (
    <section
      className={classNames(
        'sup-file-info',
        !!selectedList.length && 'checked'
      )}
    >
      <span className="status">
        <span>{counter}</span>
      </span>
      <span className="buttons">
        {(mergeable || unmergeable) && (
          <IconButton
            color={Primary[50]}
            variant="none"
            size="lg"
            value="none"
            onClick={mergeFiles}
            tooltip={t('Merge Selections')}
          >
            {/* {todo unmerge icon 필요} */}
            <MergeIcon />
          </IconButton>
        )}
        <IconButton
          color={White}
          size="lg"
          variant="none"
          onClick={deleteTargetFiles}
          tooltip={t('Delete Selections')}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          color={White}
          size="lg"
          variant="none"
          tooltip={t('Download Selections')}
          onClick={onClickDownload}
        >
          <DownloadIcon />
        </IconButton>
      </span>
    </section>
  );
};
export default TargetListControl;
